// Component represents the main counter on the Home page
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import MediaQuery from "react-responsive";
import { getMetrics } from "../merlinv1/beta_api";

// Creates the exports the component
const MetricsCounter = () => {

  const [clicks, setClicks] = useState([]);
  const [models, setModels] = useState([]);
  const [cost, setCost] = useState([]);
  const history = useHistory();

  const fetchData = async () => {
    try {
      const res = await getMetrics();

      setClicks(res.data['clicks'])
      setModels(res.data['models'])
      setCost(res.data['cost'])

    } catch (err) {
      history.push("/ErrorPage");
    }
  }

  useEffect(() => {
    fetchData()
  }, []);


  return (
    <div>
      <div
        className={
          "w-full flex flex-col md:flex-row bg-white justify-evenly"
        }
      >
        <div className={"flex flex-col justify-evenly items-center"}>
          <p
            className={"text-blue-pondr text-center text-5xl font-bold tracking-wider"}
          >
            {(clicks + "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
          <p className={"text-blue-pondr text-center text-xl font-light"}>
            Creations Made
          </p>
        </div>
        <div className={"md:h-0 h-5"} />
        <div className={"flex flex-col justify-evenly items-center"}>
          <p
            className={"text-blue-pondr text-center text-5xl font-bold tracking-wider"}
          >
            {(models + "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
          <p className={"text-blue-pondr text-center text-xl font-light"}>
            Models Created
          </p>
        </div>
        <div className={"md:h-0 h-5"} />
        <div className={"flex flex-col justify-evenly items-center"}>
          <p
            className={"text-blue-pondr text-center text-5xl font-bold tracking-wider"}
          >
            ${(cost + "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
          <p className={"text-blue-pondr text-center text-xl font-light"}>
            Earned by Developers
          </p>
        </div>
      </div>
    </div>
  );
};

// Exports the counter
export default MetricsCounter;
