import React, { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import WelcomePartial from "../partials/WelcomePartial";
import MetaTags from "react-meta-tags";
import WalkthroughPartial from "../partials/WalkthroughPartial";

function Walkthrough() {
  useEffect(() => {}, []);

  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <MetaTags>
        <title>Welcome</title>
        <meta name='description' content='Quasi | Welcome Page' />
        <meta property='og:title' content='Quasi | Welcome Page' />
      </MetaTags>
      {/* <Header /> */}
      <main>
        <WalkthroughPartial />
      </main>
      <Footer />
    </div>
  );
}

export default Walkthrough;
