import React, { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import ConstructionPartial from "../partials/ConstructionPartial";

function UnderConstruction() {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
    logScreenName("UnderConstruction");
    logEvent("page_view", { page_name: "UnderConstruction" });
  }, []);

  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <meta name='viewport' content='width=device-width, initial-scale=1' />

      {/*  Site header */}

      {/*  Page content */}
      <main className='flex-grow'>
        {/*  Page sections */}
        <ConstructionPartial />
      </main>

      {/*  Site footer */}
    </div>
  );
}

export default UnderConstruction;
