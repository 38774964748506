import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import BadgesPartial from "../partials/BadgesPartial";

const Badges = () => {

    useEffect(() => {

    }, []);

    return (
        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <MetaTags>
                <title>Badges</title>
                <meta
                    name="description"
                    content="Quasi | Badges"
                />
                <meta name="facebook-domain-verification" content="go460u6t8soug9eienhq6kqphkebsr" />
            </MetaTags>
            <Header />
            <BadgesPartial />
            <Footer />
        </div>
    );
};

export default Badges;