import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { connect } from "react-redux";
import Card4 from "../pages/components/Card4";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import Editable from "../pages/components/Editable";
import Button from "../pages/components/Button";
import {
  FaDollarSign,
  FaRegThumbsDown,
  FaRegThumbsUp,
} from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti"
import { getAnalyticsModel } from '../merlinv1/beta_api';
import {
  BsFillFlagFill,
  BsStarFill,
  BsFillGridFill
} from "react-icons/bs";
import { } from "react-icons/bs";
import { } from "react-icons/bs";
import Card from "../pages/components/Card";
import { Range } from "react-range";
import { useHistory } from "react-router";
import ReactLoading from "react-loading";
import { Link } from 'react-router-dom';
import { RiEditFill } from 'react-icons/ri';
import { AiFillGift } from 'react-icons/ai';
import { IoCube } from 'react-icons/io5';
import { MdSpaceDashboard } from 'react-icons/md'
import {
  editImageModel,
  generateImageExamples,
  getEditModel,
  hideModel,
  showModel,
  unlistModel
} from "../merlinv1/beta_api";
import TextArea from "../pages/components/TextArea";
import { HiCursorClick } from "react-icons/hi"


const AddProductFormSchema = Yup.object({
  // modelTitle: Yup.string().required("Model title is required"),
  // category: Yup.string().required("Category is required"),
  // openAI: Yup.string().required("GPT-3 key is required"),
  // prompt: Yup.string().required("Prompt is required"),
  // engine: Yup.string().required("GPT-3 engine is required")
});

const EditImageModelPartial = (props) => {

  // Button loading state
  const [isLoading, setIsLoading] = useState(false);
  const [isHideLoading, setIsHideLoading] = useState(false);
  const [isEstimateLoading, setIsEstimateLoading] = useState(false);
  const [isLoadingExamples, setIsLoadingExamples] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [numRuns, setNumRuns] = React.useState([45])
  const [width, setWidth] = React.useState([256])
  const [length, setLength] = React.useState([256])
  const [numImages, setNumImages] = React.useState([4])
  const [uniqueness, setUniqueness] = React.useState([11])
  const [input1, setInput1] = React.useState("")
  const [input2, setInput2] = React.useState("")
  const [input3, setInput3] = React.useState("")
  const [input1Len, setInput1Len] = React.useState([60])
  const [input2Len, setInput2Len] = React.useState([60])
  const [input3Len, setInput3Len] = React.useState([60])
  const [example1, setExample1] = React.useState("")
  const [example2, setExample2] = React.useState("")
  const [example3, setExample3] = React.useState("")
  const [exampleInputList, setExampleInputList] = React.useState([])
  const [exampleOutput, setExampleOutput] = useState('Generate Examples Before Updating!')
  const [exampleList, setExampleList] = React.useState([])
  const [modelId, setModelId] = React.useState()
  const [newModelTitle, setNewModelTitle] = React.useState()
  const [newKey, setNewKey] = React.useState()
  const [newModelDescription, setNewModelDescription] = React.useState()
  const [newModelType, setNewModelType] = React.useState()
  const [newModelEngine, setNewModelEngine] = React.useState()
  const [newPrice, setNewPrice] = React.useState()
  const [newStopSequence, setNewStopSequence] = React.useState()
  const [newPrompt, setNewPrompt] = React.useState()
  const [modelStatus, setModelStatus] = React.useState()
  const history = useHistory();
  const [numberList, setNumberList] = React.useState([])
  const [oldPrompt, setOldPrompt] = React.useState("")
  const [nameList, setNameList] = React.useState([])
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [clicks, setClicks] = useState()
  const [disLikes, setDislikes] = useState()
  const [likes, setLikes] = useState()
  const [flaggs, setFlaggs] = useState()
  const [earnings, setEarnings] = useState()
  const [successRate, setSuccessRate] = useState()

  var model

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(AddProductFormSchema),
    defaultValues: {
      modelTitle: newModelTitle,
      category: newModelType,
      openAI: newKey,
      prompt: newPrompt,
      engine: newModelEngine,
      input1: "",
      input2: "",
      input3: "",
      prefix: "",
      credits: newPrice,
    },
  });

  const addExamples = async (event) => {
    var newExampleList = exampleList
    newExampleList.push(exampleOutput)
    // setExampleList(newExampleList)

    var newExampleInputList = exampleInputList
    newExampleInputList.push([example1, example2, example3])
    setExampleInputList(newExampleInputList)

    forceUpdate()
  }

  const subtractExamples = async (index) => {
    var newExampleList = exampleList
    newExampleList.splice(index, 1)
    //setExampleList(newExampleList)

    var newExampleInputList = exampleInputList
    newExampleInputList.splice(index, 1)
    setExampleInputList(newExampleInputList)
  }

  const handleChange = async (event) => {

    // This is really ugly I know, ill come back and make it better but for now it works - Thomas S
    var newNumberList = numberList
    var newNameList = nameList
    var textInput = event.target.value
    if (textInput !== oldPrompt) {
      setExampleList([])
      setExampleInputList([])
      setExampleOutput("Generate Examples Before Updating!")
    }
    if (textInput.includes("{1}") && !newNumberList.includes(1)) {
      newNumberList.push(1)
      newNameList.push("input1")
      forceUpdate()
    }
    else if (textInput.includes("{2}") && !newNumberList.includes(2)) {
      newNumberList.push(2)
      newNameList.push("input2")
      forceUpdate()
    }
    else if (textInput.includes("{3}") && !newNumberList.includes(3)) {
      newNumberList.push(3)
      newNameList.push("input3")
      forceUpdate()
    }
    else if (!textInput.includes("{1}") && newNumberList.includes(1)) {
      const index = newNumberList.indexOf(1);
      if (index > -1) {
        newNumberList.splice(index, 1);
        newNameList.splice(index, 1);
      }
      forceUpdate()
    }
    else if (!textInput.includes("{2}") && newNumberList.includes(2)) {
      const index = newNumberList.indexOf(2);
      if (index > -1) {
        newNumberList.splice(index, 1);
        newNameList.splice(index, 1);
      }
      forceUpdate()
    }
    else if (!textInput.includes("{3}") && newNumberList.includes(3)) {
      const index = newNumberList.indexOf(3);
      if (index > -1) {
        newNumberList.splice(index, 1);
        newNameList.splice(index, 1);
      }
      forceUpdate()
    }
    setNewPrompt(textInput)
    setNumberList(newNumberList)
    setNameList(newNameList)
    setOldPrompt(textInput)
  }

  const onSubmit = async (data) => {

    if (exampleList.length >= 3) {  // <--- check for credits here
      setIsLoading(true);
      // Updates the model
      var res = await editImageModel(
        newModelTitle,
        newPrompt,
        newModelEngine,
        newPrice,
        numRuns,
        width,
        length,
        numImages,
        uniqueness,
        exampleList,
        exampleInputList,
        input1,
        input2,
        input3,
        newModelDescription,
        modelId,
        input1Len,
        input2Len,
        input3Len
      );

      console.log(res)

      setIsLoading(false);
      history.push("/user/my-models");
    }

    else {
      alert("Plase have at least 3 examples to launch model")
    }
  };

  const onGenerateSubmit = async (data) => {

    let res = ""

    setIsLoadingExamples(true);

    if (true) {

      res = await generateImageExamples(
        newPrompt,
        newModelEngine,
        numRuns,
        width,
        length,
        numImages,
        uniqueness,
        example1,
        example2,
        example3
      );

      setExampleOutput(res.data["response"]);

      setIsLoadingExamples(false);

    }
  };

  const onError = (errors) => {
    console.log(errors);
  };


  const fetchData = async () => {
    try {
      let searchParams = new URLSearchParams(window.location.pathname);
      var modelID
      for (let p of searchParams) {
        modelID = p[1];
        break
      }

      let res = await getEditModel(modelID);
      model = res.data['model']
      setModelId(modelID)
      setNewModelTitle(model['Model_title'])
      setNewModelDescription(model['Model_description'])
      setNewModelType(model['Category'])
      setNewModelEngine(model['Engine'])
      setNewPrice(model['Credits'])
      setNumRuns(model['Num_runs'])
      setWidth(model['Width'])
      setLength(model['Length'])
      setNumImages(model['Num_images'])
      setUniqueness(model['Uniqueness'])
      setInput1(model['Input1'])
      setInput2(model['Input2'])
      setInput3(model['Input3'])
      setInput1Len(model['Input1_Len'])
      setInput2Len(model['Input2_Len'])
      setInput3Len(model['Input3_Len'])
      setExampleList(model['Example_list'])
      setModelStatus(model['Status'])

      let modelAnalytics = await getAnalyticsModel(modelID);

      setClicks(modelAnalytics.data.clicks)
      setDislikes(modelAnalytics.data.dislikes)
      setLikes(modelAnalytics.data.likes)
      setFlaggs(modelAnalytics.data.flaggs)
      setEarnings(modelAnalytics.data.earnings)
      setSuccessRate(modelAnalytics.data.successRate)
      console.log(model['Status'])
      if (model['Status'] === "Online") {
        document.getElementById('hide').className = "bg-green-primaryBox text-green-primaryText hover:bg-green-hoverBox hover:text-green-hoverText font-bold py-2 px-4 rounded-full"
      }
      else if (model['Status'] === "Hidden" || model['Status'] === "Error") {
        document.getElementById('hide').className = "bg-red-primaryBox text-red-primaryText hover:bg-red-hoverBox hover:text-red-hoverText font-bold py-2 px-4 rounded-full"
      }
      else if (model['Status'] === "Unlisted") {
        document.getElementById('hide').className = "bg-yellow-primaryBox text-yellow-primaryText hover:bg-yellow-hoverBox hover:text-yellow-hoverText font-bold py-2 px-4 rounded-full"
      }

      var newExampleInputList = exampleInputList
      for (let i = 0; i < model['Input1_Examples'].length; i++) {
        newExampleInputList.push([model['Input1_Examples'][i], model['Input2_Examples'][i], model['Input3_Examples'][i]])
      }

      setExampleInputList(newExampleInputList)

      // updates prompt inputs
      var newNumberList = numberList
      var newNameList = nameList
      var textInput = model['Prompt']

      if (textInput.includes("{1}") && !newNumberList.includes(1)) {
        newNumberList.push(1)
        newNameList.push("input1")
        forceUpdate()
      }
      if (textInput.includes("{2}") && !newNumberList.includes(2)) {
        newNumberList.push(2)
        newNameList.push("input2")
        forceUpdate()
      }
      if (textInput.includes("{3}") && !newNumberList.includes(3)) {
        newNumberList.push(3)
        newNameList.push("input3")
        forceUpdate()
      }
      if (!textInput.includes("{1}") && newNumberList.includes(1)) {
        const index = newNumberList.indexOf(1);
        if (index > -1) {
          newNumberList.splice(index, 1);
          newNameList.splice(index, 1);
        }
        forceUpdate()
      }
      if (!textInput.includes("{2}") && newNumberList.includes(2)) {
        const index = newNumberList.indexOf(2);
        if (index > -1) {
          newNumberList.splice(index, 1);
          newNameList.splice(index, 1);
        }
        forceUpdate()
      }
      if (!textInput.includes("{3}") && newNumberList.includes(3)) {
        const index = newNumberList.indexOf(3);
        if (index > -1) {
          newNumberList.splice(index, 1);
          newNameList.splice(index, 1);
        }
        forceUpdate()
      }
      setNewPrompt(textInput)
      setNumberList(newNumberList)
      setNameList(newNameList)
      setOldPrompt(textInput)

      forceUpdate()

    } catch (err) {
      //history.push("/ErrorPage");
      console.log(err)
    }
  }

  const handler = async (event) => {
    // Searches models
    if (event.key === "Enter") {
      onGenerateSubmit()
    }
  };

  useEffect(() => {
    window.scroll(0, 0)
    fetchData()
    setIsPageLoading(false)
  }, []);

  if (isPageLoading) {
    return (
      <div>
        <div className={"pt-24"}>
          <div className="w-full flex flex-wrap justify-items-center pt-16">
            <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
              <Link
                to="/user/dashboard/"
                className="btn-sm font-bold pl-8 pr-8 text-blue-primaryText bg-blue-primaryBox hover:bg-blue-hoverBox hover:text-blue-hoverText ml-3 focus:outline-none rounded-full"
              >
                <MdSpaceDashboard />
                <span className='pl-2'>Dashboard</span>
                <path
                  d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                  fillRule="nonzero"
                />
              </Link>
            </div>
            <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
              <Link
                to="/user/my-models"
                className="btn-sm font-bold pl-8 pr-8 text-gray-secondaryText bg-gray-secondaryBox hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox ml-3 focus:outline-none rounded-full"
              >
                <IoCube />
                <span className='pl-2'>My Models</span>
                <path
                  d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                  fillRule="nonzero"
                />
              </Link>
            </div>
            <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
              <Link
                to="/user/my-creations"
                className="btn-sm font-bold pl-8 pr-8 text-gray-secondaryText bg-gray-secondaryBox hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox ml-3 focus:outline-none rounded-full"
              >
                <AiFillGift />
                <span className='sm:pl-1 md:pl-2 xl:pl-2'>My Creations</span>
                <path
                  d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                  fillRule="nonzero"
                />
              </Link>
            </div>
            <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
              <Link
                to="/user/create-product"
                className="btn-sm font-bold pl-8 pr-8 text-gray-secondaryText bg-gray-secondaryBox hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox ml-3 focus:outline-none rounded-full"
              >
                <RiEditFill />
                <span className='pl-2'>Make Model</span>
                <path
                  d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                  fillRule="nonzero"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full h-screen flex justify-center items-center">
          <ReactLoading type="spin" color="#6a4c93" height="3%" width="3%" />
        </div>
      </div>
    )
  }

  else {
    return (
      <section>
        <Header />
        <div className="pt-20" />
        <div className="pt-24"></div>
        <div className='flex flex-row flex-wrap items-center mb-4 pb-8 pt-20 px-24'>
          <div className="w-full flex flex-row sm:justfy-center md:justify-end font-bold leading-tighter tracking-tighter">
            <button className="btn-sm font-medium text-gray-secondaryText bg-gray-secondaryBox hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox focus:outline-none rounded-full" onClick={() => { history.push("/user/dashboard/") }}>
              <TiArrowBack className="text-gray-secondaryText" />
              <span className='pl-2'>Return to dashboard</span>

            </button>
          </div>

          <div
            className="text-left lg:text-6xl md:text-5xl sm:text-5xl font-bold leading-tighter tracking-tighter"
            data-aos="zoom-y-out"
          >
            <span className="">
              Edit Model
            </span>
          </div>
        </div>

        <div id={"pageRef"} className="mx-10 pt-20">
          <div
            className="flex flex-wrap justify-center w-full mx-auto text-white"
            data-aos="fade-up"
          >
            {[
              {
                icon: <FaRegThumbsDown className="mb-3 font-bold" size="20px" />,
                title: "DISLIKES",
                text: disLikes
              },
              {
                icon: <FaRegThumbsUp className="mb-3 font-bold" size="20px" />,
                title: "LIKES",
                text: likes
              },
              {
                icon: <BsFillFlagFill className="mb-3" size="20px" />,
                title: "NUMBER OF FLAGS",
                text: flaggs
              },
              {
                icon: <BsStarFill className="mb-3" size="20px" />,
                title: "OUTPUT SUCCESS RATE",
                text: (Math.round((successRate + Number.EPSILON) * 100) / 100) + "%"
              },
              {
                icon: <HiCursorClick className="mb-3" size="20px" />,
                title: "TOTAL CLICKS",
                text: clicks
              },
              {
                icon: <FaDollarSign className="mb-3" size="20px" />,
                title: "AMOUNT EARNED",
                text: earnings,
              },
            ].map((eachCard, index) => (
              <Card4
                className={
                  index - (2 % 4) !== 4 ? "w-22.5% mr-3 mb-5" : "w-22.5% mb-5"
                }
                key={index}
              >
                <div className="w-full font-medium text-gray-secondaryText justify-left pb-3 ">
                  {" "}
                  {eachCard.icon}
                  {eachCard.title}{" "}
                </div>
                <div className="w-min h4 text-blue-pondr"> {eachCard.text}</div>
              </Card4>
            ))}
          </div>
        </div>

        <div className="w-full px-3 py-6 flex justify-center items-center">
          {isHideLoading ? (
            <ReactLoading
              type={"spin"}
              color={"#C3A6EC"}
              height={"3%"}
              width={"3%"}
            />
          ) : (
            <button
              id="hide"
              onClick={async () => {
                if (modelStatus === "Online")
                  try {
                    setIsHideLoading(true)
                    let res = await hideModel(modelId);
                    setModelStatus("Hidden")
                    setIsHideLoading(false)
                    document.getElementById('hide').className = "bg-red-primaryBox text-red-primaryText hover:bg-red-hoverBox hover:text-red-hoverText font-bold py-2 px-4 rounded-full"
                  } catch (err) {
                    //history.push("/ErrorPage");
                    console.log(err)
                  }
                else if (modelStatus === "Error" || modelStatus === "Unlisted") {
                  try {
                    setIsHideLoading(true)
                    let res = await showModel(modelId);
                    setModelStatus("Online")
                    setIsHideLoading(false)
                    document.getElementById('hide').className = "bg-green-primaryBox text-green-primaryText hover:bg-green-hoverBox hover:text-green-hoverText font-bold py-2 px-4 rounded-full"
                  } catch (err) {
                    //history.push("/ErrorPage");
                    console.log(err)
                  }
                }
                else if (modelStatus === "Hidden") {
                  try {
                    setIsHideLoading(true)
                    let res = await unlistModel(modelId);
                    setModelStatus("Unlisted")
                    setIsHideLoading(false)
                    document.getElementById('hide').className = "bg-yellow-primaryBox text-yellow-primaryText hover:bg-yellow-hoverBox hover:text-yellow-hoverText font-bold py-2 px-4 rounded-full"
                  } catch (err) {
                    //history.push("/ErrorPage");
                    console.log(err)
                  }
                }
              }}
            >
              {modelStatus}
            </button>
          )}
        </div>

        {modelStatus === "Error" ? (
          <div className="text-red-600 text-center">
            Your model errored, we have hidden it for now. Plese check your openAI key and account. When the problem is solved, click the Error button above to bring your model back online
          </div>
        ) : (null)}

        <Card className="mx-auto w-2/3 md:w-4/5 h-2/5 mb-0">
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className="flex justify-center md:w-full w-full mx-auto"
          >
            <div className="flex flex-wrap mx-3">
              <div className="w-full pb-8">
                <div className="w-full overflow-hidden bg-gray-tertiaryBox rounded-lg">
                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 xl:my-3 xl:px-3">
                    <div className="">
                      <div className="rounded-xl p-1">
                        <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                          Model Title
                        </div>
                        <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                          This is the name of your model
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="px-2 w-full overflow-hidden md:px-3 xl:px-3 self-center pb-8">
                    <div className="">
                      <Editable
                        className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox"
                        text={newModelTitle}
                        placeholder="Write a task name"
                      >
                        <input
                          className="w-full text-gray-800 outline-none bg-gray-secondaryBox"
                          name="task"
                          placeholder="Write a task name"
                          value={newModelTitle}
                          onChange={e => setNewModelTitle(e.target.value)}
                          maxLength={150}
                        />
                      </Editable>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full pb-8">
                <div className="flex flex-wrap w-full overflow-hidden bg-gray-tertiaryBox rounded-lg">
                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 xl:my-3 xl:px-3">
                    <div className="">
                      <div className="rounded-xl p-1">
                        <div className="text-left h3 mr-5 leading-tighter tracking-tighter mb-1">
                          Model Description
                        </div>
                        <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                          Describe your model and what it does
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="px-2 w-full overflow-hidden md:px-3 xl:px-3 self-center pb-8">
                    <div className="">
                      <Editable
                        className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox"
                        text={newModelDescription}
                        placeholder="Write a task name"
                        type="input"
                      >
                        <input
                          className="w-full text-gray-800 outline-none bg-gray-secondaryBox"
                          type="text"
                          name="task"
                          placeholder="Write a task name"
                          value={newModelDescription}
                          onChange={e => setNewModelDescription(e.target.value)}
                          maxLength={200}
                        />
                      </Editable>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full pb-8">
                <div className="flex flex-wrap overflow-hidden bg-gray-tertiaryBox rounded-lg pb-8">
                  <div className="pt-2 px-2 w-full overflow-hidden md:px-2 xl:px-3 xl:w-1/2">
                    <div className="">
                      <div className="w-full">
                        <label
                          className="form-label block text-sm font-medium mb-1"
                          htmlFor="engine"
                        >
                          <div className="text-left h3 mr-5 leading-tighter tracking-tighter mb-2">
                            Model Engine
                          </div>
                        </label>
                        <select
                          ref={register}
                          onChange={e => setNewModelEngine(e.target.value)}
                          name="engine"
                          className="form-select w-full focus:outline-none bg-gray-secondaryBox"
                        >
                          <option value="" disabled defaultValue>
                            Select your model engine
                          </option>
                          <option value="Quasi Diffusion">Quasi Diffusion</option>
                        </select>
                        <p className="block text-sm font-medium text-red-600">
                          <ErrorMessage errors={errors} name="engine" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="pt-2 px-2 w-full overflow-hidden md:px-2 xl:px-3 xl:w-1/2">
                    <div className="">
                      <div className="w-full">
                        <div className="text-left h3 mr-5 leading-tighter tracking-tighter mb-2">
                          Set Price
                        </div>
                        <Editable
                          className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox"
                          text={newPrice}
                          placeholder="Write a task name"
                        >
                          <input
                            className="w-full text-gray-800 outline-none bg-gray-secondaryBox"
                            type="number"
                            name="task"
                            placeholder="Write a task name"
                            value={newPrice}
                            onChange={e => setNewPrice(e.target.value)}
                          />
                        </Editable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full pb-8">
                <div className="w-full bg-gray-tertiaryBox rounded-lg">
                  <div className="w-full flex justify-center items-center">
                    <div className="text-right h3 pt-2 leading-tighter tracking-tighter"
                      data-tip="Quasi recommends 4 examples">
                      Prompt
                    </div>
                  </div>
                  <div className="w-full px-3 flex justify-center items-center">
                    <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                      Use &#123;1&#125; &#123;2&#125; &#123;3&#125; characters to denote an input
                    </div>
                  </div>

                  <div className="w-full px-3 flex">
                    <div className="w-full bg-quasi-gray">
                      <div className="w-full p-8">
                        <Editable
                          className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox whitespace-pre-line"
                          text={newPrompt}
                          placeholder="Write a task name"
                          type="input"
                        >
                          <textarea
                            value={newPrompt}
                            type="textarea"
                            className="px-4 py-3 w-full text-gray-800 bg-gray-secondaryBox rounded-lg whitespace-pre-line"
                            name="task"
                            label="Prompt"
                            required
                            ref={register}
                            errors={errors}
                            onChange={handleChange}
                          />
                        </Editable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full pb-24">
                {numberList.length > 0 ? (
                  <div className="w-full px-3">
                    <div
                      className="mb-2"
                    >
                      <div className="flex flex-wrap -mx-1 overflow-hidden sm:-mx-1 md:-mx-1 lg:-mx-1 xl:-mx-4 bg-gray-tertiaryBox rounded-xl">
                        <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                          <div className="p-4">
                            <div className="pb-2">
                              <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                Input {numberList[0]}
                              </div>
                              <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                                What do you want the end user to input at {numberList[0]}
                              </div>
                            </div>
                          </div>
                          <div className="p-4">
                            <div className="w-full">
                              <Editable
                                className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                text={input1}
                                placeholder="What should the user input at this location?"
                                type="input"
                              >
                                <input
                                  className="w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                  name='test1'
                                  value={input1}
                                  placeholder="What should the user input at this location?"
                                  required
                                  type="text"
                                  errors={errors}
                                  onChange={e => setInput1(e.target.value)}
                                />
                              </Editable>
                            </div>
                          </div>
                        </div>

                        <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                          <div className="p-4">
                            <div className="pb-2">
                              <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                Input {numberList[0]} Length
                              </div>
                              <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                                What is the maximum length you would allow the end user to input at input {numberList[0]}?
                              </div>
                            </div>
                          </div>

                          <div className="p-4">
                            <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                              <Range
                                step={1}
                                min={0}
                                max={1000}
                                values={input1Len}
                                onChange={(input1Len) => {
                                  setInput1Len(input1Len)
                                }}
                                renderTrack={({ props, children }) => (
                                  <div
                                    {...props}
                                    className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                                  >
                                    {children}
                                  </div>
                                )}
                                renderThumb={({ props }) => (
                                  <div
                                    {...props}
                                    className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                                  />
                                )}
                              />
                              <span>{input1Len[0]}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {numberList.length > 1 ? (
                  <div className="w-full px-3">
                    <div
                      className="mb-2"
                    >
                      <div className="flex flex-wrap -mx-1 overflow-hidden sm:-mx-1 md:-mx-1 lg:-mx-1 xl:-mx-4 bg-gray-tertiaryBox rounded-xl">
                        <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                          <div className="p-4">
                            <div className="pb-2">
                              <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                Input {numberList[1]}
                              </div>
                              <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                                What do you want the end user to input at {numberList[1]}
                              </div>
                            </div>
                          </div>
                          <div className="p-4">
                            <div className="w-full">
                              <Editable
                                className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                text={input2}
                                placeholder="What should the user input at this location?"
                                type="input"
                              >
                                <input
                                  className="w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                  name='test1'
                                  value={input2}
                                  placeholder="What should the user input at this location?"
                                  required
                                  type="text"
                                  errors={errors}
                                  onChange={e => setInput2(e.target.value)}
                                />
                              </Editable>
                            </div>
                          </div>
                        </div>

                        <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                          <div className="p-4">
                            <div className="pb-2">
                              <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                Input {numberList[1]} Length
                              </div>
                              <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                                What is the maximum length you would allow the end user to input at input {numberList[1]}?
                              </div>
                            </div>
                          </div>

                          <div className="p-4">
                            <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                              <Range
                                step={1}
                                min={0}
                                max={1000}
                                values={input2Len}
                                onChange={(input2Len) => {
                                  setInput2Len(input2Len)
                                }}
                                renderTrack={({ props, children }) => (
                                  <div
                                    {...props}
                                    className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                                  >
                                    {children}
                                  </div>
                                )}
                                renderThumb={({ props }) => (
                                  <div
                                    {...props}
                                    className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                                  />
                                )}
                              />
                              <span>{input2Len[0]}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {numberList.length > 2 ? (
                  <div className="w-full px-3">
                    <div
                      className="mb-2"
                    >
                      <div className="flex flex-wrap -mx-1 overflow-hidden sm:-mx-1 md:-mx-1 lg:-mx-1 xl:-mx-4 bg-gray-tertiaryBox rounded-xl">
                        <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                          <div className="p-4">
                            <div className="pb-2">
                              <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                Input {numberList[2]}
                              </div>
                              <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                                What do you want the end user to input at {numberList[2]}
                              </div>
                            </div>
                          </div>
                          <div className="p-4">
                            <div className="w-full">
                              <Editable
                                className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                text={input3}
                                placeholder="What should the user input at this location?"
                                type="input"
                              >
                                <input
                                  className="w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                  name='test1'
                                  value={input3}
                                  placeholder="What should the user input at this location?"
                                  required
                                  type="text"
                                  errors={errors}
                                  onChange={e => setInput3(e.target.value)}
                                />
                              </Editable>
                            </div>
                          </div>
                        </div>

                        <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                          <div className="p-4">
                            <div className="pb-2">
                              <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                Input {numberList[2]} Length
                              </div>
                              <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                                What is the maximum length you would allow the end user to input at input {numberList[2]}?
                              </div>
                            </div>
                          </div>

                          <div className="p-4">
                            <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                              <Range
                                step={1}
                                min={0}
                                max={1000}
                                values={input3Len}
                                onChange={(input3Len) => {
                                  setInput3Len(input3Len)
                                }}
                                renderTrack={({ props, children }) => (
                                  <div
                                    {...props}
                                    className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                                  >
                                    {children}
                                  </div>
                                )}
                                renderThumb={({ props }) => (
                                  <div
                                    {...props}
                                    className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                                  />
                                )}
                              />
                              <span>{input3Len[0]}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="w-full grid overflow-hidden grid-cols-2 auto-rows-max gap-4">
                <div className="box col-start-1 col-end-3 bg-gray-tertiaryBox rounded-xl p-4">
                  <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                    <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                      <div className="pb-2">
                        <div className="p-1">
                          <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                            Number of Runs
                          </div>
                          <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                            How many times do you want your model to run?
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                      <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                        <Range
                          step={1}
                          min={0}
                          max={50}
                          values={numRuns}
                          onChange={(numRuns) => {
                            setNumRuns(numRuns)
                          }}
                          renderTrack={({ props, children }) => (
                            <div
                              {...props}
                              className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                            >
                              {children}
                            </div>
                          )}
                          renderThumb={({ props }) => (
                            <div
                              {...props}
                              className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                            />
                          )}
                        />
                        <span>{numRuns[0]}</span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                    <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                      <div className="pb-2">
                        <div className="p-1">
                          <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                            Width
                          </div>
                          <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                            How wide in pixel do you want your image to be?
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                      <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                        <Range
                          step={128}
                          min={0}
                          max={1024}
                          values={width}
                          onChange={(width) => {
                            setWidth(width)
                          }}
                          renderTrack={({ props, children }) => (
                            <div
                              {...props}
                              className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                            >
                              {children}
                            </div>
                          )}
                          renderThumb={({ props }) => (
                            <div
                              {...props}
                              className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                            />
                          )}
                        />
                        <span>{width[0]}</span>
                      </div>
                    </div>
                  </div>


                  <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                    <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                      <div className="pb-2">
                        <div className="p-1">
                          <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                            Legnth
                          </div>
                          <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                            How long in pixels do you want your image to be?
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                      <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                        <Range
                          step={128}
                          min={0}
                          max={1024}
                          values={length}
                          onChange={(length) => {
                            setLength(length)
                          }}
                          renderTrack={({ props, children }) => (
                            <div
                              {...props}
                              className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                            >
                              {children}
                            </div>
                          )}
                          renderThumb={({ props }) => (
                            <div
                              {...props}
                              className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                            />
                          )}
                        />
                        <span>{length[0]}</span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                    <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                      <div className="pb-2">
                        <div className="rounded-xl p-1">
                          <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                            Number of Images
                          </div>
                          <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                            How many images do you want?
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                      <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                        <Range
                          step={1}
                          min={0}
                          max={16}
                          values={numImages}
                          onChange={(numImages) => {
                            setNumImages(numImages)
                          }}
                          renderTrack={({ props, children }) => (
                            <div
                              {...props}
                              className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                            >
                              {children}
                            </div>
                          )}
                          renderThumb={({ props }) => (
                            <div
                              {...props}
                              className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                            />
                          )}
                        />
                        <span>{numImages[0]}</span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                    <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                      <div className="pb-2">
                        <div className="p-1">
                          <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                            Uniqueness Value
                          </div>
                          <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                            How unique do you want each of your images
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                      <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                        <Range
                          step={1}
                          min={0}
                          max={15}
                          values={uniqueness}
                          onChange={(uniqueness) => {
                            setUniqueness(uniqueness)
                          }}
                          renderTrack={({ props, children }) => (
                            <div
                              {...props}
                              className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                            >
                              {children}
                            </div>
                          )}
                          renderThumb={({ props }) => (
                            <div
                              {...props}
                              className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                            />
                          )}
                        />
                        <span>{uniqueness[0]}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box col-start-1 col-end-3 bg-gray-tertiaryBox rounded-xl p-4">
                  <div className="flex flex-row justify-between">
                    <div>
                      <div className="">
                        <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                          Test Your Model!
                        </div>
                      </div>

                      <div className="">
                        <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                          These examples will show up on your model's page
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="my-2 px-2 w-full overflow-hidden">
                        <div className="w-full">
                          <button
                            className="w-full text-blue-primaryText bg-blue-primaryBox hover:bg-blue-hoverBox hover:text-blue-hoverText font-bold py-2 px-4 rounded-full"
                            type="generate_examples"
                            onClick={handleSubmit(onGenerateSubmit, onError)}
                          >
                            <span className=''>
                              {isLoadingExamples ?
                                <div className="flex items-center justify-center">
                                  <svg role="status" className="inline w-6 h-6 mx-16 text-blue-generateSpin animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#ffffff" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                  </svg>
                                </div>
                                : 'Generate Examples'}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex mb-5"
                    onKeyDown={(e) => handler(e)}>
                    {numberList.length > 0 ? (
                      <div className="w-full">
                        <div
                          className="mb-2 px-3"
                        >
                          <div className="flex flex-wrap w-full overflow-hidden">
                            <div className="my-2 px-2 w-full overflow-hidden">
                              <div className="pb-2">
                                <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                  {input1}
                                </div>
                              </div>
                            </div>
                            <div className="my-2 px-2 w-full overflow-hidden">
                              <div className="">
                                <TextArea
                                  className="px-4 py-3 w-full bg-gray-secondaryBox rounded-lg whitespace-pre-line"
                                  name='Exinput1'
                                  placeholder="Complete your input for example outputs"
                                  required
                                  type="text"
                                  errors={errors}
                                  onChangeText={(newText) => setExample1(newText)}
                                  maxLength={input1Len * 4}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="w-full flex mb-5"
                    onKeyDown={(e) => handler(e)}>
                    {numberList.length > 1 ? (
                      <div className="w-full">
                        <div
                          className="mb-2 px-3"
                        >
                          <div className="flex flex-wrap w-full overflow-hidden">
                            <div className="my-2 px-2 w-full">
                              <div className="pb-2">
                                <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                  {input2}
                                </div>
                              </div>
                            </div>
                            <div className="my-2 px-2 w-full">
                              <div className="">
                                <TextArea
                                  className="px-4 py-3 w-full bg-gray-secondaryBox rounded-lg whitespace-pre-line"
                                  name='Exinput2'
                                  placeholder="Complete your input for example outputs"
                                  required
                                  type="text"
                                  errors={errors}
                                  onChangeText={(newText) => setExample2(newText)}
                                  maxLength={input2Len * 4}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="w-full flex mb-5"
                    onKeyDown={(e) => handler(e)}>
                    {numberList.length > 2 ? (
                      <div className="w-full">
                        <div
                          className="mb-2 px-3"
                        >
                          <div className="flex flex-wrap w-full overflow-hidden">
                            <div className="my-2 px-2 w-full overflow-hidden">
                              <div className="pb-2">
                                <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                  {input3}
                                </div>
                              </div>
                            </div>
                            <div className="my-2 px-2 w-full overflow-hidden">
                              <div className="">
                                <TextArea
                                  className="px-4 py-3 w-full bg-gray-secondaryBox rounded-lg whitespace-pre-line"
                                  name='Exinput3'
                                  placeholder="Complete your input for example outputs"
                                  required
                                  type="text"
                                  errors={errors}
                                  onChangeText={(newText) => setExample3(newText)}
                                  maxLength={input3Len * 4}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="w-full overflow-hidden bg-gray-tertiaryBox rounded-xl">
                    <div className="w-full bg-gray-secondaryBox rounded-lg p-3 whitespace-pre-line">
                      {exampleOutput === 'Generate Examples Before Updating!' ? (
                        <div className="text-xl font-semibold whitespace-pre-line">
                          {exampleOutput.trim()}
                        </div>
                      ) : (
                        <div className='px-12'>
                          <div className='flex justify-center items-center'>
                            <img src={exampleOutput.trim()}></img>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-row justify-end pt-4">
                      <div className="my-2 px-2 overflow-hidden">
                        <div className="">
                          <Button
                            className="w-full text-blue-primaryText bg-blue-primaryBox hover:bg-blue-hoverBox hover:text-blue-hoverText font-bold py-2 px-4 rounded-full"
                            type="add"
                            onClick={handleSubmit(addExamples, onError)}
                          >
                            + Add Example
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box col-start-1 col-end-3 bg-gray-tertiaryBox rounded-xl p-4">
                  <p className="text-left h3 mr-5 leading-tighter tracking-tighter">
                    Model Examples
                  </p>
                  <p className="text-left mr-5 leading-tighter tracking-tighter">
                    These examples will be published on your model page. (Need 3+)
                  </p>
                  {exampleList.length > 0 ? (
                    <div className="w-full">
                      {exampleList.map((example, index) => (
                        <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                          <div className="my-2 px-2 w-full overflow-hidden">
                            <div className="bg-gray-secondaryBox rounded-xl">
                              <div className="p-3">
                                <div className="">
                                  <div className="flex justify-end items-end pb-2">
                                    <button
                                      className="rounded-full bg-red-quasi px-2 hover:bg-red-quasidark"
                                      type="button"
                                      onClick={() => {
                                        var newExampleList = exampleList
                                        newExampleList.splice(index, 1)
                                        var newExampleInputList = exampleInputList
                                        newExampleInputList.splice(index, 1)
                                        setExampleInputList(newExampleInputList)
                                        forceUpdate()
                                      }}
                                    >
                                      -
                                    </button>
                                  </div>
                                  {example1 !== "" ? (
                                    <div className="font-semibold text-lg mb-2 px-3">
                                      <span className="text-left bg-clip-text text-transparent bg-green-mainText">
                                        Input: {" "}
                                      </span>
                                      {" " + exampleInputList[index]}
                                    </div>
                                  ) : null}
                                  <div className="text-lg font-semibold mb-2 px-3 whitespace-pre-line">
                                    <span className="text-left bg-clip-text text-transparent bg-green-mainText whitespace-pre-line">
                                      Output: {" "}
                                    </span>
                                    <div className='flex justify-center items-center'>
                                      <img src={example.trim()}></img>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : <div className='font-semibold text-lg pt-6'>
                    No examples added yet...
                  </div>}
                </div>

                <div className="w-full px-3 flex mb-5">
                  {numberList.length > 1 ? (
                    <div className="w-full">
                      <div
                        className="mb-2 px-3"
                      >
                        <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                          <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                            <div className="pb-2">
                              <div className="bg-gray-white rounded-xl p-1">
                                <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                  Input {numberList[1]}
                                </div>
                                <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                                  What do you want the end user to input at {numberList[1]}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                            <div className="">
                              <Editable
                                className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                text={input2}
                                placeholder="Write a task name"
                                type="input"
                              >
                                <input
                                  className="w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                  name='test1'
                                  value={input2}
                                  placeholder="Enter user input prompt"
                                  required
                                  type="text"
                                  errors={errors}
                                  onChange={e => setInput2(e.target.value)}
                                />
                              </Editable>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                          <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                            <div className="pb-2">
                              <div className="bg-gray-white rounded-xl p-1">
                                <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                  Input {numberList[1]} Length
                                </div>
                                <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                                  What is the maximum length you would allow the end user to input at input {numberList[1]}?
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                            <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                              <Range
                                step={1}
                                min={0}
                                max={1000}
                                values={input2Len}
                                onChange={(input2Len) => {
                                  setInput2Len(input2Len)
                                }}
                                renderTrack={({ props, children }) => (
                                  <div
                                    {...props}
                                    className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                                  >
                                    {children}
                                  </div>
                                )}
                                renderThumb={({ props }) => (
                                  <div
                                    {...props}
                                    className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                                  />
                                )}
                              />
                              <span>{input2Len[0]}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (null)}
                </div>

                <div className="w-full px-3 flex mb-5">
                  {numberList.length > 2 ? (
                    <div className="w-full">
                      <div
                        className="mb-2 px-3"
                      >
                        <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                          <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                            <div className="pb-2">
                              <div className="bg-gray-white rounded-xl p-1">
                                <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                  Input {numberList[2]}
                                </div>
                                <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                                  What do you want the end user to input at {numberList[2]}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                            <div className="">
                              <Editable
                                className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                text={input3}
                                placeholder="Write a task name"
                                type="input"
                              >
                                <input
                                  className="w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                  name='test1'
                                  value={input3}
                                  placeholder="Enter user input prompt"
                                  required
                                  type="text"
                                  errors={errors}
                                  onChange={e => setInput3(e.target.value)}
                                />
                              </Editable>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                          <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                            <div className="pb-2">
                              <div className="bg-gray-white rounded-xl p-1">
                                <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                  Input {numberList[2]} Length
                                </div>
                                <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                                  What is the maximum length you would allow the end user to input at input {numberList[2]}?
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                            <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                              <Range
                                step={1}
                                min={0}
                                max={1000}
                                values={input3Len}
                                onChange={(input3Len) => {
                                  setInput3Len(input3Len)
                                }}
                                renderTrack={({ props, children }) => (
                                  <div
                                    {...props}
                                    className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                                  >
                                    {children}
                                  </div>
                                )}
                                renderThumb={({ props }) => (
                                  <div
                                    {...props}
                                    className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                                  />
                                )}
                              />
                              <span>{input3Len[0]}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="box col-start-1 col-end-3">
                  {isLoading ? (
                    <ReactLoading
                      type={"spin"}
                      color={"#C3A6EC"}
                      height={"5%"}
                      width={"5%"}
                    />
                  ) : (
                    <Button
                      className="w-full text-purple-primaryText bg-purple-primaryBox hover:bg-purple-hoverBox hover:text-purple-hoverText font-bold py-4 px-4 rounded-full"
                      type="submit"
                    >
                      Update Model!
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Card>
        <Footer />
      </section>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    user: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(EditImageModelPartial);