import { saveAs } from "file-saver";
import firebase from "firebase";
import { default as $, default as jQuery } from "jquery";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import MediaQuery from "react-responsive";
import { useHistory } from "react-router";
import ShareButton from "react-web-share-button";
import ReactiveButton from "reactive-button";
import { db } from "../firebase.config";
import { generateOutput } from "../merlinv1/beta_api";
import Editable from "../pages/components/Editable";
import LongerTextArea from "../pages/components/LongerTextArea";
import { generateUsingSandbox } from "../merlinv1/beta_api";

const SandboxPartial = (props) => {
  let phrases = [
    "Gathering The Brushes ",
    "Laying The Foundation ",
    "Painting The Picture ",
    "Filling In The Color ",
    "Finishing Little Details ",
  ];

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const [length, setLength] = useState(1500);
  const [isRefresh, setIsRefresh] = useState(false);
  const [state, setState] = useState("idle");
  const [isGenerateDisable, setIsGenerateDisable] = useState(false);
  const [textArea, setTextArea] = useState("");
  const [prompt, setPrompt] = useState("");
  const [output, setOutput] = useState("");
  const history = useHistory();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [descriptionVisible, setDescriptionVisible] = useState(false);

  let searchParams = new URLSearchParams(window.location.pathname);

  const onClickHandler = async () => {
    if (textArea != "") {
      if (isRefresh) {
        setState("loading");
        const res = await generateUsingSandbox(
          prompt.trim().replace(/\n+$/, ""),
          length
        );
        setOutput(res.data.response);
        setTextArea(prompt + res.data.response);
        setState("success");
      } else {
        setPrompt(textArea);
        setState("loading");
        const res = await generateUsingSandbox(
          textArea.trim().replace(/\n+$/, ""),
          length
        );
        setOutput(res.data.response);
        setTextArea(textArea + res.data.response);
        setState("success");
        setIsRefresh(true);
      }
    }
  };

  const onContinueClickHandler = async () => {
    if (textArea != "") {
      setPrompt(textArea);
      setState("loading");
      const res = await generateUsingSandbox(
        textArea.trim().replace(/\n+$/, ""),
        length
      );
      setOutput(res.data.response);
      setTextArea(textArea + res.data.response);
      setState("success");
      setIsRefresh(true);
    }
  };

  if (isPageLoading) {
    return (
      <div className='w-full h-screen flex justify-center items-center'>
        <ReactLoading type='spin' color='#6a4c93' height='3%' width='3%' />
      </div>
    );
  } else {
    return (
      <section>
        <div className='lg:px-20 sm:px-5 pt-24 pb-12'>
          <div className='flex justify-between'>
            <div className='flex flex-row items-center'>
              <div className='flex flex-row flex-wrap justify-between'>
                <div className='flex md:flex-row md:items-center sm:flex-col sm:items-start'>
                  <div className='h2 font-bold leading-tighter tracking-tighter'>
                    Sandbox
                  </div>
                </div>
              </div>
              <button
                onClick={() => setDescriptionVisible(!descriptionVisible)}
                className='bg-yellow-info px-3 py-1 flex flex-row items-center rounded-xl md:mx-2 sm:mr-2'>
                <svg
                  width='22'
                  height='22'
                  viewBox='0 0 22 22'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M11 21.1348C16.5859 21.1348 21.1855 16.5352 21.1855 10.9492C21.1855 5.37305 16.5762 0.763672 10.9902 0.763672C5.41406 0.763672 0.814453 5.37305 0.814453 10.9492C0.814453 16.5352 5.41406 21.1348 11 21.1348ZM11 18.8984C6.5957 18.8984 3.06055 15.3535 3.06055 10.9492C3.06055 6.54492 6.58594 3 10.9902 3C15.4043 3 18.9395 6.54492 18.9492 10.9492C18.959 15.3535 15.4043 18.8984 11 18.8984ZM10.9609 7.75586C11.7227 7.75586 12.3379 7.13086 12.3379 6.37891C12.3379 5.59766 11.7227 4.98242 10.9609 4.98242C10.1992 4.98242 9.58398 5.59766 9.58398 6.37891C9.58398 7.13086 10.1992 7.75586 10.9609 7.75586ZM9.26172 16.2227H13.2168C13.6855 16.2227 14.0469 15.8809 14.0469 15.4121C14.0469 14.9629 13.6855 14.6113 13.2168 14.6113H12.2207V10.2266C12.2207 9.60156 11.9082 9.19141 11.3125 9.19141H9.4375C8.97852 9.19141 8.60742 9.54297 8.60742 9.99219C8.60742 10.4707 8.97852 10.8027 9.4375 10.8027H10.3945V14.6113H9.26172C8.80273 14.6113 8.43164 14.9629 8.43164 15.4121C8.43164 15.8809 8.80273 16.2227 9.26172 16.2227Z'
                    fill='black'
                  />
                </svg>
                <span className='pl-2'>Info</span>
              </button>
            </div>
            <div className='flex flex-row justify-end'>
              <select
                onChange={(e) => {
                  setLength(parseInt(e.target.value));
                  console.log(e.target.value);
                }}
                name='length'
                className='w-full font-semibold my-2 focus:outline-none bg-purple-primaryBox text-purple-primaryText rounded-squircle'>
                <option value={500}>Output Length - Long</option>
                <option value={200}>Output Length - Medium</option>
                <option value={50}>Output Length - Short</option>
              </select>
              <div className='px-1' />
              <div className='btn-sm text-gray-secondaryText bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox hover:text-gray-secondaryHoverText font-bold rounded-squircle my-2'>
                <ShareButton
                  title={"Quasi Sandbox"}
                  text='Checkout this cool AI model on Quasi!'
                  url={"https://quasi.market/sandbox"}
                  buttonText={
                    <div className='flex flex-row items-center'>
                      <svg
                        width='18'
                        height='23'
                        viewBox='0 0 18 23'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M8.94531 14.6875C9.50195 14.6875 9.94141 14.2383 9.94141 13.7109V4.01367L9.86328 2.56836L10.4297 3.30078L11.709 4.66797C11.8848 4.86328 12.1289 4.96094 12.373 4.96094C12.8516 4.96094 13.252 4.60938 13.252 4.11133C13.252 3.85742 13.1543 3.66211 12.9785 3.48633L9.7168 0.351562C9.45312 0.0976562 9.20898 0 8.94531 0C8.68164 0 8.42773 0.0976562 8.17383 0.351562L4.91211 3.48633C4.72656 3.66211 4.62891 3.85742 4.62891 4.11133C4.62891 4.60938 5.0293 4.96094 5.50781 4.96094C5.75195 4.96094 6.00586 4.86328 6.17188 4.66797L7.46094 3.30078L8.02734 2.56836L7.93945 4.01367V13.7109C7.93945 14.2383 8.38867 14.6875 8.94531 14.6875ZM3.31055 22.5781H14.5801C16.748 22.5781 17.8906 21.4355 17.8906 19.2969V9.78516C17.8906 7.64648 16.748 6.50391 14.5801 6.50391H11.9922V8.68164H14.3945C15.2441 8.68164 15.7129 9.12109 15.7129 10.0098V19.0723C15.7129 19.9707 15.2441 20.4004 14.3945 20.4004H3.49609C2.63672 20.4004 2.17773 19.9707 2.17773 19.0723V10.0098C2.17773 9.12109 2.63672 8.68164 3.49609 8.68164H5.92773V6.50391H3.31055C1.15234 6.50391 0 7.64648 0 9.78516V19.2969C0 21.4453 1.15234 22.5781 3.31055 22.5781Z'
                          fill='#333333'
                        />
                      </svg>
                    </div>
                  }
                  className='font-xl'></ShareButton>
              </div>
            </div>
          </div>
          {descriptionVisible ? (
            <div
              className='bg-yellow-info rounded-xl mb-8'
              data-aos='fade-down'>
              <div className='md:p-4 sm:p-2'>
                <div className=''>
                  <div className='flex flex-row flex-wrap items-center justify-between pb-5'>
                    <div className='h3 mr-5 font-bold leading-tighter tracking-tighter'>
                      Description
                    </div>
                    <button
                      onClick={() => setDescriptionVisible(false)}
                      className='bg-yellow-infoClose flex flex-row items-center py-1 px-3 rounded-xl'>
                      <svg
                        width='17'
                        height='18'
                        viewBox='0 0 17 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M0.953125 16.5566C1.44141 17.0449 2.26172 17.0254 2.73047 16.5664L8.55078 10.7461L14.3613 16.5664C14.8398 17.0449 15.6602 17.0449 16.1387 16.5566C16.6172 16.0684 16.627 15.2578 16.1484 14.7793L10.3379 8.95898L16.1484 3.14844C16.627 2.66992 16.627 1.85938 16.1387 1.37109C15.6504 0.892578 14.8398 0.882812 14.3613 1.36133L8.55078 7.18164L2.73047 1.36133C2.26172 0.902344 1.43164 0.882812 0.953125 1.37109C0.474609 1.85938 0.484375 2.67969 0.943359 3.13867L6.76367 8.95898L0.943359 14.7891C0.484375 15.248 0.464844 16.0781 0.953125 16.5566Z'
                          fill='#725300'
                        />
                      </svg>
                      <span className='pl-2'>Close</span>
                    </button>
                  </div>
                  <div className='text-xl font-semibold whitespace-pre-line mb-4 pb-5'>
                    The following is a sandbox to input directly into a text
                    model
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className='bg-gray-tertiaryBox rounded-2xl'>
            <div className='p-5'>
              <LongerTextArea
                type='textarea'
                className='px-4 py-3 w-full text-gray-800 bg-gray-secondaryBox rounded-xl whitespace-pre-line'
                name='sandbox'
                type='sandbox'
                placeholder='Write a tagline for an ice cream shop.'
                rows='12'
                value={textArea}
                onChangeText={(newText) => {
                  setTextArea(newText);
                  setIsRefresh(false);
                }}
              />
            </div>
          </div>
          <div className='pb-2' />
          <div className='flex flex-row'>
            <ReactiveButton
              buttonState={state}
              onClick={onClickHandler}
              color={"primary green"}
              idleText={isRefresh ? "Refresh" : "Generate >>"}
              loadingText={"Loading"}
              successText={"Success"}
              errorText={"Error"}
              type={"button"}
              className={""}
              style={{ borderRadius: "0.875rem" }}
              outline={false}
              shadow={false}
              rounded={false}
              size={"normal"}
              block={false}
              messageDuration={1000}
              disabled={isGenerateDisable}
              buttonRef={null}
              width={250}
              height={null}
              animation={false}
            />
            <div className='mx-1' />
            <button
              onClick={() => {
                onContinueClickHandler();
              }}
              className='text-gray-secondaryText bg-gray-secondaryBox hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox font-bold text-md sm:px-1 md:px-4 rounded-squircle'>
              <span className='font-bold'>Continue >></span>
            </button>
          </div>
        </div>
      </section>
    );
  }
};

export default SandboxPartial;
