// this file will contain the Axios setup link between API and Front-end
import firebase from "firebase/app";
import "firebase/auth";
import merlinApp from "../AxiosSetup.js";

// POST request functions

export const enterpriseCreate = (
  companyName,
  phoneNum,
  email,
  password,
  firstname,
  lastname,
  outreachType,
  referralID
) => {
  return merlinApp.post("/auth/enterprise", {
    data: {
      user_name: companyName,
      phone_number: phoneNum,
      email: email,
      password: password,
      first_name: firstname,
      last_name: lastname,
      outreach_type: outreachType,
      referralID: referralID,
    },
  });
};

export const createProduct = async (
  status,
  modelTitle,
  openAI,
  userClass,
  category,
  prompt,
  engine,
  stopSec,
  prefix,
  temperature,
  responseLen,
  topP,
  frequencyPenalty,
  presencePenalty,
  bestOf,
  exampleList,
  exampleInputList,
  input1,
  input2,
  input3,
  model_description,
  input1Len,
  input2Len,
  input3Len
) => {
  return merlinApp.post(
    "/enterprise/createProduct",
    {
      data: {
        status: status,
        modelTitle: modelTitle,
        key: openAI,
        category: category,
        userClass: userClass,
        prompt: prompt,
        engine: engine,
        stopSec: stopSec,
        prefix: prefix,
        credits: 1,
        temperature: temperature,
        responseLen: responseLen,
        topP: topP,
        frequencyPenalty: frequencyPenalty,
        presencePenalty: presencePenalty,
        bestOf: bestOf,
        exampleList: exampleList,
        exampleInputList: exampleInputList,
        input1: input1,
        input2: input2,
        input3: input3,
        model_description: model_description,
        input1Len: input1Len,
        input2Len: input2Len,
        input3Len: input3Len,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const createImageModel = async (
  status,
  modelTitle,
  prompt,
  engine,
  userClass,
  //credits,
  numRuns,
  width,
  length,
  numImages,
  uniqueness,
  exampleList,
  exampleInputList,
  input1,
  input2,
  input3,
  model_description,
  input1Len,
  input2Len,
  input3Len
) => {
  return merlinApp.post(
    "/user/createImageModel",
    {
      data: {
        status: status,
        modelTitle: modelTitle,
        prompt: prompt,
        engine: engine,
        userClass: userClass,
        credits: 0,
        numRuns: numRuns,
        width: width,
        length: length,
        numImages: numImages,
        uniqueness: uniqueness,
        exampleList: exampleList,
        exampleInputList: exampleInputList,
        input1: input1,
        input2: input2,
        input3: input3,
        model_description: model_description,
        input1Len: input1Len,
        input2Len: input2Len,
        input3Len: input3Len,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const editModel = async (
  modelTitle,
  openAI,
  userClass,
  category,
  prompt,
  engine,
  stopSec,
  prefix,
  credits,
  temperature,
  responseLen,
  topP,
  frequencyPenalty,
  presencePenalty,
  bestOf,
  exampleList,
  exampleInputList,
  input1,
  input2,
  input3,
  model_description,
  modelId,
  input1Len,
  input2Len,
  input3Len
) => {
  return merlinApp.post(
    "/enterprise/editModel",
    {
      data: {
        modelTitle: modelTitle,
        key: openAI,
        userClass: userClass,
        category: category,
        prompt: prompt,
        engine: engine,
        stopSec: stopSec,
        prefix: prefix,
        credits: credits,
        temperature: temperature,
        responseLen: responseLen,
        topP: topP,
        frequencyPenalty: frequencyPenalty,
        presencePenalty: presencePenalty,
        bestOf: bestOf,
        exampleList: exampleList,
        exampleInputList: exampleInputList,
        input1: input1,
        input2: input2,
        input3: input3,
        model_description: model_description,
        modelId: modelId,
        input1Len: input1Len,
        input2Len: input2Len,
        input3Len: input3Len,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const editImageModel = async (
  modelTitle,
  prompt,
  engine,
  credits,
  numRuns,
  width,
  length,
  numImages,
  uniqueness,
  exampleList,
  exampleInputList,
  input1,
  input2,
  input3,
  model_description,
  modelId,
  input1Len,
  input2Len,
  input3Len,
  userClass
) => {
  return merlinApp.post(
    "/enterprise/editImageModel",
    {
      data: {
        modelTitle: modelTitle,
        prompt: prompt,
        engine: engine,
        credits: credits,
        numRuns: numRuns,
        width: width,
        length: length,
        numImages: numImages,
        uniqueness: uniqueness,
        exampleList: exampleList,
        exampleInputList: exampleInputList,
        input1: input1,
        input2: input2,
        input3: input3,
        model_description: model_description,
        modelId: modelId,
        input1Len: input1Len,
        input2Len: input2Len,
        input3Len: input3Len,
        userClass: userClass,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const hideModel = async (modelID) => {
  return merlinApp.post(
    "/hide_model",
    {
      data: {
        modelID: modelID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const showModel = async (modelID) => {
  return merlinApp.post(
    "/show_model",
    {
      data: {
        modelID: modelID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const unlistModel = async (modelID) => {
  return merlinApp.post(
    "/unlist_model",
    {
      data: {
        modelID: modelID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const generateExamples = async (
  openAI,
  prompt,
  engine,
  stopSec,
  prefix,
  temperature,
  responseLen,
  topP,
  frequencyPenalty,
  presencePenalty,
  bestOf,
  example1,
  example2,
  example3
) => {
  return merlinApp.post("/user/generateExample", {
    data: {
      key: openAI,
      prompt: prompt,
      engine: engine,
      stopSec: stopSec,
      prefix: prefix,
      temperature: temperature,
      responseLen: responseLen,
      topP: topP,
      frequencyPenalty: frequencyPenalty,
      presencePenalty: presencePenalty,
      bestOf: bestOf,
      example1: example1,
      example2: example2,
      example3: example3,
    },
  });
};

export const generateImageExamples = async (
  prompt,
  engine,
  numRuns,
  width,
  length,
  numImages,
  uniqueness,
  example1,
  example2,
  example3
) => {
  return merlinApp.post(
    "/user/generateImageExample",
    {
      data: {
        prompt: prompt,
        engine: engine,
        numRuns: numRuns,
        width: width,
        length: length,
        numImages: numImages,
        uniqueness: uniqueness,
        example1: example1,
        example2: example2,
        example3: example3,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const likeModel = async (modelID, outputID) => {
  return merlinApp.post(
    "/like_model",
    {
      data: {
        modelID: modelID,
        outputID: outputID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const dislikeModel = async (modelID, outputID) => {
  return merlinApp.post(
    "/dislike_model",
    {
      data: {
        modelID: modelID,
        outputID: outputID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getUserDashboard = async () => {
  return merlinApp.get("/getUserDashboard", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const getUserDashboardData = async () => {
  return merlinApp.get("/getUserDashboardData", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const getAmount = async () => {
  return merlinApp.get("/getAmount", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const completePaymentOut = async (amount, stripeExpressCode) => {
  return merlinApp.post(
    "/completePaymentOut",
    {
      data: {
        amount: amount,
        stripeExpressCode: stripeExpressCode,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getTokens = async (text) => {
  return merlinApp.post("/getTokens", {
    data: {
      text: text,
    },
  });
};

export const getBountyList = async (auth) => {
  return merlinApp.get("/getBountyList", {
    headers: {
      Authorization: auth,
    },
  });
};

export const submitBounty = async (bountyId, modelLink, auth) => {
  return merlinApp.post(
    "/submitBounty",
    {
      data: {
        id: bountyId,
        link: modelLink,
      },
    },
    {
      headers: {
        Authorization: auth,
      },
    }
  );
};

export const getBadges = async () => {
  return merlinApp.get("/getBadges", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const getModelContent = async (modelId, auth) => {
  return merlinApp.get("/create/model=" + modelId, {
    headers: {
      Authorization: auth,
    },
  });
};

export const getCreationContent = async (creationId) => {
  return merlinApp.get("/creation/output=" + creationId, {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const getGuestCreationContent = async (creationId) => {
  return merlinApp.get("/guestCreation/output=" + creationId);
};

// This is going to submit the contact form using the data it has taken in
export const submitContactForm = (name, email, phone, message) => {
  return merlinApp.post("/contact", {
    data: {
      name,
      email,
      phone,
      message,
    },
  });
};

// This method is going to attach a stripe payment method to the customer in stripe
export const attachPaymentToCustomer = async (paymentMethod) => {
  return merlinApp.post(
    "/enterprise/stripe/attach",
    {
      data: {
        payment_method: paymentMethod,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

// This method is going to get a stripe customer object for this user
export const getStripeCustomer = async () => {
  return merlinApp.get("/enterprise/stripe/customer", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const startFreeTrial = async () => {
  return merlinApp.get("/enterprise/stripe/startFreeTrial", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const cancelSubscription = async () => {
  return merlinApp.get("/enterprise/stripe/cancelSubscription", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const addListens = async (listens) => {
  return merlinApp.post("/addListens", {
    data: {
      Listens: listens,
    },
  });
};

export const updateAPIKey = async (key) => {
  return merlinApp.post(
    "/user/updateAPIKey",
    {
      data: {
        APIKey: key,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const changeModelKeys = async (key) => {
  return merlinApp.post(
    "/user/changeModelKeys",
    {
      data: {
        APIKey: key,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getModels = async (modelID) => {
  return merlinApp.get("/getModels", {
    data: {
      modelID: modelID,
    },
  });
};

export const getRandomModel = async () => {
  return merlinApp.get("/getRandomModel");
};

export const getTrendingModels = async (modelID) => {
  return merlinApp.get("/getTrendingModels", {
    data: {
      modelID: modelID,
    },
  });
};

export const getCreationModels = async (filter, type, number) => {
  return merlinApp.post("/getCreationModels", {
    data: {
      filter: filter,
      type: type,
      number: number,
    },
  });
};

export const getAllCreationModels = async () => {
  return merlinApp.get("/getAllCreationModels");
};

export const searchModelsByClass = async (userClass) => {
  return merlinApp.post("/searchModelsByClass", {
    data: {
      userClass: userClass,
    },
  });
};

export const getMetrics = async (modelID) => {
  return merlinApp.get("/getMetrics", {
    data: {
      modelID: modelID,
    },
  });
};

export const searchModels = async (searchText, filter) => {
  return merlinApp.post("/searchModels", {
    data: {
      searchText: searchText,
      filter: filter,
    },
  });
};

export const createFlow = async (ids, category, status, title, description) => {
  return merlinApp.post(
    "/createFlow",
    {
      data: {
        models: ids,
        category: category,
        status: status,
        Flow_title: title,
        Flow_description: description,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getFlow = async (id) => {
  return merlinApp.post(
    "/getFlow",
    {
      data: {
        Model_id: id,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const editFlow = async (
  ids,
  category,
  status,
  title,
  description,
  flow_id
) => {
  return merlinApp.post(
    "/editFlow",
    {
      data: {
        ids: ids,
        category: category,
        visibility: status,
        title: title,
        description: description,
        flow_id: flow_id,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const searchGallery = async (searchText) => {
  return merlinApp.post("/searchGallery", {
    data: {
      searchText: searchText,
    },
  });
};

export const searchMusic = async (searchText) => {
  return merlinApp.post("/searchMusic", {
    data: {
      searchText: searchText,
    },
  });
};

export const searchGalleryList = async (searchText, page, pageNum) => {
  return merlinApp.post("/searchGalleryList", {
    data: {
      searchText: searchText,
      page: page,
      pageNum: pageNum,
    },
  });
};

export const updateGalleryItem = async (outputId, event) => {
  return merlinApp.post(
    "/updateGalleryItem",
    {
      data: {
        output_id: outputId,
        event: event,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const searchUserModels = async (searchText) => {
  return merlinApp.post(
    "/searchUserModels",
    {
      data: {
        searchText: searchText,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getHomePage = async (textIds, codeIds) => {
  return merlinApp.post("/getHomePage", {
    data: {
      textIds: textIds,
      codeIds: codeIds,
    },
  });
};

export const requestModel = async (modelTitle, modelDescription) => {
  return merlinApp.post(
    "/requestModel",
    {
      data: {
        modelTitle: modelTitle,
        modelDescription: modelDescription,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const askQuestion = async (
  questions,
  answers,
  currentQuestion,
  subject
) => {
  return merlinApp.post(
    "/ask_any_question",
    {
      data: {
        questions: questions,
        answers: answers,
        current_question: currentQuestion,
        subject: subject,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const addBounty = async (modelTitle, modelDescription, modelType) => {
  return merlinApp.post(
    "/addBounty",
    {
      data: {
        modelTitle: modelTitle,
        modelDescription: modelDescription,
        modelType: modelType,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getEditModel = async (modelID) => {
  return merlinApp.get("/edit/model=" + modelID, {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const getAnalyticsModel = async (modelID) => {
  return merlinApp.get("/analytics/model=" + modelID, {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const getUserModels = async () => {
  return merlinApp.get("/getUserModels", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const getMusic = async () => {
  return merlinApp.get("/getMusic");
};

export const convertEarnings = async () => {
  return merlinApp.get("/convertEarnings", {
    headers: {
      Authorization: await firebase.auth().currentUser.getIdToken(),
    },
  });
};

export const getCreationList = async (pageNum) => {
  return merlinApp.post(
    "/getCreationList",
    {
      data: {
        pageNum: pageNum,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const searchCreationList = async (searchText, filter, pageNum) => {
  return merlinApp.post(
    "/searchCreationList",
    {
      data: {
        searchText: searchText,
        filter: filter,
        pageNum: pageNum,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const followModel = async (modelID) => {
  return merlinApp.post(
    "/followModel",
    {
      data: {
        modelID: modelID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const unfollowModel = async (modelID) => {
  return merlinApp.post(
    "/unfollowModel",
    {
      data: {
        modelID: modelID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const setUserType = async (type) => {
  return merlinApp.post(
    "/setUserType",
    {
      data: {
        type: type,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const generateOutput = async (modelID, input1, input2, input3) => {
  return merlinApp.post(
    "/UseModels",
    {
      data: {
        modelID: modelID,
        userInput1: input1,
        userInput2: input2,
        userInput3: input3,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const generateDemo = async (modelID, input1, input2, input3) => {
  return merlinApp.post("/UseDemo", {
    data: {
      modelID: modelID,
      userInput1: input1,
      userInput2: input2,
      userInput3: input3,
    },
  });
};

export const addToMailingList = async (
  first_name,
  last_name,
  email,
  company_name,
  work_number,
  industry,
  time_zone,
  check_list,
  comments
) => {
  return merlinApp.post("/addToMail", {
    data: {
      first_name: first_name,
      last_name: last_name,
      email: email,
      company_name: company_name,
      work_number: work_number,
      industry: industry,
      time_zone: time_zone,
      check_list: check_list,
      comments: comments,
    },
  });
};

export const generateUsingSandbox = async (prompt, length) => {
  return merlinApp.post(
    "/UseSandbox",
    {
      data: {
        Prompt: prompt,
        Response_len: length,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const generateImageOutput = async (modelID, input1, input2, input3) => {
  return merlinApp.post(
    "/MakeImage",
    {
      data: {
        modelID: modelID,
        userInput1: input1,
        userInput2: input2,
        userInput3: input3,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const MakeForkImager = async (modelID, input1, input2, input3) => {
  return merlinApp.post(
    "/UseFork",
    {
      data: {
        modelID: modelID,
        userInput1: input1,
        userInput2: input2,
        userInput3: input3,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getGalleryLikes = async () => {
  return merlinApp.post(
    "/getGalleryLikes",
    {
      data: {},
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getUserHeader = async () => {
  return merlinApp.post(
    "/getUserHeader",
    {
      data: {},
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const publishOutput = async (outputID) => {
  return merlinApp.post(
    "/publishOutput",
    {
      data: {
        outputID: outputID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const privateOutput = async (outputID) => {
  return merlinApp.post(
    "/privateOutput",
    {
      data: {
        outputID: outputID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const CreateOrUpdateUser = async () => {
  return merlinApp.post(
    "/CreateOrUpdateUser",
    {
      data: {},
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

// This method is going to charge a customer a specified one time transaction
export const createStripePayment = async (
  stripeID,
  amount,
  codeApplied,
  numCredits,
  paymentID = ""
) => {
  return merlinApp.post(
    "/enterprise/stripe/payment",
    {
      data: {
        customer_stripe_id: stripeID,
        amount: amount,
        has_coupon: codeApplied !== "",
        coupon_code: codeApplied,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

// This method is going to retrieve a coupon based on the code
export const getStripeCouponCode = async (couponCode) => {
  return merlinApp.post(
    "/enterprise/stripe/coupon",
    {
      data: {
        coupon_code: couponCode,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

// This method is going to subscribe a customer to a specific product
export const createStripeSubscription = async (
  customerID,
  priceID,
  code_applied
) => {
  return merlinApp.post(
    "/enterprise/stripe/subscription",
    {
      data: {
        customer_stripe_id: customerID,
        price_id: priceID,
        code_applied: code_applied,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

// Will return a true/false boolean whether or not the customer is subscribed
export const isCustomerSubscribed = async (customerStripeID) => {
  return merlinApp.post(
    "/enterprise/stripe/subscriptionstatus",
    {
      data: {
        customer_stripe_id: customerStripeID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

// Will subscribe a customer
export const subscribeCustomer = async (customerStripeID, codeApplied) => {
  return merlinApp.post(
    "/enterprise/stripe/subscribe",
    {
      data: {
        stripe_customer_id: customerStripeID,
        code_applied: codeApplied,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getFollowing = async () => {
  return merlinApp.post(
    "/get_following",
    {
      data: {},
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const UseFeebo = async (model_id, contentList) => {
  return merlinApp.post(
    "/use_feebo",
    {
      data: {
        currentIdeaDict: contentList[0],
        model_id: model_id,
        oldIdeaDict: contentList[3],
        dislike_count: contentList[2],
        current_action: contentList[1],
        old_action: contentList[4],
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const saveData = async (outputs) => {
  //save_outputs
  return merlinApp.post(
    "/save_outputs",
    {
      data: {
        outputs: { outputs: outputs },
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const optimizeInput = async (input) => {
  return merlinApp.post(
    "/optimizeInput",
    {
      data: {
        input: input,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const feeboLoad = async () => {
  return merlinApp.post(
    "/feebo_load",
    {
      data: {},
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const getWordSuggestions = async (word) => {
  return merlinApp.post(
    "/getWordSuggestions",
    {
      data: {
        word: word,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

export const checkOutput = async (outputID) => {
  return merlinApp.post(
    "/checkOutput",
    {
      data: {
        outputID: outputID,
      },
    },
    {
      headers: {
        Authorization: await firebase.auth().currentUser.getIdToken(),
      },
    }
  );
};

// Method is going to log a specific event in Cloud Firestore
export const logFirestoreEvent = (eventName, eventPaylod) => {
  if (window.location.host !== "www.quasi.market") {
    if (eventName === "404 Error") {
      console.log({ eventName, eventPaylod });
    }
  } else {
    return merlinApp.post("/log", {
      data: {
        key: "RH5cFnBB0t",
        event_name: eventName,
        event_payload: eventPaylod,
      },
    });
  }
};
