import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { getAmount } from "../merlinv1/beta_api";
import { useHistory } from "react-router";
import ReactLoading from "react-loading";
import { convertEarnings } from '../merlinv1/beta_api';

const ConvertParital = React.forwardRef((props, ref) => {

    const [isLoading, setIsLoading] = useState(true);
    const [isPayoutLoading, setIsPayoutLoading] = useState(false);
    const [amount, setAmount] = useState();
    const [amountOfCoins, setAmountOfCoins] = useState();
    const [stripeExpressCode, setStripeExpressCode] = useState();
    const history = useHistory();

    const fetchData = async () => {
        try {
            var stripeReturnCode = window.location.search

            setStripeExpressCode(stripeReturnCode)

            const res = await getAmount();
            setAmount(res.data.earnings)
            setAmountOfCoins(Math.floor(res.data.earnings * 100 / 5))

        } catch (err) {
            history.push("/ErrorPage");
        }
    }

    const handleClick = async () => {
        setIsPayoutLoading(true)
        try {
            const res = await convertEarnings();
            history.push("/user/dashboard/");
        } catch (err) {
            history.push("/ErrorPage");
        }
        setIsPayoutLoading(false)
    }

    useEffect(() => {
        fetchData()
        setIsLoading(false)
    }, []);

    if (isLoading) {
        return (
            <div className="w-full h-screen flex justify-center items-center">
                <ReactLoading type="spin" color="#6a4c93" height="3%" width="3%" />
            </div>
        )
    }

    else {
        return (
            <section>
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                        <div className="max-w-3xl mx-auto lg:max-w-none">
                            <article>
                                {/* Article header */}
                                <header className="max-w-3xl mx-auto mb-20">
                                    {/* Title */}
                                    <h1 className="h1 text-center mb-4">
                                        Convert Earnings to Quasi Coins
                                    </h1>
                                </header>
                                <p className="h4 text-center mb-4 pb-8">
                                    Thanks for being a part of this amazing community, here's your reward!
                                </p>
                                <p className="h3 text-center mb-4 pb-8">
                                    Converting
                                    <span className="text-green-mainText"> ${amount} </span> USD to <span className="text-green-mainText">  {amountOfCoins} </span> Quasi Coins
                                </p>
                            </article>

                            <div className="flex justify-center pb-8">
                                {isPayoutLoading ? (
                                    <ReactLoading
                                        type={"spin"}
                                        color={"#8ac926"}
                                        height={"5%"}
                                        width={"5%"}
                                    />
                                ) : (
                                    <button
                                        onClick={handleClick}
                                        className="btn-sm text-green-primaryText bg-green-primaryBox hover:bg-green-hoverBox hover:text-green-hoverText font-bold py-2 px-4 rounded-full"
                                        id='payout'
                                    >
                                        Confirm Conversion
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
});

export default ConvertParital;
