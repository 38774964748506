// This is the pricing screen which will show users our different pricing packages
import React, { useState, useEffect } from 'react';
import Wrapper from "../utils/Wrapper";
import * as FaIcons from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { useHistory } from "react-router";
import { logScreenName, logEvent } from "../utils/CommonFunctions";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";
import { CgCreditCard } from "react-icons/cg";
import { getStripeCustomer, startFreeTrial, cancelSubscription } from "../merlinv1/beta_api";
import ReactLoading from "react-loading";
import firebase from "firebase";

// Creates the component
const ShopPartial = () => {
  // The history hook
  const history = useHistory();
  const [signedIn, setSignedIn] = useState(false);
  const [stripeCustomer, setStripeCustomer] = useState();
  const [stripeID, setStripeID] = useState();
  const [isFreeTrial, setIsFreeTrial] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState("No Plan")

  const handleCancel = async () => {
    try {
      const res = await cancelSubscription();
      history.push('/user/dashboard/')
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleClick = async () => {
    try {
      if (signedIn) {
        if (!isFreeTrial) {
          const res = await startFreeTrial();
          history.push('/user/dashboard/')
        }
      }
      else {
        history.push('/login')
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const res = await getStripeCustomer();
      setStripeCustomer(res.data.stripe_customer)
      setStripeID(res.data.stripe_id)
      setIsFreeTrial(res.data.free_trial)
      console.log(res.data.free_trial)
      setPlan(res.data.plan)
      console.log(plan)
    } catch (err) {

    }
    setIsLoading(false)
    if (firebase.auth().currentUser !== null) {
      setSignedIn(true);
    } else {
      setSignedIn(false);
    }
  }

  useEffect(() => {
    fetchData()
  }, []);

  if (isLoading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <ReactLoading type="spin" color="#6a4c93" height="3%" width="3%" />
      </div>
    );
  }

  else {
    // Returns the UI
    return (
      <div>
        <MetaTags>
          <title>Pricing</title>
          <meta
            name="Shop"
            content="Get more Quasi Coins"
          />
          <meta property="og:title" content="Quasi | Pricing" />
        </MetaTags>
        <div className={"p-6 "}>
          <div className="pt-8" />
          {/* <div
            className="text-left md:text-6xl md:pt-40 sm:text-5xl font-bold leading-tighter tracking-tighter mb-4 pb-5 text-center"
            data-aos="zoom-y-out"
          >
            <span className="text-left bg-clip-text text-transparent bg-blue-pondr">
              {" "} Shop
            </span>
          </div> */}
          <div className="text-left sm:text-5xl font-bold leading-tighter tracking-tighter mb-4 pb-5 text-left">
            Pricing Plans
          </div>
          <div className="flex flex-row flex-wrap gap-5 justify-center">
            <div
              onClick={() => handleClick()}
              className="bg-gray-tertiaryBox hover:bg-gray-200 rounded-3xl p-6 flex flex-col box">
              {((isFreeTrial) && (plan == "Quasi Free Trial")) ? (
                <>
                  <span className="rounded-xl px-3 py-1 bg-green-likeBox active ">
                    Active
                  </span>
                  <button
                    onClick={() => handleCancel()}
                    className="rounded-xl py-1 bg-red-primaryBox cancel justify-self-center hover:bg-red-hoverBox">
                    Cancel Free Trial
                  </button>
                </>
              ) : (null)}
              {/* {(isFreeTrial && (plan !== "Quasi Free Trial")) ? (
                <>
                  <span className="absolute top-0 mt-1/7 justify-self-center">
                    <svg width="256" height="256" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.402353 15.5567C0.890634 16.045 1.71095 16.0254 2.1797 15.5664L8.00001 9.74613L13.8106 15.5664C14.2891 16.045 15.1094 16.045 15.5879 15.5567C16.0664 15.0684 16.0762 14.2578 15.5977 13.7793L9.78712 7.95902L15.5977 2.14847C16.0762 1.66996 16.0762 0.859412 15.5879 0.371131C15.0996 -0.107385 14.2891 -0.11715 13.8106 0.361365L8.00001 6.18168L2.1797 0.361365C1.71095 -0.0976189 0.880869 -0.11715 0.402353 0.371131C-0.0761624 0.859412 -0.0663968 1.67972 0.392588 2.13871L6.2129 7.95902L0.392588 13.7891C-0.0663968 14.2481 -0.0859281 15.0782 0.402353 15.5567Z" fill="#ffa5a5" />
                    </svg>
                  </span>
                </>
              ) : (null)} */}
              <div className={isFreeTrial ? "opacity-30 flex flex-col" : "flex flex-col"}>
                <span className={"border-b border-b-solid border-red-freeTrial mb-4 font-bold text-4xl text-red-freeTrial"}>
                  Free Trial
                </span>
                <span className="font-medium text-2xl">
                  1 Month
                </span>
                <span className="text-lg text-gray-400 pb-8">
                  Get a taste of what Quasi offers.
                </span>
                <span className="flex flex-row text-gray-600 pb-4">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
                  </svg>
                  <span className="pl-2 my-auto">Unlimited Creations</span>
                </span>
                <span className="flex flex-row text-gray-600 pb-4">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
                  </svg>
                  <span className="pl-2 my-auto">Post To The Gallery</span>
                </span>
                <span className="flex flex-row text-gray-600 pb-4">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
                  </svg>
                  <span className="pl-2 my-auto">A Chance To Make Money</span>
                </span>
              </div>
            </div>
            <div
              onClick={() => { signedIn ? history.push({ pathname: '/checkout', state: { stripeCustomer: stripeCustomer, productName: "Unlimited Quasi", price: 5, stripeID: stripeID } }) : history.push('\login') }}
              className={"bg-gray-tertiaryBox rounded-3xl p-6 flex flex-col box hover:bg-gray-200"}>
              {((plan !== "No Plan") && (plan === "Quasi Unlimited")) ? (
                <>
                  <span className="rounded-xl px-3 py-1 bg-green-likeBox active ">
                    Active
                  </span>
                  <button
                    onClick={() => handleCancel()}
                    className="rounded-xl py-1 bg-red-primaryBox cancel justify-self-center hover:bg-red-hoverBox">
                    Cancel Subscription
                  </button>
                </>
              ) : (null)}
              <div className={((plan !== "No Plan") && (plan !== "Quasi Free Trial")) ? ("opacity-40 flex flex-col") : ("flex flex-col")}>
                <span className="border-b border-b-solid border-red-unlimited mb-4 font-bold text-4xl text-red-unlimited">
                  Unlimited
                  {/* {plan === "No Plan" ? (null) : (<span className="justify-self-end rounded-xl bg-green-likeBox">
                  Active
                </span>)} */}
                </span>
                <span className="font-medium text-2xl">
                  $5/Mo
                </span>
                <span className="text-lg text-gray-400 pb-8">
                  Who doesn't like unlimited?
                </span>
                <span className="flex flex-row text-gray-600 pb-4">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
                  </svg>
                  <span className="pl-2 my-auto">Unlimited Text Creations</span>
                </span>
                <span className="flex flex-row text-gray-600 pb-4">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
                  </svg>
                  <span className="pl-2 my-auto">Unlimited Image Creations</span>
                </span>
                <span className="flex flex-row text-gray-600 pb-4">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
                  </svg>
                  <span className="pl-2 my-auto">Post To The Gallery</span>
                </span>
                <span className="flex flex-row text-gray-600 pb-4">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
                  </svg>
                  <span className="pl-2 my-auto">Make Your Own Models</span>
                </span>
                <span className="flex flex-row text-gray-600 pb-4">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
                  </svg>
                  <span className="pl-2 my-auto">Earn Money As A Developer</span>
                </span>
              </div>
            </div>
          </div>
          {/*           
          {isFreeTrial ? (
            <div>
              {plan === "No Plan" ? (
                <div className='pb-8'>
                  <div
                    className="text-left sm:text-3xl font-bold leading-tighter tracking-tighter mb-4 pb-5 text-center"
                    data-aos="zoom-y-out"
                  >
                    Want to go Unlimited?
                  </div>
                  <div className="justify-center sm:mx-6 md:mx-24">
                    <div className="flex flex-wrap -mx-2 overflow-hidden md:-mx-2 xl:-mx-2 px-12 bg-gray-tertiaryBox rounded-xl sm:p-2 md:p-4">
                      <div className="my-2 px-2 w-full overflow-hidden md:my-2 md:px-2 xl:my-2 xl:px-2">
                        <div className="rounded-3xl overflow-hidden shadow-lg bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox"
                          data-aos="fade-up"
                          onClick={() => history.push({ pathname: '/checkout', state: { stripeCustomer: stripeCustomer, productName: "Unlimited Quasi", price: 5, stripeID: stripeID } })}
                        >
                          <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">Unlimited Plan ($5)</div>
                            <p className="text-gray-700 text-base">
                              Unlimited text and image creations, the ability to make your own models and post to the gallery, and the oppurtunity to earn money as a developer
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='pb-8'>
                  <div
                    className="text-left sm:text-3xl font-bold leading-tighter tracking-tighter mb-4 pb-5 text-center"
                    data-aos="zoom-y-out"
                  >
                    Current Plan: {plan}
                  </div>
                  <div className="flex justify-center w-full">
                    <button
                      href="https://openai.com/api/"
                      className="btn-sm font-medium pl-8 pr-8 text-red-primaryText bg-red-primaryBox hover:bg-red-hoverBox hover:text-red-hoverText focus:outline-none rounded-full"
                      onClick={() => (handleCancel())}
                    >
                      Cancel Subscription
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className='pb-8'>
              <div
                className="text-left sm:text-3xl font-bold leading-tighter tracking-tighter mb-4 pb-5 text-center"
                data-aos="zoom-y-out"
              >
                Start Your Free Trial!
              </div>
              <div className="justify-center sm:mx-6 md:mx-24">
                <div className="flex flex-wrap -mx-2 overflow-hidden md:-mx-2 xl:-mx-2 px-12 bg-gray-tertiaryBox rounded-xl sm:p-2 md:p-4">
                  <div className="my-2 px-2 w-full overflow-hidden md:my-2 md:px-2 xl:my-2 xl:px-2">
                    <div className="rounded-3xl overflow-hidden shadow-lg bg-green-primaryBox hover:bg-green-hoverBox"
                      data-aos="fade-up"
                      onClick={() => (handleClick())}
                    >
                      <div className="px-6 py-4">
                        <div className="font-bold text-xl mb-2">Start Free Trial ($0)</div>
                        <p className="text-gray-700 text-base">
                          One month free trial of unlimited Quasi usage!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    );
  }
};

// Exports the component
export default ShopPartial;
