// Editable.js
import React, { useImperativeHandle, useState } from "react";

// Component accept text, placeholder values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
const Editable = React.forwardRef((props, ref) => {
  // Manage the state whether to show the label or the input box. By default, label will be shown.
  // Exercise: It can be made dynamic by accepting initial state as props outside the component
  const [isEditing, setEditing] = useState(false);
  const [editingEnabled, setEditingEnabled] = useState(true);

  // Event handler while pressing any key while editing
  const handleKeyDown = (event, type) => {
    // Handle when key is pressed
  };

  //when props.text is changed, set editingEnabled to TRUE

  useImperativeHandle(ref, () => ({
    //send a function which allows to toggle isEditing state
    toggleEditing: () => {
      setEditing(!isEditing);
    },

    disableEditing: () => {
      setEditing(false);
    },

    enableEditing: () => {
      setEditing(true);
    },

    fullDisableEditing: () => {
      setEditing(false);
      setEditingEnabled(false);
    },

    fullEnableEditing: () => {
      setEditing(true);
      setEditingEnabled(true);
    },

    paritalEnableEditing: () => {
      setEditingEnabled(true);
    },

    partialDisableEditing: () => {
      setEditingEnabled(false);
    },
  }));
  /*
  - It will display a label is `isEditing` is false
  - It will display the children (input or textarea) if `isEditing` is true
  - when input `onBlur`, we will set the default non edit mode
  Note: For simplicity purpose, I removed all the classnames, you can check the repo for CSS styles
  */
  return (
    <div className='' {...props}>
      {isEditing ? (
        <>
          {editingEnabled ? (
            <div
              onBlur={() => setEditing(false)}
              onKeyDown={(e) => handleKeyDown(e, props.type)}>
              {props.children}
            </div>
          ) : (
            <div
              className=''
              onClick={() => {
                if (editingEnabled) {
                  setEditing(true);
                }
              }}>
              <span
                className='break-all'
                id={props.internalID !== null ? props.internalID : ""}>
                {/* {props.text || props.placeholder || "Editable content"} */}
                {props.text !== null &&
                props.text !== "" &&
                props.text != undefined ? (
                  <>
                    {/* split the text by word and put it indivual spans */}
                    {props.text.split(" ").map((word, index) => (
                      <>
                        <span
                          key={index}
                          id={word + "-" + index}
                          style={{ position: "relative" }}>
                          {word}
                        </span>

                        {index < props.text.split(" ").length - 1 ? " " : ""}
                      </>
                    ))}
                  </>
                ) : (
                  props.placeholder || "Editable content"
                )}
              </span>
            </div>
          )}
        </>
      ) : (
        <div
          className=''
          onClick={() => {
            if (editingEnabled) {
              setEditing(true);
            }
          }}>
          <span
            className='break-all'
            id={props.internalID !== null ? props.internalID : ""}>
            {/* {props.text || props.placeholder || "Editable content"} */}
            {props.text !== null &&
            props.text !== "" &&
            props.text != undefined ? (
              <>
                {/* split the text by word and put it indivual spans */}
                {props.text.split(" ").map((word, index) => (
                  <>
                    <span
                      key={index}
                      id={
                        props.prefix +
                        word.replace(/[^a-z0-9]/gi, "") +
                        "-" +
                        index
                      }
                      style={{ position: "relative" }}>
                      {word}
                    </span>

                    {index < props.text.split(" ").length - 1 ? " " : ""}
                  </>
                ))}
              </>
            ) : (
              props.placeholder || "Editable content"
            )}
          </span>
        </div>
      )}
    </div>
  );
});

export default Editable;
