import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { getBountyList } from "../merlinv1/beta_api";
import { useLocation, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { firebaseReducer } from "react-redux-firebase";
import firebase from "firebase";
import ReactiveButton from "reactive-button";
import TextArea from "../pages/components/TextArea";

const WalkthroughPartial = () => {
  const [state, setState] = useState("idle");
  const [input1, setInput1] = useState("");
  const [modelTitle, setModelTitle] = useState("");
  const [output, setOutput] = useState("");
  const [pageTitle, setPageTitle] = useState("Create on Quasi");
  const [pageSubTitle, setPageSubTitle] = useState(
    "You can generate creations simply by putting in what you want. For example, type a poem topic in the model below and click Generate."
  );
  const [isOutputHidden, setIsOutputHidden] = useState(false);
  const [isModelHidden, setIsModelHidden] = useState(true);

  const [userReply, setUserReply] = useState(
    "write a paragraph about the war of 1812"
  );
  const [messages, setMessages] = useState(["What can I help you with?"]);
  const [senders, setSenders] = useState(["BOT"]);
  const [loading, setLoading] = useState(false);
  const [sendText, setSendText] = useState("Send");

  const location = useLocation();
  const history = useHistory();

  const onClickHandler = async () => {
    setState("loading");
    setTimeout(() => {
      setIsOutputHidden(true);
      setState("idle");
    }, 3000);
  };

  const onContinueHandler = async () => {
    setIsOutputHidden(false);
    window.scroll(0, 0);
    if (location.state.input2 != null && input1 != location.state.input2) {
      setInput1(location.state.input2);
      setModelTitle(location.state.modelName2);
      setOutput(location.state.output2);
      setPageTitle("Try An Image Model Too");
      setPageSubTitle(
        "It’s the exact same process for generating an image. Feel free to type an image idea in the model below and click Generate."
      );
    } else {
      if (modelTitle == "school") {
        setModelTitle("School");
      }
      setIsModelHidden(false);
      setPageTitle("You’re Ready To Get Started!");
      setPageSubTitle(
        "Now that you understand the basics of using an AI model to create, you can use Quasi to fit your needs, whatever they may be!"
      );
    }
  };

  useEffect(() => {
    setInput1(location.state.input1);
    setModelTitle(location.state.modelName);
    setOutput(location.state.output);
    window.scroll(0, 0);
  }, []);

  return (
    <section>
      <MetaTags>
        <title>Walkthrough</title>
        <meta name='Welcome' content='Quasi Walkthrough' />
      </MetaTags>
      <div className='max-w-full mx-auto'>
        <div className='w-full lg:max-w-none'>
          {modelTitle !== "school" ? (
            <div className='sm:p-2 md:p-16 w-full'>
              <div
                className='text-left md:text-5xl md:pt-20 sm:text-5xl font-bold leading-tighter tracking-tighter mb-4 pb-2'
                data-aos='zoom-y-out'>
                <span className='text-left text-blue-pondr'>{pageTitle}</span>
              </div>

              <div
                className='text-left text-black sm:text-xl md:text-3xl font-semibold leading-tighter tracking-tighter mb-2 pb-5'
                data-aos='zoom-y-out'>
                {pageSubTitle}
              </div>
              {isModelHidden ? (
                <div className='box xl:col-start-2 xl:col-span-2 bg-gray-tertiaryBox rounded-xl'>
                  <div className='md:p-4 sm:p-2'>
                    <div className='pb-2'>
                      <div className=''>
                        <div className='flex flex-row flex-wrap justify-between items-center -mx-1 sm:-mx-1 md:-mx-1 lg:-mx-1 xl:-mx-1'>
                          <div className='md:text-3xl sm:text-lg font-bold my-1 px-1 w-full sm:my-1 sm:px-1 md:my-1 md:px-1 md:w-full '>
                            {modelTitle}
                          </div>
                          <div className='my-1 px-1 sm:my-1 sm:px-1 md:my-1 md:px-1'>
                            <div className='ml-1' />
                            <ReactiveButton
                              buttonState={state}
                              onClick={onClickHandler}
                              color={"primary green"}
                              idleText={"Generate >>"}
                              loadingText={"Loading"}
                              successText={"Success"}
                              errorText={"Error"}
                              type={"button"}
                              className={""}
                              style={{ borderRadius: "0.875rem" }}
                              outline={false}
                              shadow={false}
                              rounded={false}
                              size={"normal"}
                              block={false}
                              messageDuration={1000}
                              buttonRef={null}
                              width={250}
                              height={null}
                              animation={false}
                            />
                          </div>
                        </div>
                        <TextArea
                          className='w-full p-3 text-gray-100 bg-gray-secondaryBox rounded-lg whitespace-pre-line'
                          name='question'
                          type='search'
                          required
                          id='input1'
                          value={input1}
                          // onChangeText={(newText) => {
                          //     setInput1(newText)
                          // }}
                          autoComplete='off'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <button
                  className='font-bold text-purple-primaryText bg-purple-primaryBox hover:bg-purple-hoverBox hover:text-purple-hoverText rounded-xl px-3 py-1 mx-6 my-4'
                  onClick={() => {
                    if (modelTitle === "School") {
                      history.push({ pathname: "/school" });
                    } else {
                      history.push({ pathname: "/create" });
                    }
                  }}>
                  <span className='flex justify-center'>
                    <div className='flex flex-col justify-center'>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M15.5 11.5723L15.4902 1.77734C15.4902 1.01562 14.9824 0.498047 14.2109 0.498047H4.40625C3.68359 0.498047 3.15625 1.04492 3.15625 1.69922C3.15625 2.34375 3.71289 2.87109 4.38672 2.87109H7.93164L11.7109 2.73438L9.96289 4.28711L0.949219 13.3105C0.695312 13.5645 0.548828 13.8867 0.548828 14.1992C0.548828 14.834 1.13477 15.4395 1.78906 15.4395C2.11133 15.4395 2.41406 15.3027 2.67773 15.0391L11.7012 6.02539L13.2637 4.27734L13.1074 7.91016V11.5918C13.1074 12.2754 13.6348 12.832 14.2891 12.832C14.9434 12.832 15.5 12.2852 15.5 11.5723Z'
                          fill='#2C0147'
                        />
                      </svg>
                    </div>
                    <span className='pl-2'>
                      {modelTitle === "School"
                        ? "Explore School"
                        : "Explore Models"}
                    </span>
                  </span>
                </button>
              )}
              <div className='pt-8' />
              {isOutputHidden ? (
                <div>
                  <div
                    className='bg-gray-tertiaryBox rounded-xl mb-8 p-4'
                    data-aos='fade-down'>
                    {output.includes("quasi_art") ? (
                      <div className='flex justify-center'>
                        <div className='md:p-4 sm:p-2'>
                          <img src={output} className='rounded-xl' />
                        </div>
                      </div>
                    ) : (
                      <div className='bg-gray-secondaryBox rounded-lg px-4 py-3 whitespace-pre-line'>
                        <div className='text-xl font-semibold whitespace-pre-line'>
                          {output}
                        </div>
                      </div>
                    )}
                    <div className='flex justify-end'>
                      <button
                        className=' font-bold text-purple-primaryText bg-purple-primaryBox hover:bg-purple-hoverBox hover:text-purple-hoverText rounded-xl px-3 py-1 mx-6 my-4'
                        onClick={() => {
                          onContinueHandler();
                        }}>
                        <span className='flex justify-center'>
                          <span className='pl-2'>Continue {">>"}</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div className='sm:p-2 md:p-16 w-full'>
              <div
                className='text-left md:text-5xl md:pt-20 sm:text-5xl font-bold leading-tighter tracking-tighter mb-4 pb-2'
                data-aos='zoom-y-out'>
                <span className='text-left text-blue-pondr'>{pageTitle}</span>
              </div>

              <div
                className='text-left text-black sm:text-xl md:text-3xl font-semibold leading-tighter tracking-tighter mb-2 pb-5'
                data-aos='zoom-y-out'>
                You can get help with school simply by putting in what you want.{" "}
                <br></br>For example, ask Quasi to do something and click Send.
              </div>
              <div
                className={
                  true ? "h-full flex flex-col p-2" : "h-75vh flex flex-col p-2"
                }>
                {messages.map((message, index) => (
                  <div
                    className={
                      senders[index] == "USER"
                        ? "flex justify-end my-1"
                        : "flex justify-start my-1"
                    }>
                    <div className='flex flex-col' data-aos='fade-up'>
                      {senders[index] == "USER" ? null : (
                        <span className='text-xs pl-2'>Quasi School</span>
                      )}
                      <div
                        className={
                          senders[index] == "USER"
                            ? "p-2 rounded-xl bg-yellow-school"
                            : "p-2 rounded-xl bg-gray-secondaryBox"
                        }>
                        {message}
                      </div>
                    </div>
                  </div>
                ))}
                {loading ? (
                  <div className='flex justify-start my-1'>
                    <div className='p-2 rounded-xl bg-gray-secondaryBox'>
                      ...
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='rounded-b-3xl bg-gray-tertiaryBox flex flex-col sm:p-3 md:p-6'>
                <input
                  className='rounded-2xl bg-gray-secondaryBox w-full px-4 py-2'
                  onChange={(e) => {
                    setUserReply(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                    }
                  }}
                  value={userReply}
                  placeholder={"Type your response here!"}
                  id={"inputBar"}
                />
                <div className='flex flex-row justify-end gap-2 pt-4'>
                  <button
                    onClick={() => {}}
                    className='px-2 py-1 bg-gray-secondaryBox rounded-xl flex flex-row items-center'>
                    <svg
                      width='18'
                      height='17.2173913'
                      viewBox='0 0 23 22'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M4.29688 16.877H5.9375V18.332C5.9375 20.4805 7.08984 21.6133 9.24805 21.6133H19.1992C21.3574 21.6133 22.5098 20.4707 22.5098 18.332V8.32227C22.5098 6.18359 21.3574 5.04102 19.1992 5.04102H17.5586V3.58594C17.5586 1.44727 16.4062 0.314453 14.248 0.314453H4.29688C2.12891 0.314453 0.986328 1.44727 0.986328 3.58594V13.5957C0.986328 15.7441 2.12891 16.877 4.29688 16.877ZM4.48242 14.709C3.62305 14.709 3.16406 14.2695 3.16406 13.3711V3.82031C3.16406 2.92188 3.62305 2.48242 4.48242 2.48242H14.0625C14.9121 2.48242 15.3809 2.92188 15.3809 3.82031V5.04102H9.24805C7.08984 5.04102 5.9375 6.18359 5.9375 8.32227V14.709H4.48242ZM9.43359 19.4355C8.57422 19.4355 8.11523 19.0059 8.11523 18.1074V8.54688C8.11523 7.64844 8.57422 7.21875 9.43359 7.21875H19.0137C19.8633 7.21875 20.332 7.64844 20.332 8.54688V18.1074C20.332 19.0059 19.8633 19.4355 19.0137 19.4355H9.43359ZM9.72656 13.332C9.72656 13.8984 10.1367 14.3086 10.7129 14.3086H13.2422V16.8477C13.2422 17.4141 13.6523 17.8242 14.2188 17.8242C14.8047 17.8242 15.2148 17.4141 15.2148 16.8477V14.3086H17.7539C18.3301 14.3086 18.7305 13.8984 18.7305 13.332C18.7305 12.7461 18.3301 12.3359 17.7539 12.3359H15.2148V9.80664C15.2148 9.2207 14.8047 8.82031 14.2188 8.82031C13.6523 8.82031 13.2422 9.23047 13.2422 9.80664V12.3359H10.7129C10.1367 12.3359 9.72656 12.7461 9.72656 13.332Z'
                        fill='#333333'
                      />
                    </svg>
                    <span className='pl-2'>{false ? "Copied!" : "Copy"}</span>
                  </button>
                  {sendText === "Send" ? (
                    <button
                      onClick={() => {
                        setSenders(["BOT", "USER"]);
                        setMessages([
                          "What can I help you with?",
                          "write a paragraph about the war of 1812",
                        ]);
                        setLoading(true);
                        setTimeout(() => {
                          setSenders(["BOT", "USER", "BOT"]);
                          setMessages([
                            "What can I help you with?",
                            "write a paragraph about the war of 1812",
                            'the war of 1812 was a military conflict between the united states and great britain. it is also known as the second war of independence, or simply, "the war of 1812". this conflict took place in north america from june 1812 to february 1815. it began when the united states declared war on great britain due to trade restrictions caused by british interference with american merchant ships and impressment of sailors into its navy. at first, canada (then part of great britain) did not fight against us but instead remained neutral until early september 1813 when they joined forces with their mother country. after two years both sides agreed to an armistice which restored pre-war boundaries without resolving any territorial issues.',
                          ]);
                          setLoading(false);
                          setSendText("Continue");
                        }, 3000);
                      }}
                      className='px-2 py-1 bg-yellow-school rounded-xl flex flex-row items-center'>
                      <svg
                        width='17.2173913'
                        height='16.4347826'
                        viewBox='0 0 22 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M13.082 20.9355C13.873 20.9355 14.4102 20.3203 14.7812 19.373L21.2559 2.43945C21.4219 2.00977 21.5098 1.62891 21.5098 1.30664C21.5098 0.564453 21.041 0.0957031 20.2988 0.0957031C19.9766 0.0957031 19.5957 0.193359 19.166 0.349609L2.16406 6.86328C1.30469 7.18555 0.669922 7.73242 0.669922 8.52344C0.669922 9.48047 1.37305 9.87109 2.34961 10.1543L9.39062 12.2148L11.4316 19.1875C11.7246 20.2031 12.1152 20.9355 13.082 20.9355ZM10.0352 10.2324L4.36133 8.50391C4.23438 8.45508 4.19531 8.41602 4.19531 8.35742C4.19531 8.28906 4.22461 8.24023 4.3418 8.20117L14.9668 4.11914C15.8359 3.77734 16.6855 3.36719 17.6719 2.91797C16.9297 3.52344 16.0312 4.24609 15.4062 4.87109L10.0352 10.2324ZM13.2578 17.4297C13.1895 17.4297 13.1504 17.3711 13.1113 17.2441L11.373 11.5801L16.7344 6.19922C17.3398 5.59375 18.1113 4.65625 18.6973 3.91406C18.248 4.90039 17.8281 5.75977 17.4863 6.63867L13.4141 17.2637C13.3652 17.3809 13.3262 17.4297 13.2578 17.4297Z'
                          fill='#725300'
                        />
                      </svg>
                      <span className='pl-2 text-yellow-schoolText'>
                        {sendText}
                      </span>
                    </button>
                  ) : (
                    <button
                      className='font-bold text-purple-primaryText bg-purple-primaryBox hover:bg-purple-hoverBox hover:text-purple-hoverText rounded-xl px-3 py-1'
                      onClick={() => {
                        onContinueHandler();
                      }}>
                      <span className='flex justify-center'>
                        <span className='pl-2'>Continue {">>"}</span>
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default WalkthroughPartial;
