import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import Input from "../pages/components/Input";
import Button from '../pages/components/Button';
import { searchCreationList } from '../merlinv1/beta_api';
import { publishOutput } from '../merlinv1/beta_api';
import { privateOutput } from '../merlinv1/beta_api';
import { useHistory } from "react-router";
import Card from '../pages/components/Card';
import { Link } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';
import { RiEditFill } from 'react-icons/ri';
import { AiFillGift } from 'react-icons/ai';
import { IoCube } from 'react-icons/io5';
import { MdSpaceDashboard } from 'react-icons/md'
import { getCreationList } from '../merlinv1/beta_api';
import NonFormInput from "../pages/components/NonFormInput"
import { FaPaperPlane } from 'react-icons/fa'
import { FaTrash } from 'react-icons/fa';


import InfiniteScroll from 'react-infinite-scroller';

const CreateList = React.forwardRef((props, ref) => {

    const [isLoading, setIsLoading] = useState(true);
    const [creations, setCreations] = useState([]);
    const [filter, setFilter] = useState("Input1, Input2, Input3");
    const [pageNum, setPageNum] = useState(25);
    const [searchText, setSearchText] = useState("");
    const [hasMore, setHasMore] = useState(true);
    const [galleryList, setGalleryList] = useState([]);
    const [indexMap, setIndexMap] = useState({});
    const history = useHistory();

    const publishToGallery = async (outputID, index) => {
        await publishOutput(outputID)

        let newGalleryList = galleryList.slice();
        newGalleryList[index] = true;
        setGalleryList(newGalleryList);

    }

    const removeFromGallery = async (outputID, index) => {
        await privateOutput(outputID)

        let newGalleryList = galleryList.slice();
        newGalleryList[index] = false;
        setGalleryList(newGalleryList);

    }

    const loadMore = async () => {
        const res = await searchCreationList(searchText, filter, pageNum + 25)
        setCreations(res.data['outputs'])

        var tempGalleryList = []
        var tempIndexMap = {}
        for (let i = 0; i < res.data['outputs'].length; i++) {
            tempGalleryList.push(res.data['outputs'][i].Gallery ? true : false)
            tempIndexMap[res.data['outputs'][i].id] = i
        }

        if (res.data['outputs'].length >= res.data['found']) {
            setHasMore(false)
        }
        setGalleryList(tempGalleryList)
        setIndexMap(tempIndexMap)



        setPageNum(pageNum + 25)
    }

    const handler = async (searchText) => {
        // changing the state to the name of the key
        // which is pressed
        setSearchText(searchText)
        try {
            const res = await searchCreationList(searchText, filter, 25)
            setCreations(res.data['outputs'])
            var tempGalleryList = []
            var tempIndexMap = {}
            for (let i = 0; i < res.data['outputs'].length; i++) {
                tempGalleryList.push(res.data['outputs'][i].Gallery ? true : false)
                tempIndexMap[res.data['outputs'][i].id] = i

            }

            if (res.data['outputs'].length >= res.data['found']) {
                setHasMore(false)
            }
            setGalleryList(tempGalleryList)
            setIndexMap(tempIndexMap)

        } catch (err) {
            history.push("/ErrorPage");
        }
    };

    const fetchData = async () => {
        try {
            const res = await getCreationList(25);
            setCreations(res.data['outputs'])
            var tempGalleryList = [];
            var tempMap = {};
            for (let i = 0; i < res.data['outputs'].length; i++) {
                tempGalleryList.push(res.data['outputs'][i].Gallery ? true : false)
                tempMap[res.data['outputs'][i].id] = i;
            }

            if (res.data['outputs'].length >= res.data['found']) {
                setHasMore(false)
            }
            setGalleryList(tempGalleryList)
            setIndexMap(tempMap);


        } catch (err) {
            history.push("/ErrorPage");
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, []);

    if (isLoading) {
        return (
            <div>
                <MetaTags>
                    <title>Dashboard</title>
                    <meta
                        name="Dashboard"
                        content="User Dashboard"
                    />
                    <meta property="og:title" content="Quasi | Dashboard" />
                </MetaTags>
                <div
                    className="text-left sm:text-5xl font-bold leading-tighter tracking-tighter mb-4 text-center pt-16"
                    data-aos="zoom-y-out"
                >
                    My Creations
                </div>
                <div className={""}>
                    <div className="w-full flex flex-wrap justify-items-center pt-5">
                        <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
                            <Link
                                to="/user/dashboard/"
                                className="btn-sm font-bold pl-8 pr-8 text-gray-secondaryText bg-gray-secondaryBox hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox ml-3 focus:outline-none rounded-full"
                            >
                                <MdSpaceDashboard />
                                <span className='pl-2'>Dashboard</span>
                                <path
                                    d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                    fillRule="nonzero"
                                />
                            </Link>
                        </div>
                        <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
                            <Link
                                to="/user/my-models"
                                className="btn-sm font-bold pl-8 pr-8 text-gray-secondaryText bg-gray-secondaryBox hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox ml-3 focus:outline-none rounded-full"
                            >
                                <IoCube />
                                <span className='pl-2'>My Models</span>
                                <path
                                    d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                    fillRule="nonzero"
                                />
                            </Link>
                        </div>
                        <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
                            <Link
                                to="/user/my-creations"
                                className="btn-sm font-bold pl-8 pr-8 text-blue-primaryText bg-blue-primaryBox hover:bg-blue-hoverBox hover:text-blue-hoverText ml-3 focus:outline-none rounded-full"
                            >
                                <AiFillGift />
                                <span className='sm:pl-1 md:pl-2 xl:pl-2'>My Creations</span>
                                <path
                                    d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                    fillRule="nonzero"
                                />
                            </Link>
                        </div>
                        <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
                            <Link
                                to="/user/create-product"
                                className="btn-sm font-bold pl-8 pr-8 text-gray-secondaryText bg-gray-secondaryBox hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox ml-3 focus:outline-none rounded-full"
                            >
                                <RiEditFill />
                                <span className='pl-2'>Make Model</span>
                                <path
                                    d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                    fillRule="nonzero"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="w-full h-screen flex justify-center items-center">
                    <ReactLoading type="spin" color="#6a4c93" height="3%" width="3%" />
                </div>
            </div>
        )
    }

    else {
        return (
            <section>
                <MetaTags>
                    <title>Dashboard</title>
                    <meta
                        name="Dashboard"
                        content="User Dashboard"
                    />
                    <meta property="og:title" content="Quasi | Dashboard" />
                </MetaTags>
                <div
                    className="text-left sm:text-5xl font-bold leading-tighter tracking-tighter mb-4 pb-5 text-center pt-16"
                >
                    My Creations
                </div>

                <div className={"pb-8"}>
                    <div className="w-full flex flex-wrap justify-items-center">
                        <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
                            <Link
                                to="/user/dashboard/"
                                className="btn-sm font-bold pl-8 pr-8 text-gray-secondaryText bg-gray-secondaryBox hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox ml-3 focus:outline-none rounded-full"
                            >
                                <MdSpaceDashboard />
                                <span className='pl-2'>Dashboard</span>
                                <path
                                    d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                    fillRule="nonzero"
                                />
                            </Link>
                        </div>
                        <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
                            <Link
                                to="/user/my-models"
                                className="btn-sm font-bold pl-8 pr-8 text-gray-secondaryText bg-gray-secondaryBox hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox ml-3 focus:outline-none rounded-full"
                            >
                                <IoCube />
                                <span className='pl-2'>My Models</span>
                                <path
                                    d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                    fillRule="nonzero"
                                />
                            </Link>
                        </div>
                        <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
                            <Link
                                to="/user/my-creations"
                                className="btn-sm font-bold pl-8 pr-8 text-blue-primaryText bg-blue-primaryBox hover:bg-blue-hoverBox hover:text-blue-hoverText ml-3 focus:outline-none rounded-full"
                            >
                                <AiFillGift />
                                <span className='sm:pl-1 md:pl-2 xl:pl-2'>My Creations</span>
                                <path
                                    d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                    fillRule="nonzero"
                                />
                            </Link>
                        </div>
                        <div className='w-full sm:my-2 sm:px-2 sm:w-full md:my-2 md:px-2 md:w-1/2 xl:my-2 xl:px-2 xl:w-1/4 text-center'>
                            <Link
                                to="/user/create-product"
                                className="btn-sm font-bold pl-8 pr-8 text-gray-secondaryText bg-gray-secondaryBox hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox ml-3 focus:outline-none rounded-full"
                            >
                                <RiEditFill />
                                <span className='pl-2'>Make Model</span>
                                <path
                                    d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                    fillRule="nonzero"
                                />
                            </Link>
                        </div>
                    </div>
                </div>

                <div
                    className='sm:px-1 md:px-1 lg:px-24 sm:pb-10 md:pb-10 lg:pb-10'
                >
                    <div className="relative text-gray-600 focus-within:text-gray-400">
                        <span className="absolute md:inset-y-0 sm:top-0 md:pt-1 sm:pt-3 left-0 flex md:justify-center sm:justify-end pl-6">
                            <button type="submit" className="focus:outline-none focus:shadow-outline">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" className="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </button>
                        </span>
                        <NonFormInput
                            className='px-16 py-3 w-full text-gray-800 bg-gray-secondaryBox rounded-3xl border-opacity-0 focus:border-opacity-0'
                            placeholder='Search My Creations'
                            type="text"
                            onChangeText={(newText) => handler(newText)}
                        />
                        <span className="flex md:absolute md:justify-end md:inset-y-0 md:right-0 md:pr-6 md:pt-0 md:w-1/6 sm:pt-4">
                            <select
                                value={filter}
                                onChange={e => { setFilter(e.target.value) }}
                                name="filter"
                                className="focus:outline-none bg-gray-secondaryBox w-full sm:rounded-3xl sm:py-3 md:px-0 sm:px-6"
                            >
                                <option value="" disabled defaultValue>
                                    Search by...
                                </option>
                                <option value='Input1, Input2, Input3'>In Input</option>
                                <option value='Output'>In Output</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div className="bg-gray-tertiaryBox rounded-xl">
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={loadMore}
                        hasMore={hasMore}
                        loader={<div className="w-full  flex justify-center items-center">
                            <ReactLoading type="spin" color="#6a4c93" height="3%" width="3%" />
                        </div>}
                        treshold={0}
                    >
                        <div className="p-6">
                            {creations.length > 0 ? (
                                <div>
                                    {creations.map((creation, index) => (
                                        <div className="pb-2">
                                            {creation['Likes'] > 0 ? (
                                                <div className="bg-green-primaryBox rounded-xl p-1">
                                                    <div className="my-1 px-1 w-full overflow-hidden self-center">
                                                        <div className="w-full flex justify-end">


                                                            <div className='pr-2' />
                                                            {galleryList[index] === false ? (
                                                                <button
                                                                    className="opacity-0 group-hover:opacity-100 btn-sm text-purple-mainText bg-purple-primaryBox hover:bg-purple-hoverBox hover:text-purple-hoverText font-bold rounded-full px-3"
                                                                    type="copy"
                                                                    onClick={() => { publishToGallery(creation.id, index) }}
                                                                >
                                                                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12.9062 20.9355C13.6973 20.9355 14.2344 20.3203 14.6055 19.373L21.0801 2.43945C21.2461 2.00977 21.334 1.62891 21.334 1.30664C21.334 0.564453 20.8652 0.0957031 20.123 0.0957031C19.8008 0.0957031 19.4199 0.193359 18.9902 0.349609L1.98828 6.86328C1.12891 7.18555 0.494141 7.73242 0.494141 8.52344C0.494141 9.48047 1.19727 9.87109 2.17383 10.1543L9.21484 12.2148L11.2559 19.1875C11.5488 20.2031 11.9395 20.9355 12.9062 20.9355ZM9.85938 10.2324L4.18555 8.50391C4.05859 8.45508 4.01953 8.41602 4.01953 8.35742C4.01953 8.28906 4.04883 8.24023 4.16602 8.20117L14.791 4.11914C15.6602 3.77734 16.5098 3.36719 17.4961 2.91797C16.7539 3.52344 15.8555 4.24609 15.2305 4.87109L9.85938 10.2324ZM13.082 17.4297C13.0137 17.4297 12.9746 17.3711 12.9355 17.2441L11.1973 11.5801L16.5586 6.19922C17.1641 5.59375 17.9355 4.65625 18.5215 3.91406C18.0723 4.90039 17.6523 5.75977 17.3105 6.63867L13.2383 17.2637C13.1895 17.3809 13.1504 17.4297 13.082 17.4297Z" fill="#2C0147" />
                                                                    </svg>

                                                                    <span className='pl-2' >Publish to Gallery</span>
                                                                </button>
                                                            ) : (<button
                                                                className="opacity-0 group-hover:opacity-100 btn-sm text-red-mainText bg-red-primaryBox hover:bg-red-hoverBox hover:text-red-hoverText font-bold rounded-full px-3"
                                                                type="copy"
                                                                onClick={() => { removeFromGallery(creation.id, index) }}
                                                            >
                                                                <FaTrash />
                                                                <span className='pl-2' >Remove From Gallery</span>
                                                            </button>)}
                                                        </div>
                                                    </div>
                                                    {creation['Input1'] !== "" ? (
                                                        <div className="font-bold text-xl text-green-primaryText mb-2 px-3">
                                                            <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                                {" "} Input: {" "}
                                                            </span>
                                                            {creation['Input1']}
                                                        </div>
                                                    ) : (null)}
                                                    {creation['Input2'] !== "" ? (
                                                        <div className="font-bold text-xl text-green-primaryText mb-2 px-3">
                                                            <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                                {" "} Input 2: {" "}
                                                            </span>
                                                            {creation['Input2']}
                                                        </div>
                                                    ) : (null)}
                                                    {creation['Input3'] !== "" ? (
                                                        <div className="font-bold text-xl text-green-primaryText mb-2 px-3">
                                                            <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                                {" "} Input 3: {" "}
                                                            </span>
                                                            {creation['Input3']}
                                                        </div>
                                                    ) : (null)}
                                                    <div className="font-bold text-xl text-green-primaryText mb-2 px-3 whitespace-pre-line">
                                                        <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                            {" "} Output: {" "}
                                                        </span>
                                                        {!creation['Output'].includes("quasi_art") ? (
                                                            <div className="">
                                                                {creation['Output'].trim()}
                                                            </div>
                                                        ) : (
                                                            <div className='px-12'>
                                                                <div className='flex justify-center items-center'>
                                                                    <img src={creation['Output'].trim()}></img>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : creation['Likes'] < 0 ? (
                                                <div className="bg-red-primaryBox rounded-xl p-1">
                                                    <div className="my-1 px-1 w-full overflow-hidden self-center">
                                                        <div className="w-full flex justify-end">


                                                            <div className='pr-2' />
                                                            {galleryList[indexMap[creation.id]] === false ? (
                                                                <button
                                                                    className="opacity-0 group-hover:opacity-100 btn-sm text-purple-mainText bg-purple-primaryBox hover:bg-purple-hoverBox hover:text-purple-hoverText font-bold rounded-full px-3"
                                                                    type="copy"
                                                                    onClick={() => { publishToGallery(creation.id, indexMap[creation.id]) }}
                                                                >
                                                                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12.9062 20.9355C13.6973 20.9355 14.2344 20.3203 14.6055 19.373L21.0801 2.43945C21.2461 2.00977 21.334 1.62891 21.334 1.30664C21.334 0.564453 20.8652 0.0957031 20.123 0.0957031C19.8008 0.0957031 19.4199 0.193359 18.9902 0.349609L1.98828 6.86328C1.12891 7.18555 0.494141 7.73242 0.494141 8.52344C0.494141 9.48047 1.19727 9.87109 2.17383 10.1543L9.21484 12.2148L11.2559 19.1875C11.5488 20.2031 11.9395 20.9355 12.9062 20.9355ZM9.85938 10.2324L4.18555 8.50391C4.05859 8.45508 4.01953 8.41602 4.01953 8.35742C4.01953 8.28906 4.04883 8.24023 4.16602 8.20117L14.791 4.11914C15.6602 3.77734 16.5098 3.36719 17.4961 2.91797C16.7539 3.52344 15.8555 4.24609 15.2305 4.87109L9.85938 10.2324ZM13.082 17.4297C13.0137 17.4297 12.9746 17.3711 12.9355 17.2441L11.1973 11.5801L16.5586 6.19922C17.1641 5.59375 17.9355 4.65625 18.5215 3.91406C18.0723 4.90039 17.6523 5.75977 17.3105 6.63867L13.2383 17.2637C13.1895 17.3809 13.1504 17.4297 13.082 17.4297Z" fill="#2C0147" />
                                                                    </svg>

                                                                    <span className='pl-2' >Publish to Gallery</span>
                                                                </button>
                                                            ) : (<button
                                                                className="opacity-0 group-hover:opacity-100 btn-sm text-red-mainText bg-red-primaryBox hover:bg-red-hoverBox hover:text-red-hoverText font-bold rounded-full px-3"
                                                                type="copy"
                                                                onClick={() => { removeFromGallery(creation.id, indexMap[creation.id]) }}
                                                            >
                                                                <FaTrash />
                                                                <span className='pl-2' >Remove From Gallery</span>
                                                            </button>)}
                                                        </div>
                                                    </div>
                                                    {creation['Input1'] !== "" ? (
                                                        <div className="font-bold text-xl mb-2 px-3">
                                                            <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                                {" "} Input: {" "}
                                                            </span>
                                                            {creation['Input1']}


                                                        </div>
                                                    ) : (null)}
                                                    {creation['Input2'] !== "" ? (
                                                        <div className="font-bold text-xl text-red-primaryText mb-2 px-3">
                                                            <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                                {" "} Input 2: {" "}
                                                            </span>
                                                            {creation['Input2']}
                                                        </div>
                                                    ) : (null)}
                                                    {creation['Input3'] !== "" ? (
                                                        <div className="font-bold text-xl text-red-primaryText mb-2 px-3">
                                                            <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                                {" "} Input 3: {" "}
                                                            </span>
                                                            {creation['Input3']}
                                                        </div>
                                                    ) : (null)}
                                                    <div className="font-bold text-xl text-red-primaryText mb-2 px-3">
                                                        <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                            {" "} Output: {" "}
                                                        </span>
                                                        {!creation['Output'].includes("quasi_art") ? (
                                                            <div className="">
                                                                {creation['Output'].trim()}
                                                            </div>
                                                        ) : (
                                                            <div className='px-12'>
                                                                <div className='flex justify-center items-center'>
                                                                    <img src={creation['Output'].trim()}></img>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="bg-gray-white rounded-xl p-1">
                                                    <div className="my-1 px-1 w-full overflow-hidden self-center">
                                                        <div className="w-full flex justify-end">


                                                            <div className='pr-2' />
                                                            {galleryList[indexMap[creation.id]] === false ? (
                                                                <button
                                                                    className="opacity-0 group-hover:opacity-100 btn-sm text-purple-mainText bg-purple-primaryBox hover:bg-purple-hoverBox hover:text-purple-hoverText font-bold rounded-full px-3"
                                                                    type="copy"
                                                                    onClick={() => { publishToGallery(creation.id, indexMap[creation.id]) }}
                                                                >
                                                                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12.9062 20.9355C13.6973 20.9355 14.2344 20.3203 14.6055 19.373L21.0801 2.43945C21.2461 2.00977 21.334 1.62891 21.334 1.30664C21.334 0.564453 20.8652 0.0957031 20.123 0.0957031C19.8008 0.0957031 19.4199 0.193359 18.9902 0.349609L1.98828 6.86328C1.12891 7.18555 0.494141 7.73242 0.494141 8.52344C0.494141 9.48047 1.19727 9.87109 2.17383 10.1543L9.21484 12.2148L11.2559 19.1875C11.5488 20.2031 11.9395 20.9355 12.9062 20.9355ZM9.85938 10.2324L4.18555 8.50391C4.05859 8.45508 4.01953 8.41602 4.01953 8.35742C4.01953 8.28906 4.04883 8.24023 4.16602 8.20117L14.791 4.11914C15.6602 3.77734 16.5098 3.36719 17.4961 2.91797C16.7539 3.52344 15.8555 4.24609 15.2305 4.87109L9.85938 10.2324ZM13.082 17.4297C13.0137 17.4297 12.9746 17.3711 12.9355 17.2441L11.1973 11.5801L16.5586 6.19922C17.1641 5.59375 17.9355 4.65625 18.5215 3.91406C18.0723 4.90039 17.6523 5.75977 17.3105 6.63867L13.2383 17.2637C13.1895 17.3809 13.1504 17.4297 13.082 17.4297Z" fill="#2C0147" />
                                                                    </svg>

                                                                    <span className='pl-2' >Publish to Gallery</span>
                                                                </button>
                                                            ) : (<button
                                                                className="opacity-0 group-hover:opacity-100 btn-sm text-red-mainText bg-red-primaryBox hover:bg-red-hoverBox hover:text-red-hoverText font-bold rounded-full px-3"
                                                                type="copy"
                                                                onClick={() => { removeFromGallery(creation.id, indexMap[creation.id]) }}
                                                            >
                                                                <FaTrash />
                                                                <span className='pl-2' >Remove From Gallery</span>
                                                            </button>)}
                                                        </div>
                                                    </div>
                                                    {creation['Input1'] !== "" ? (
                                                        <div className="font-bold text-xl mb-2 px-3">
                                                            <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                                {" "} Input: {" "}
                                                            </span>
                                                            {creation['Input1']}

                                                        </div>
                                                    ) : (null)}
                                                    {creation['Input2'] !== "" ? (
                                                        <div className="font-bold text-xl mb-2 px-3">
                                                            <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                                {" "} Input 2: {" "}
                                                            </span>
                                                            {creation['Input2']}
                                                        </div>
                                                    ) : (null)}
                                                    {creation['Input3'] !== "" ? (
                                                        <div className="font-bold text-xl mb-2 px-3">
                                                            <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                                {" "} Input 3: {" "}
                                                            </span>
                                                            {creation['Input3']}
                                                        </div>
                                                    ) : (null)}
                                                    <div className="font-bold text-xl mb-2 px-3">
                                                        <span className="text-left bg-clip-text text-transparent bg-blue-user">
                                                            {" "} Output: {" "}
                                                        </span>
                                                        {!creation['Output'].includes("quasi_art") ? (
                                                            <div className="">
                                                                {creation['Output'].trim()}
                                                            </div>
                                                        ) : (
                                                            <div className='px-12'>
                                                                <div className='flex justify-center items-center'>
                                                                    <img src={creation['Output'].trim()}></img>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : <div className='font-bold text-xl mb-2'>
                                No creations made yet...
                            </div>
                            }
                        </div >
                    </InfiniteScroll>
                </div >
                {
                    creations.length > 0 ? (
                        <div className="w-full flex justify-start items-center py-3">
                            {isLoading ? (
                                <ReactLoading
                                    type={"spin"}
                                    color={"#AEE08F"}
                                    height={"5%"}
                                    width={"5%"}
                                />
                            ) : (
                                <Button
                                    className="w-1/3 text-green-primaryText bg-green-primaryBox hover:bg-green-hoverBox hover:text-green-hoverText font-bold py-2 px-4 rounded-full"
                                    type="submit"
                                    id="submit"
                                    onClick={() => { loadMore() }}
                                >
                                    Load More...
                                </Button>
                            )}
                        </div>
                    ) : (null)
                }
            </section >
        );
    }
});

//bg-gradient-to-r from-green-500 to-yellow-400

export default CreateList;
