import React, { useState, useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  signInWithGoogle,
  signInWithMicrosoft,
} from "../store/actions/authActions";
import { Link, withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { signIn } from "../store/actions/authActions";
import Wrapper from "../utils/Wrapper";
import { logFirestoreEvent } from "../merlinv1/beta_api";
import MetaTags from "react-meta-tags";
import firebase from "firebase";

const signInFormSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const SignIn = (props) => {
  // Sets a loading state
  const [isLoading, setIsLoading] = useState(false);
  const handleMicrosoftSignIn = (e) => props.signInWithMicrosoft();
  const handleGoogleSignIn = (e) => props.signInWithGoogle();

  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(signInFormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    await props.signIn(data);
  };

  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
    logScreenName("SignIn");
    logEvent("page_view", { page_name: "SignIn" });
  }, []);

  return (
    <div className='min-h-screen overflow-hidden'>
      <MetaTags>
        <title>Sign In</title>
        <meta name='description' content='Quasi | Sign In Page' />
        <meta property='og:title' content='Quasi | Sign In' />
      </MetaTags>
      <Wrapper>
        <div className='text-blue-pondr w-auto md:text-5xl sm:text-3xl font-bold'>
          Welcome to Quasi!{" "}
        </div>
        <div className='grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-5 mb-5'>
          {
            // <Card className="mx-auto  max-w-3/4 md:w-4/5 h-4/5 shadow-2xl" style={{ backgroundColor: "#F7F7F7" }}>
          }
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='h-full rounded-3xl bg-gray-tertiaryBox'>
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1'
            />
            <div className='flex flex-wrap mb-12 mt-5 -mt-5 -mb-5'>
              <div className='w-auto mb-4'>
                <p className='w-full text-3xl text-black font-semibold'>
                  Sign Into My Account{" "}
                </p>
              </div>

              {props.user.authError ? (
                <div className='w-full p-6 mx-3 mb-4 bg-red-200 border-2 border-red-600 text-red-600 rounded '>
                  {props.user.authError}
                </div>
              ) : null}

              <div className='flex flex-col w-full'>
                <div className='flex flex-row'>
                  <span className='text-xl font-semibold'>Email</span>
                  <span className='text-red-orange'>*</span>
                </div>
                <input
                  className='w-full rounded-full mb-8 py-2 pl-6 bg-gray-secondaryBox'
                  name='email'
                  label='Email'
                  placeholder='Enter your email'
                  type='text'
                  required
                  ref={register}
                  errors={errors}
                />
                <div className='flex flex-row'>
                  <span className='text-xl font-semibold'>Password</span>
                  <span className='text-red-orange'>*</span>
                </div>
                <input
                  className='w-full rounded-full py-2 pl-6 pr-4 bg-gray-secondaryBox'
                  name='password'
                  label='Password'
                  placeholder='Enter your password'
                  type='password'
                  required
                  ref={register}
                  errors={errors}
                />
              </div>

              <div className='flex flex-row w-full justify-left my-4'>
                <button
                  className='my-auto mr-auto w-full text-sm text-blue-pondr'
                  type='submit'>
                  Sign in
                </button>
                <Link
                  to='/reset-password'
                  className='my-auto mr-auto w-full text-sm text-blue-pondr'>
                  Forgot password?
                </Link>
              </div>

              <Link
                to='/signup'
                className='mt-2 my-4 mr-auto w-full text-left text-sm text-blue-pondr'>
                Don't have an account? Sign up
              </Link>

              {/* <div className="flex justify-start md:auto w-full">
                <div className="text-center bold">
                  <b>---- Or ----</b>
                </div>
              </div>

              <div className="flex justify-start mt-4 md:auto w-full">
                <Button type="button" className="login-with-google-btn mt-2 mb-8 mr-2"
                  onClick={() => { handleGoogleSignIn(); }}>
                  Sign In with Google
                </Button>
                <Button type="button" className="login-with-microsoft-btn mt-2 mb-8 mr-2"
                  onClick={() => { handleMicrosoftSignIn(); }}>
                  Sign In with Microsoft
                </Button>
              </div> */}
            </div>
          </form>
          {
            //  </Card>
          }
          <div className='grid grid-cols-1 gap-5'>
            <div className='bg-gray-tertiaryBox rounded-3xl p-6 flex flex-col'>
              <div className='text-red-orange text-base'>Definition</div>
              <div className='font-bold text-3xl mb-2'>
                <span>Quasi Model</span>
              </div>
              <div className='text-gray-700 text-base'>
                A program that uses a set of data to identify patterns and
                create new things.
              </div>
            </div>
            <div className='bg-gray-tertiaryBox rounded-3xl p-6 flex flex-col'>
              <div className='text-red-orange text-base'>Question</div>
              <div className='font-bold text-3xl mb-2'>
                <span>What Is Quasi?</span>
              </div>
              <div className='text-gray-700 text-base'>
                Quasi is a digital marketplace full of AI tools that help people
                create personal content within seconds. From AI Text to AI
                Images and even AI Code, creating becomes infinitely more
                personal and accessible to anyone.
              </div>
            </div>
            <div
              className='bg-gray-tertiaryBox hover:bg-gray-300 rounded-3xl p-6 flex flex-col'
              onClick={() => {
                logFirestoreEvent("GiveFeedback", {});
                window.open("https://forms.gle/Uov7D7UGiMiq1zfy7", "_blank");
              }}>
              <div className='text-red-orange text-base'>Feedback</div>
              <div className='font-bold text-3xl mb-2'>
                <span>We Want to Hear From You!</span>
              </div>
              <div className='text-gray-700 text-base'>
                Like or dislike Quasi? We want to hear your thoughts on the
                platform! Click to submit feedback.
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: async (creds) => {
      const returnSignIn = await signIn(creds);
      dispatch(returnSignIn);
    },
    signInWithMicrosoft: () => {
      const returnSignInWithMicrosoft = signInWithMicrosoft();
      dispatch(returnSignInWithMicrosoft);
    },
    signInWithGoogle: () => {
      const returnSignInWithGoogle = signInWithGoogle();
      dispatch(returnSignInWithGoogle);
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
