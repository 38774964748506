import React from "react";
import { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import MetaTags from "react-meta-tags";
import PublicCreationsPartial from "../partials/PublicCreationsPartial";

const Creations = (props) => {
  // Sets screen analytics
  useEffect(() => {
  }, []);
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <MetaTags>
        <title>Quasi Creation</title>
        <meta
          name="description"
          content="Quasi Market: AI Art and Creations"
        />
        <meta name="audience" content="all" />
        <meta name="keywords" content="ai, artificialintelligence, quasi, quasiai, quasiartificialintelligence, aitext, gpt3, gpt2, gptneo, opt, openai, artificialintelligencetext, aicopy, openai, quasiaitext, quasiartificialintelligencetext, ai, artificialintelligenc, quasi, quasiai, quasiartificialintelligence, aiimage, aiimages, artificialintelligenceimage, artificialintelligenceimages, aiart, artificialintelligenceart, dalle, dalle2, gans, gan, imagen, dallemini, craiyon, openai, google, quasiaiimages, quasiartificialintelligenceimages" />
        <meta property="og:title" content="Quasi Creation" />
      </MetaTags>

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="">
        {/*  Page sections */}
        <PublicCreationsPartial />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
};

export default Creations;
