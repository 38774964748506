import React, { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import BountyPartial from "../partials/BountyPartial";
import MetaTags from "react-meta-tags";

function BountyList() {

    useEffect(() => {
    }, []);

    return (
        <div>
            <MetaTags>
        <title>Vounty</title>
        <meta
          name="description"
          content="Quasi | Bounty Page"
        />
        <meta property="og:title" content="Quasi | Bounty" />
      </MetaTags>
            <Header />
            <main>
                <BountyPartial />
            </main>
            <Footer />
        </div>
    );
}

export default BountyList;