import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { getUserModels } from "../merlinv1/beta_api";
import { searchUserModels } from "../merlinv1/beta_api";
import { TiArrowBack } from "react-icons/ti";
import NonFormInput from "../pages/components/NonFormInput";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { Grid } from "@mui/material";
import {
  FaRegThumbsDown,
  FaRegThumbsUp,
  FaRegArrowAltCircleRight,
  FaDollarSign,
} from "react-icons/fa";
import { HiCursorClick } from "react-icons/hi";
import { MetaTags } from "react-meta-tags";
import ReactLoading from "react-loading";

const ModelPartial = React.forwardRef((props, ref) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [titles, setTitles] = useState([]);
  const [likes, setLikes] = useState([]);
  const [status, setStatus] = useState([]);
  const [clicks, setClicks] = useState([]);
  const [types, setTypes] = useState([]);
  const [earnings, setEarnings] = useState([]);
  const [following, setFollowing] = useState([]);
  const [description, setDescription] = useState([]);
  const [ids, setIds] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await getUserModels();
      setTitles(res.data["models"]);
      setTypes(res.data["types"]);
      setLikes(res.data["likes"]);
      setStatus(res.data["status"]);
      setClicks(res.data["clicks"]);
      setDescription(res.data["description"]);
      setIds(res.data["model_ids"]);
      setEarnings(res.data["earnings"]);
      setFollowing(res.data["following"]);
    } catch (err) {
      history.push("/ErrorPage");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handler = async (searchText) => {
    // changing the state to the name of the key
    // which is pressed

    try {
      const res = await searchUserModels(searchText);
      setTitles(res.data["models"]);
      setTypes(res.data["types"]);
      setLikes(res.data["likes"]);
      setStatus(res.data["status"]);
      setClicks(res.data["clicks"]);
      setDescription(res.data["description"]);
      setIds(res.data["model_ids"]);
      setEarnings(res.data["earnings"]);
      setFollowing(res.data["following"]);
    } catch (err) {
      history.push("/ErrorPage");
    }
  };

  const onCardClick = (id, type) => {
    console.log(type);
    if (type === "flow" || type === "Flow") {
      history.push("/edit/workflow=" + id);
    } else {
      history.replace({
        pathname: "/edit/" + new URLSearchParams({ model: id }),
      });
    }
    // }
  };

  const onArrowClick = (id, type) => {
    if (type === "image") {
      history.replace({
        pathname: "/make/" + new URLSearchParams({ imagemodel: id }),
      });
    } else {
      history.replace({
        pathname: "/make/" + new URLSearchParams({ model: id }),
      });
    }
  };

  return (
    <section>
      <MetaTags>
        <title>My Models</title>
        <meta name='My Models' content='Quasi Models' />
        <meta property='og:title' content='Quasi | Dashboard' />
      </MetaTags>
      <div
        className='lg:px-10 md:px-2 sm:px-2 pb-24'
        style={{ overflow: "auto" }}>
        <div className='flex flex-wrap flex-row justify-between pb-5'>
          <div className='text-left sm:text-5xl font-bold leading-tighter tracking-tighter my-auto text-left'>
            My Models
          </div>
          <Link
            to='/user/dashboard/'
            className='my-auto btn-sm bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox rounded-squircle md:text-base sm:text-xs'>
            <span>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M19.4434 12.5918C19.4434 8.60742 16.7578 5.86328 12.334 5.86328H6.48438L4.24805 5.96094L5.85938 4.61328L8.14453 2.38672C8.35938 2.16211 8.50586 1.89844 8.50586 1.52734C8.50586 0.853516 8.03711 0.355469 7.32422 0.355469C7.01172 0.355469 6.68945 0.492188 6.44531 0.736328L0.927734 6.17578C0.693359 6.41016 0.556641 6.74219 0.556641 7.07422C0.556641 7.40625 0.693359 7.74805 0.927734 7.98242L6.44531 13.4121C6.68945 13.6562 7.01172 13.793 7.32422 13.793C8.03711 13.793 8.50586 13.2949 8.50586 12.6211C8.50586 12.25 8.35938 11.9863 8.14453 11.7617L5.85938 9.54492L4.24805 8.19727L6.48438 8.29492H12.3242C15.332 8.29492 17.041 10.043 17.041 12.5137C17.041 14.9941 15.332 16.8008 12.3242 16.8008H10.0391C9.31641 16.8008 8.79883 17.3379 8.79883 18.0117C8.79883 18.6855 9.31641 19.2324 10.0391 19.2324H12.4414C16.8066 19.2324 19.4434 16.6055 19.4434 12.5918Z'
                  fill='black'
                />
              </svg>
            </span>
            <span className='pl-2'>Back To Dashboard</span>
          </Link>
        </div>
        <div
          className='sm:px-1 md:px-1 sm:pb-10 md:pb-10 lg:pb-10'
          style={{ marginBottom: "2rem" }}>
          <div className='relative text-gray-600 focus-within:text-gray-400'>
            <span className='absolute md:inset-y-0 sm:top-0 md:pt-1 sm:pt-3 left-0 flex md:justify-center sm:justify-end pl-6'>
              <button
                type='submit'
                className='focus:outline-none focus:shadow-outline'>
                <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  viewBox='0 0 24 24'
                  className='w-6 h-6'>
                  <path d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'></path>
                </svg>
              </button>
            </span>
            <NonFormInput
              className='px-16 py-3 w-full text-gray-800 bg-gray-secondaryBox rounded-3xl border-opacity-0 focus:border-opacity-0'
              placeholder='Search My Models'
              type='text'
              onChangeText={(newText) => handler(newText)}
            />
          </div>
        </div>

        <div>
          {isLoading ? (
            <div className='w-full flex justify-center items-center'>
              <ReactLoading
                type='spin'
                color='#6a4c93'
                height='3%'
                width='3%'
              />
            </div>
          ) : titles.length > 0 ? (
            <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-5'>
              {titles.map((model_title, index) => (
                <div
                  className='flex lg:flex-row md:flex-col sm:flex-col justify-between group rounded-2xl overflow-hidden bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox shadow-lg cursor-pointer'
                  data-aos='fade-up'
                  onClick={() => onCardClick(ids[index], types[index])}>
                  <div className='flex flex-col justify-between group '>
                    <div
                      className='sm:p-2 md:p-6 lg:p-12'
                      onClick={() => onCardClick(ids[index], types[index])}>
                      <p
                        className='text-gray-700 text-base'
                        style={{ textTransform: "capitalize" }}>
                        {types[index]} Model
                      </p>
                      <div className='font-bold text-xl mb-2'>
                        {model_title}
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-row justify-between md:flex-wrap sm:flex-wrap'>
                    <div className='flex flex-col justify-between group '>
                      <div
                        className='sm:p-2 md:p-6 lg:p-12'
                        onClick={() => onCardClick(ids[index], types[index])}>
                        <p
                          className='text-gray-700 text-base'
                          style={{ textTransform: "capitalize" }}>
                          Visibility
                        </p>
                        <div className='font-bold text-xl mb-2'>
                          {status[index]}
                        </div>
                      </div>
                    </div>

                    {/* followers */}

                    <div className='flex flex-col justify-between group '>
                      <div
                        className='sm:p-2 md:p-6 lg:p-12'
                        onClick={() => onCardClick(ids[index], types[index])}>
                        <p
                          className='text-gray-700 text-base'
                          style={{ textTransform: "capitalize" }}>
                          Followers
                        </p>
                        <div className='font-bold text-xl mb-2'>
                          {following[index]}
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col justify-between group '>
                      <div
                        className='sm:p-2 md:p-6 lg:p-12'
                        onClick={() => onCardClick(ids[index], types[index])}>
                        <p
                          className='text-gray-700 text-base'
                          style={{ textTransform: "capitalize" }}>
                          Clicks
                        </p>
                        <div className='font-bold text-xl mb-2'>
                          {clicks[index]}
                        </div>
                      </div>
                    </div>
                    {/* likes */}
                    <div className='flex flex-col justify-between group '>
                      <div
                        className='sm:p-2 md:p-6 lg:p-12'
                        onClick={() => onCardClick(ids[index], types[index])}>
                        <p
                          className='text-gray-700 text-base'
                          style={{ textTransform: "capitalize" }}>
                          Likes
                        </p>
                        <div className='font-bold text-xl mb-2'>
                          {likes[index]}
                        </div>
                      </div>
                    </div>

                    {/* earnings */}
                    <div className='flex flex-col justify-between group '>
                      <div
                        className='sm:p-2 md:p-6 lg:p-12'
                        onClick={() => onCardClick(ids[index], types[index])}>
                        <p
                          className='text-gray-700 text-base'
                          style={{ textTransform: "capitalize" }}>
                          Earnings
                        </p>
                        <div className='font-bold text-xl mb-2'>
                          ${earnings[index]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='bg-gray-tertiaryBox rounded-3xl p-6 text-center items-center'>
              <div className='pb-8 mx-auto'>
                Looks like you don't have any models yet. Try making one!
              </div>
              <Link
                className='bg-gray-darkButton hover:bg-gray-400 rounded-2xl p-2 btn-sm'
                to='/user/create-product'>
                <svg
                  width='21'
                  height='22'
                  viewBox='0 0 21 22'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M10.5 21.1348C16.0859 21.1348 20.6855 16.5352 20.6855 10.9492C20.6855 5.37305 16.0762 0.763672 10.4902 0.763672C4.91406 0.763672 0.314453 5.37305 0.314453 10.9492C0.314453 16.5352 4.91406 21.1348 10.5 21.1348ZM10.5 18.8984C6.0957 18.8984 2.56055 15.3535 2.56055 10.9492C2.56055 6.54492 6.08594 3 10.4902 3C14.9043 3 18.4492 6.54492 18.4492 10.9492C18.4492 15.3535 14.9043 18.8984 10.5 18.8984ZM5.98828 10.9492C5.98828 11.5156 6.39844 11.9258 6.97461 11.9258H9.50391V14.4648C9.50391 15.0312 9.91406 15.4414 10.4805 15.4414C11.0664 15.4414 11.4766 15.0312 11.4766 14.4648V11.9258H14.0156C14.5918 11.9258 14.9922 11.5156 14.9922 10.9492C14.9922 10.373 14.5918 9.95312 14.0156 9.95312H11.4766V7.42383C11.4766 6.84766 11.0664 6.4375 10.4805 6.4375C9.91406 6.4375 9.50391 6.84766 9.50391 7.42383V9.95312H6.97461C6.39844 9.95312 5.98828 10.373 5.98828 10.9492Z'
                    fill='#333333'
                  />
                </svg>
                <span className='pl-2'>Make New Model</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
});

export default ModelPartial;
