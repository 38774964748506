import React from "react";
import { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import MetricsCounter from "../partials/MetricsCounter";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";
import NewHomePartial from "../partials/NewHomePartial";

const Home = (props) => {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <MetaTags>
        <title>Home</title>
        <meta
          name='description'
          content='Quasi Market: the first ever AI marketplace'
        />
        <meta
          name='facebook-domain-verification'
          content='go460u6t8soug9eienhq6kqphkebsr'
        />
        <meta property='og:title' content='Quasi | Home' />
      </MetaTags>

      {/*  Site header */}

      {/*  Page content */}
      <main className='flex-grow'>
        {/*  Page sections */}
        <Header />
        <NewHomePartial />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
};

export default Home;
