import React from "react";
import { useEffect } from "react";
import RadioPartial from "../partials/RadioPartial";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import MetaTags from "react-meta-tags";

const Gallery = (props) => {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <MetaTags>
        <title>Quasi Radio</title>
        <meta
          name="description"
          content="Quasi | Radio"
        />
        <meta property="og:title" content="Quasi | Radio" />
      </MetaTags>

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <RadioPartial />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
};

export default Gallery;
