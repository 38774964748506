import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsArrowUpRight } from 'react-icons/bs';
import { FaParagraph } from 'react-icons/fa';
import { AiOutlinePicture } from 'react-icons/ai';
import { HiCode } from 'react-icons/hi';


function BetaPartial() {
  return (
    <section>
      <div className="max-w-full mx-auto bg-black">
        <div className="w-full lg:max-w-none">
          <div className="bg-gray-800 py-16 w-full">
            <div
              className="text-left text-white md:text-6xl md:pt-20 sm:text-5xl font-bold leading-tighter tracking-tighter mb-4 pb-2 text-center"
              data-aos="zoom-y-out"
            >
              <span className="text-left bg-clip-text text-transparent bg-gradient-to-r from-purple-betaProgGradient to-green-betaProgGradient">
                Beta Program
              </span>
            </div>

            <div
              className="text-left text-white sm:text-3xl font-semibold leading-tighter tracking-tighter mb-2 pb-5 text-center"
              data-aos="zoom-y-out"
            >
              Gain early access to the latest and <br /> greatest that Quasi has to offer.
            </div>
            <div
              className="text-left text-white sm:text-xl leading-tighter tracking-tighter mb-4 pb-16 text-center"
            //data-aos="zoom-y-out"
            >
              The Quasi Beta Program adds powerful new development features, perfect <br /> for working with the newest innovations in AI. Develop, publish, and iterate <br /> directly within Quasi. Apply for access to new tools today!
            </div>
          </div>

          <div className="sm:mx-1 md:mx-20 lg:mx-40 py-20">
            <div className="flex flex-col py-10">
              <span>
                <svg width="55" height="43.2978723" viewBox="0 0 94 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.7734 73.2812H80.6875C89.125 73.2812 93.5 68.9453 93.5 60.625V13.0469C93.5 4.72656 89.125 0.390625 80.6875 0.390625H13.7734C5.33594 0.390625 0.960938 4.6875 0.960938 13.0469V60.625C0.960938 68.9453 5.33594 73.2812 13.7734 73.2812ZM14.2422 65.625C10.6094 65.625 8.61719 63.75 8.61719 59.9609V13.7109C8.61719 9.92188 10.6094 8.00781 14.2422 8.00781H80.2188C83.8125 8.00781 85.8438 9.92188 85.8438 13.7109V59.9609C85.8438 63.75 83.8125 65.625 80.2188 65.625H14.2422ZM31.5078 36.6016C36.1953 36.6406 39.9453 32.6953 39.9453 27.4219C39.9453 22.5 36.1953 18.3984 31.5078 18.3984C26.8594 18.3984 23.0703 22.5 23.0703 27.4219C23.0703 32.6953 26.8594 36.6016 31.5078 36.6016ZM56.5469 24.2578H76.8203C78.2656 24.2578 79.3203 23.1641 79.3203 21.7578C79.3203 20.3906 78.2656 19.3359 76.8203 19.3359H56.5469C55.1016 19.3359 54.0469 20.3906 54.0469 21.7578C54.0469 23.1641 55.1016 24.2578 56.5469 24.2578ZM56.5469 39.3359H76.8203C78.2656 39.3359 79.3203 38.2422 79.3203 36.8359C79.3203 35.4688 78.2656 34.375 76.8203 34.375H56.5469C55.1016 34.375 54.0469 35.4688 54.0469 36.8359C54.0469 38.2422 55.1016 39.3359 56.5469 39.3359ZM16.7812 54.375H46.2344C47.6406 54.375 48.3828 53.3984 48.3828 52.1094C48.3828 48.4766 43.0312 39.2188 31.5078 39.2188C19.9844 39.2188 14.6719 48.4766 14.6719 52.1094C14.6719 53.3984 15.375 54.375 16.7812 54.375ZM56.5469 54.375H76.8203C78.2656 54.375 79.3203 53.3203 79.3203 51.9531C79.3203 50.5469 78.2656 49.4141 76.8203 49.4141H56.5469C55.1016 49.4141 54.0469 50.5469 54.0469 51.9531C54.0469 53.3203 55.1016 54.375 56.5469 54.375Z" fill="#6BCEBD" />
                </svg>

              </span>
              <span className="text-white text-4xl font-semibold pb-2 pt-4">
                Eligibility
              </span>
              <span className="text-purple-betaProgGradient text-xl">
                To request access to Quasi Beta, you'll need to be an Account holder on the Quasi Platform as of April 14, 2021. <br />
                You'll also need to have developed and published atleast 2 models to the marketplace.
              </span>
            </div>
            <div className="flex flex-col py-10">
              <span>
                <svg width="55" height="48.3516484" viewBox="0 0 91 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.1875 34.4844C27.5625 34.4844 35.375 26.6719 35.375 17.2969C35.375 7.84375 27.5625 0.03125 18.1875 0.03125C8.73438 0.03125 0.960938 7.84375 0.960938 17.2969C0.960938 26.6719 8.77344 34.4844 18.1875 34.4844ZM16.1172 26.5156C15.2578 26.5547 14.6328 26.0859 14.0469 25.3828L9.67188 20.1484C9.24219 19.6406 9.08594 19.1719 9.08594 18.5078C9.08594 17.2969 10.0234 16.3594 11.1953 16.3594C11.9766 16.3594 12.5234 16.6719 13.0312 17.375L16.0391 21.125L22.9922 9.95312C23.5 9.17188 24.1641 8.78125 24.9453 8.78125C26.1172 8.78125 27.0938 9.67969 27.0938 10.7734C27.0938 11.3203 26.9766 11.8281 26.5859 12.4141L18.2656 25.3438C17.7578 26.0859 17.0156 26.5547 16.1172 26.5156ZM47.7578 20.7344H87.0547C88.9688 20.7344 90.5312 19.2109 90.5312 17.2969C90.5312 15.3828 88.9688 13.8594 87.0547 13.8594H47.7578C45.8438 13.8594 44.3594 15.3828 44.3594 17.2969C44.3594 19.2109 45.8438 20.7344 47.7578 20.7344ZM18.1875 79.6406C27.5625 79.6406 35.375 71.8281 35.375 62.4141C35.375 52.9609 27.5625 45.1875 18.1875 45.1875C8.73438 45.1875 0.960938 53 0.960938 62.4141C0.960938 71.8281 8.77344 79.6406 18.1875 79.6406ZM18.1875 73C12.4062 73 7.60156 68.1562 7.60156 62.4141C7.60156 56.6719 12.4453 51.7891 18.1875 51.7891C23.8906 51.7891 28.7734 56.6719 28.7734 62.4141C28.7734 68.1562 23.8906 73 18.1875 73ZM47.7578 65.9297H87.0547C88.9688 65.9297 90.5312 64.4062 90.5312 62.4922C90.5312 60.5781 88.9688 59.0547 87.0547 59.0547H47.7578C45.8438 59.0547 44.3594 60.5781 44.3594 62.4922C44.3594 64.4062 45.8438 65.9297 47.7578 65.9297Z" fill="#6BCEBD" />
                </svg>


              </span>
              <span className="text-white text-4xl font-semibold pb-2 pt-4">
                Technical Requirements
              </span>
              <span className="text-purple-betaProgGradient text-xl">
                We recommend developing on a desktop-class browser for an optimal experience. Mobile browser will be fully <br />
                supported later this fall.
              </span>
            </div>
          </div>


          <div className="p-6">
            <div className='bg-gradient-to-r from-purple-betaProgGradient to-green-betaProgGradient rounded-xl text-center flex flex-col text-4xl py-20'>
              <span className="items-center mx-auto text-3xl pb-4"><svg width="47" height="74" viewBox="0 0 47 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.01562 73.1641H39.9844C44.2422 73.1641 46.8203 70.7422 46.8203 66.2891V65.5469C46.8203 55.8984 36.7422 45.8203 31.625 40.7812C28.9688 38.0859 29.125 35.2344 31.625 32.7344C36.7422 27.6562 46.8203 17.6172 46.8203 7.92969V7.30469C46.8203 2.85156 44.2422 0.390625 39.9844 0.390625H7.01562C2.79688 0.390625 0.179688 2.85156 0.179688 7.30469V7.92969C0.179688 17.6172 10.2969 27.6562 15.4141 32.7344C17.9141 35.2344 18.0703 38.0859 15.4141 40.7812C10.2969 45.8203 0.179688 55.8984 0.179688 65.5469V66.2891C0.179688 70.7422 2.79688 73.1641 7.01562 73.1641ZM10.2969 67.0703C8.53906 67.0703 8.07031 65 9.24219 63.8672L21.1562 52.3828C21.8203 51.7969 21.9766 51.6016 21.9766 50.6641V34.0625C21.9766 32.1484 21.5859 31.0938 20.1797 29.8828C17.6797 27.6953 14.1641 24.6094 11.8594 22.1484C10.7656 21.0156 11.3125 19.5703 12.7578 19.5703H34.2812C35.7266 19.5703 36.2344 21.0156 35.1406 22.1484C32.875 24.6094 29.3203 27.6953 26.8203 29.8828C25.4141 31.0938 25.0234 32.1484 25.0234 34.0625V50.6641C25.0234 51.6016 25.2188 51.7969 25.8438 52.3828L37.7578 63.8672C38.9688 65 38.5 67.0703 36.7031 67.0703H10.2969Z" fill="white" />
              </svg>

              </span>
              <span className="font-semibold text-white">
                Join The Waitlist.
              </span>
              <Link className="btn-sm text-xl text-blue-link flex flex-row mx-auto">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.4707 11.5723L15.4609 1.77734C15.4609 1.01562 14.9531 0.498047 14.1816 0.498047H4.37695C3.6543 0.498047 3.12695 1.04492 3.12695 1.69922C3.12695 2.34375 3.68359 2.87109 4.35742 2.87109H7.90234L11.6816 2.73438L9.93359 4.28711L0.919922 13.3105C0.666016 13.5645 0.519531 13.8867 0.519531 14.1992C0.519531 14.834 1.10547 15.4395 1.75977 15.4395C2.08203 15.4395 2.38477 15.3027 2.64844 15.0391L11.6719 6.02539L13.2344 4.27734L13.0781 7.91016V11.5918C13.0781 12.2754 13.6055 12.832 14.2598 12.832C14.9141 12.832 15.4707 12.2852 15.4707 11.5723Z" fill="black" />
                </svg>

                <span className="pl-2">Sign Up For Quasi Beta</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BetaPartial;