import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../store/actions/authActions";
import {
  getRandomModel,
  searchModels,
  getUserHeader,
} from "../merlinv1/beta_api";
import Transition from "../utils/Transition.js";
import Dropdown from "../utils/Dropdown";
import Social_Media_Transparent from "../pages/components/Social_Media_Transparent.png";

const BusinessHeader = (props) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [top, setTop] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [filter, setFilter] = useState("Likes:desc");
  const [ids, setIds] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [topPosPrev, setTopPosPrev] = useState(0);
  const [types, setTypes] = useState([]);
  const [userType, setUserType] = useState("explore");

  const mobileNav = useRef(null);

  const addDataIntoCache = async (cacheName, url, response) => {
    // Converting our response into Actual Response form
    const data = new Response(JSON.stringify(response));

    if ("caches" in window) {
      // Opening given cache and putting our data into it
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
        window.location.reload(false);
      });
    }
    return "success";
  };

  const getSingleCacheData = async (cacheName, url) => {
    if (typeof caches === "undefined") return false;

    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);

    // If no cache exists
    if (!cachedResponse || !cachedResponse.ok) {
      fetchData();
    }

    return cachedResponse.json().then((item) => {
      setUserType(item);
    });
  };

  const getSuggestions = (searchText) => {
    setIsLoading(true);
    searchModels(searchText, filter).then((res) => {
      setSuggestions(res.data.models);
      setIds(res.data.model_ids);
      setTypes(res.data.types);
      setTopPosPrev(0);

      setIsLoading(false);
    });
  };

  const handleKeyPress = (event) => {
    if (event.target.value === "") {
      setSuggestions([]);
      setIds([]);
      setIsLoading(false);
    } else {
      const input = String.fromCharCode(event.keyCode);

      if (/[a-zA-Z0-9-_ ]/.test(input) || event.key === "Backspace") {
        setIsLoading(true);
        searchModels(event.target.value, filter).then((res) => {
          setSuggestions(res.data.models);
          setIds(res.data.model_ids);
          setTypes(res.data.types);
          setTopPosPrev(0);

          setIsLoading(false);
        });
      }
    }

    if (event.key === "ArrowDown") {
      if (suggestions.length === 1) {
        setSelectedIndex(0);
      }
      if (selectedIndex < suggestions.length - 1) {
        if (selectedIndex === null) {
          setSelectedIndex(0);
          //focus the element so the div will scroll to it
          if (selectedIndex > topPosPrev + 2) {
            var topPos = document.getElementById("suggestion-0").offsetTop;
            document.getElementById("autocomplete-items").scrollTop = topPos;
            setTopPosPrev(selectedIndex);
          }
        } else {
          setSelectedIndex(selectedIndex + 1);
          //focus the element so the div will scroll to it

          if (selectedIndex > topPosPrev + 1) {
            var topPos = null;
            if (selectedIndex < suggestions.length - 3) {
              topPos = document.getElementById(
                "suggestion-" + (selectedIndex - 2)
              ).offsetTop;
            } else {
              topPos = document.getElementById(
                "suggestion-" + (suggestions.length - 3)
              ).offsetTop;
            }

            document.getElementById("autocomplete-items").scrollTop = topPos;
            setTopPosPrev(selectedIndex);
          }
        }
        window.scrollTo(0, 0);
      }
    } else if (event.key === "ArrowUp") {
      if (selectedIndex !== null) {
        if (selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1);

          if (selectedIndex < topPosPrev) {
            var topPos = null;
            if (selectedIndex >= 3) {
              topPos = document.getElementById(
                "suggestion-" + (selectedIndex - 3)
              ).offsetTop;
            } else {
              topPos = document.getElementById("suggestion-0").offsetTop;
            }
            document.getElementById("autocomplete-items").scrollTop = topPos;
            setTopPosPrev(selectedIndex);
          }

          window.scrollTo(0, 0);
        } else {
          setSelectedIndex(null);
        }
      }
    } else if (event.key === "Enter") {
      if (selectedIndex === null) {
        setSuggestions([]);
        setIds([]);
        setSearchText("");
        history.replace({
          pathname: "/create/" + new URLSearchParams({ search: searchText }),
        });
        if (currentSelectedPathname.includes("/create")) {
          window.location.reload();
        }
      } else {
        setSuggestions([]);
        setIds([]);
        setSearchText("");
        if (types[selectedIndex] === "image") {
          history.replace({
            pathname:
              "/make/" +
              new URLSearchParams({ imagemodel: ids[selectedIndex] }),
          });
        } else if (types[selectedIndex] === "flow") {
          history.replace({
            pathname:
              "/make/" + new URLSearchParams({ workflow: ids[selectedIndex] }),
          });
        } else {
          history.replace({
            pathname:
              "/make/" + new URLSearchParams({ model: ids[selectedIndex] }),
          });
        }
        if (currentSelectedPathname.includes("/make")) {
          window.location.reload();
        }
      }
    }
  };

  const handleRandom = async () => {
    const res = await getRandomModel();
    var type = res.data["type"];
    var id = res.data["randId"];
    if (type === "image") {
      history.replace({
        pathname: "/make/" + new URLSearchParams({ imagemodel: id }),
      });
    } else {
      history.replace({
        pathname: "/make/" + new URLSearchParams({ model: id }),
      });
    }
    window.location.reload();
  };

  const fetchData = async () => {
    try {
      const res = await getUserHeader();
      setUserType(res.data.type);
      const dataCashed = await addDataIntoCache(
        "userType",
        "http://localhost:3000/Home",
        res.data.type
      );
    } catch (err) {
      history.push("/ErrorPage");
    }
  };

  const checkData = async () => {
    const cached = await getSingleCacheData(
      "userType",
      "http://localhost:3000/Home"
    );
  };
  useEffect(() => {
    if (props.auth.uid) {
      checkData();
    }
  }, []);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const history = useHistory();

  const baseHeaderClassname =
    "text-black hover:text-blue-pondr px-3 lg:px-5 py-2 flex justify-between items-center transition duration-150 ease-in-out outline-none text-md border-b hover:border-blue-pondr";
  const currentSelectedPathname = window.location.pathname;

  return (
    <header
      className={`w-full z-30 md:bg-opacity-80 text-md transition duration-300 ease-in-out bg-gray-tertiaryBox ${
        !top && "text-md bg-white"
      }`}>
      <div className='text-md xl:px-12 lg:px-0 sm:px-2 lg:ml-3 font-medium mx-auto w-full'>
        <div className='text-md flex items-center justify-between h-16 md:h-20 outline-none w-full'>
          <Link to='/business' className='block' aria-label='Cruip'>
            <img
              className='mx-auto outline-none sm:hidden md:hidden lg:block xl:block'
              src={
                "https://storage.googleapis.com/quasi-a39a6.appspot.com/Quasi_Business_Logo.png"
              }
              width='191'
              height='100'
              alt='Hero'
            />

            <img
              className='object-cover outline-none sm:block md:block lg:hidden xl:hidden mr-1'
              src={Social_Media_Transparent}
              alt='Hero'
              style={{ height: "51.77px", width: "51.77px" }}
            />
          </Link>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: (creds) => {
      dispatch(signOut(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessHeader);
