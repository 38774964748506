import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import Header from "../partials/Header";
import Wrapper from "../utils/Wrapper";
import UserDashboard from "../partials/UserDashboard";
import ReactLoading from "react-loading";
import {
  getProductsByCompany,
  getStripeCustomer,
  isCustomerSubscribed,
} from "../merlinv1/beta_api";
import { connect } from "react-redux";

const Product = (props) => {
  // State variables for the screen
  const [tabSelected, setTabSelected] = useState(0);
  const [tabHighlighted, setTabHighlighted] = useState(0);
  const [productSelected, setProductSelected] = useState("");
  const [companyProducts, setCompanyProducts] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [canViewProduct, setCanViewProduct] = useState("");
  const [stripeCustomer, setStripeCustomer] = useState("");

  // The useLocation hook
  const history = useHistory();

  // Fetches and sets the initial state of the screen depending on what is passed in from the URL search params;
  useEffect(() => {}, []);

  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <Header />
      <div className={"flex justify-between"}>
        <Wrapper dontShowHeader={true} isGrey={false} topHead={true}>
          <UserDashboard />
        </Wrapper>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(withRouter(Product));
