import React, { useState, useEffect } from "react";
import { askQuestion } from "../merlinv1/beta_api";
import SchoolDropdown from "../utils/SchoolDropdown";
import Dropdown from "../utils/Dropdown";
import SchoolSubDropdown from "../utils/SchoolSubDropdown";

const SchoolPartial = React.forwardRef((props, ref) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [messages, setMessages] = useState(["What can I help you with?"]);
  const [senders, setSenders] = useState(["BOT"]);
  const [userReply, setUserReply] = useState("");
  const [subject, setSubject] = useState("Math");
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const mathSubjects = [
    "Statistics",
    "Calculus",
    "Algebra",
    "Cryptography",
    "Probability",
    "Linear Algebra",
  ];
  const humanitiesSubjects = [
    "Philosophy",
    "Psychology",
    "Law",
    "Economics",
    "History",
    "Geography",
    "Language",
    "Poetry",
    "Music",
  ];
  const csSubjects = [
    "C Language",
    "Python language",
    "Java language",
    "Computer Networking",
    "Matlab Language",
    "Html Language",
    "Blockchain",
    "Machine Learning",
  ];
  const scienceSubjects = [
    "Biology",
    "Physics",
    "Chemistry",
    "Medicine",
    "Astrophysics",
    "Earth Sciences",
    "Biochemistry",
    "Geology",
  ];
  const engineeringSubjects = [
    "Computer Engineering",
    "Mechanical Engineering",
    "Electrical Engineering",
    "Bio Engineering",
    "Chemical Engineering",
    "Civil Engineering",
  ];
  const languageSubjects = [
    "Latin",
    "French",
    "Spanish",
    "Russian",
    "Japanese",
    "Mandarin",
    "English",
    "Portuguese",
  ];

  var lastClick = 0;
  var delay = 20;

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(messages[messages.length - 1]).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    });
  };

  const ask = async (reply) => {
    if (lastClick >= Date.now() - delay) {
      return;
    }
    lastClick = Date.now();
    try {
      setLoading(true);
      var res = await askQuestion(questions, answers, reply, subject);
      setQuestions(res.data["AI Answer"]["questions"]);
      setAnswers(res.data["AI Answer"]["answers"]);
      var tempMessages = messages;
      console.log(tempMessages);
      tempMessages.push(
        res.data["AI Answer"]["answers"][
          res.data["AI Answer"]["answers"].length - 1
        ]
      );
      console.log(tempMessages);
      setMessages(tempMessages);
      var tempSenders = senders;
      tempSenders.push("BOT");
      setSenders(tempSenders);
      setLoading(false);
      document.getElementById("inputBar").scrollIntoView();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className='pb-12'>
      <div className='pt-12 flex flex-row items-center justify-between'>
        <div className='flex flex-row items-center pl-8'>
          <span className='text-3xl font-semibold mr-4'>Ask Quasi</span>
          <div className='px-2 py-1 rounded-xl bg-gray-tertiaryBox flex flex-row items-center'>
            <svg
              width='16'
              height='16'
              viewBox='0 0 22 22'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M11.0664 21.1348C16.6523 21.1348 21.252 16.5352 21.252 10.9492C21.252 5.37305 16.6426 0.763672 11.0566 0.763672C5.48047 0.763672 0.880859 5.37305 0.880859 10.9492C0.880859 16.5352 5.48047 21.1348 11.0664 21.1348ZM11.0664 18.8984C6.66211 18.8984 3.12695 15.3535 3.12695 10.9492C3.12695 6.54492 6.65234 3 11.0566 3C15.4707 3 19.0156 6.54492 19.0156 10.9492C19.0156 15.3535 15.4707 18.8984 11.0664 18.8984ZM11.0273 7.88281C11.7793 7.88281 12.3848 7.27734 12.3848 6.53516C12.3848 5.76367 11.7793 5.16797 11.0273 5.16797C10.2852 5.16797 9.66992 5.76367 9.66992 6.53516C9.66992 7.27734 10.2852 7.88281 11.0273 7.88281ZM9.35742 16.252H13.2637C13.7129 16.252 14.0742 15.9199 14.0742 15.4512C14.0742 15.0215 13.7129 14.6699 13.2637 14.6699H12.2578V10.334C12.2578 9.71875 11.9551 9.31836 11.3789 9.31836H9.5332C9.07422 9.31836 8.72266 9.66016 8.72266 10.0996C8.72266 10.5684 9.07422 10.8906 9.5332 10.8906H10.4805V14.6699H9.35742C8.9082 14.6699 8.54688 15.0215 8.54688 15.4512C8.54688 15.9199 8.9082 16.252 9.35742 16.252Z'
                fill='#FFCA3A'
              />
            </svg>
            <span className='text-xs text-yellow-school pl-2'>Info</span>
          </div>
        </div>
        {/* <div className="px-2 py-1 rounded-xl bg-gray-tertiaryBox mr-8 w-1/4">
                    <SchoolDropdown title={"Current subject: " + subject}
                        className="absolute bg-gray-tertiaryBox">
                        <SchoolSubDropdown
                            openOnHover={true}
                            title={"Math"}
                            className="flex flex-col justify-start">
                            {mathSubjects.map((subject, index) => (
                                <button onClick={() => { setSubject(subject) }}
                                    className="text-left flex flex-row items-center justify-between py-1 pr-2 border-b pl-8">
                                    <span>{subject}</span>
                                    <span className="pr-2">
                                        <svg width="15" height="12.75" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.2832 0.697266C1.51172 0.697266 1.16992 1.2832 1.08203 1.82031C0.994141 2.2793 0.974609 2.79688 0.974609 3.36328C0.974609 7.86523 2.88867 9.99414 7.33203 9.99414H13.5234L15.8086 9.85742L13.1914 12.2305L11.0137 14.4277C10.7891 14.6523 10.6426 14.9453 10.6426 15.2969C10.6426 15.9609 11.1406 16.459 11.8438 16.459C12.1465 16.459 12.4492 16.332 12.7227 16.0684L19.1094 9.68164C19.3633 9.44727 19.5 9.10547 19.5 8.77344C19.5 8.44141 19.3633 8.10938 19.1094 7.86523L12.7031 1.46875C12.4492 1.21484 12.1465 1.08789 11.8438 1.08789C11.1406 1.08789 10.6426 1.58594 10.6426 2.25C10.6426 2.61133 10.7891 2.9043 11.0137 3.11914L13.1914 5.31641L15.8184 7.69922L13.5234 7.55273H7.49805C4.50977 7.55273 3.43555 6.33203 3.43555 3.31445C3.43555 2.71875 3.47461 2.28906 3.47461 1.89844C3.47461 1.17578 2.99609 0.697266 2.2832 0.697266Z" fill="#333333" />
                                        </svg>
                                    </span>
                                </button>
                            ))}
                        </SchoolSubDropdown>
                        <SchoolSubDropdown
                            openOnHover={true}
                            title={"Humanities"}
                            className="flex flex-col justify-start">
                            {humanitiesSubjects.map((subject, index) => (
                                <button onClick={() => { setSubject(subject) }}
                                    className="text-left flex flex-row items-center justify-between py-1 pr-2 border-b pl-8">
                                    <span>{subject}</span>
                                    <span className="pr-2">
                                        <svg width="15" height="12.75" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.2832 0.697266C1.51172 0.697266 1.16992 1.2832 1.08203 1.82031C0.994141 2.2793 0.974609 2.79688 0.974609 3.36328C0.974609 7.86523 2.88867 9.99414 7.33203 9.99414H13.5234L15.8086 9.85742L13.1914 12.2305L11.0137 14.4277C10.7891 14.6523 10.6426 14.9453 10.6426 15.2969C10.6426 15.9609 11.1406 16.459 11.8438 16.459C12.1465 16.459 12.4492 16.332 12.7227 16.0684L19.1094 9.68164C19.3633 9.44727 19.5 9.10547 19.5 8.77344C19.5 8.44141 19.3633 8.10938 19.1094 7.86523L12.7031 1.46875C12.4492 1.21484 12.1465 1.08789 11.8438 1.08789C11.1406 1.08789 10.6426 1.58594 10.6426 2.25C10.6426 2.61133 10.7891 2.9043 11.0137 3.11914L13.1914 5.31641L15.8184 7.69922L13.5234 7.55273H7.49805C4.50977 7.55273 3.43555 6.33203 3.43555 3.31445C3.43555 2.71875 3.47461 2.28906 3.47461 1.89844C3.47461 1.17578 2.99609 0.697266 2.2832 0.697266Z" fill="#333333" />
                                        </svg>
                                    </span>
                                </button>
                            ))}
                        </SchoolSubDropdown>
                        <SchoolSubDropdown
                            openOnHover={true}
                            title={"Computer Science"}
                            className="flex flex-col justify-start">
                            {csSubjects.map((subject, index) => (
                                <button onClick={() => { setSubject(subject) }}
                                    className="text-left flex flex-row items-center justify-between py-1 pr-2 border-b pl-8">
                                    <span>{subject}</span>
                                    <span className="pr-2">
                                        <svg width="15" height="12.75" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.2832 0.697266C1.51172 0.697266 1.16992 1.2832 1.08203 1.82031C0.994141 2.2793 0.974609 2.79688 0.974609 3.36328C0.974609 7.86523 2.88867 9.99414 7.33203 9.99414H13.5234L15.8086 9.85742L13.1914 12.2305L11.0137 14.4277C10.7891 14.6523 10.6426 14.9453 10.6426 15.2969C10.6426 15.9609 11.1406 16.459 11.8438 16.459C12.1465 16.459 12.4492 16.332 12.7227 16.0684L19.1094 9.68164C19.3633 9.44727 19.5 9.10547 19.5 8.77344C19.5 8.44141 19.3633 8.10938 19.1094 7.86523L12.7031 1.46875C12.4492 1.21484 12.1465 1.08789 11.8438 1.08789C11.1406 1.08789 10.6426 1.58594 10.6426 2.25C10.6426 2.61133 10.7891 2.9043 11.0137 3.11914L13.1914 5.31641L15.8184 7.69922L13.5234 7.55273H7.49805C4.50977 7.55273 3.43555 6.33203 3.43555 3.31445C3.43555 2.71875 3.47461 2.28906 3.47461 1.89844C3.47461 1.17578 2.99609 0.697266 2.2832 0.697266Z" fill="#333333" />
                                        </svg>
                                    </span>
                                </button>
                            ))}
                        </SchoolSubDropdown>
                        <SchoolSubDropdown
                            openOnHover={true}
                            title={"Science"}
                            className="flex flex-col justify-start">
                            {scienceSubjects.map((subject, index) => (
                                <button onClick={() => { setSubject(subject) }}
                                    className="text-left flex flex-row items-center justify-between py-1 pr-2 border-b pl-8">
                                    <span>{subject}</span>
                                    <span className="pr-2">
                                        <svg width="15" height="12.75" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.2832 0.697266C1.51172 0.697266 1.16992 1.2832 1.08203 1.82031C0.994141 2.2793 0.974609 2.79688 0.974609 3.36328C0.974609 7.86523 2.88867 9.99414 7.33203 9.99414H13.5234L15.8086 9.85742L13.1914 12.2305L11.0137 14.4277C10.7891 14.6523 10.6426 14.9453 10.6426 15.2969C10.6426 15.9609 11.1406 16.459 11.8438 16.459C12.1465 16.459 12.4492 16.332 12.7227 16.0684L19.1094 9.68164C19.3633 9.44727 19.5 9.10547 19.5 8.77344C19.5 8.44141 19.3633 8.10938 19.1094 7.86523L12.7031 1.46875C12.4492 1.21484 12.1465 1.08789 11.8438 1.08789C11.1406 1.08789 10.6426 1.58594 10.6426 2.25C10.6426 2.61133 10.7891 2.9043 11.0137 3.11914L13.1914 5.31641L15.8184 7.69922L13.5234 7.55273H7.49805C4.50977 7.55273 3.43555 6.33203 3.43555 3.31445C3.43555 2.71875 3.47461 2.28906 3.47461 1.89844C3.47461 1.17578 2.99609 0.697266 2.2832 0.697266Z" fill="#333333" />
                                        </svg>
                                    </span>
                                </button>
                            ))}
                        </SchoolSubDropdown>
                        <SchoolSubDropdown
                            openOnHover={true}
                            title={"Engineering"}
                            className="flex flex-col justify-start">
                            {engineeringSubjects.map((subject, index) => (
                                <button onClick={() => { setSubject(subject) }}
                                    className="text-left flex flex-row items-center justify-between py-1 pr-2 border-b pl-8">
                                    <span>{subject}</span>
                                    <span className="pr-2">
                                        <svg width="15" height="12.75" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.2832 0.697266C1.51172 0.697266 1.16992 1.2832 1.08203 1.82031C0.994141 2.2793 0.974609 2.79688 0.974609 3.36328C0.974609 7.86523 2.88867 9.99414 7.33203 9.99414H13.5234L15.8086 9.85742L13.1914 12.2305L11.0137 14.4277C10.7891 14.6523 10.6426 14.9453 10.6426 15.2969C10.6426 15.9609 11.1406 16.459 11.8438 16.459C12.1465 16.459 12.4492 16.332 12.7227 16.0684L19.1094 9.68164C19.3633 9.44727 19.5 9.10547 19.5 8.77344C19.5 8.44141 19.3633 8.10938 19.1094 7.86523L12.7031 1.46875C12.4492 1.21484 12.1465 1.08789 11.8438 1.08789C11.1406 1.08789 10.6426 1.58594 10.6426 2.25C10.6426 2.61133 10.7891 2.9043 11.0137 3.11914L13.1914 5.31641L15.8184 7.69922L13.5234 7.55273H7.49805C4.50977 7.55273 3.43555 6.33203 3.43555 3.31445C3.43555 2.71875 3.47461 2.28906 3.47461 1.89844C3.47461 1.17578 2.99609 0.697266 2.2832 0.697266Z" fill="#333333" />
                                        </svg>
                                    </span>
                                </button>
                            ))}
                        </SchoolSubDropdown>
                        <SchoolSubDropdown
                            openOnHover={true}
                            title={"World Languages"}
                            className="flex flex-col justify-start">
                            {languageSubjects.map((subject, index) => (
                                <button onClick={() => { setSubject(subject) }}
                                    className="text-left flex flex-row items-center justify-between py-1 pr-2 border-b pl-8">
                                    <span>{subject}</span>
                                    <span className="pr-2">
                                        <svg width="15" height="12.75" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.2832 0.697266C1.51172 0.697266 1.16992 1.2832 1.08203 1.82031C0.994141 2.2793 0.974609 2.79688 0.974609 3.36328C0.974609 7.86523 2.88867 9.99414 7.33203 9.99414H13.5234L15.8086 9.85742L13.1914 12.2305L11.0137 14.4277C10.7891 14.6523 10.6426 14.9453 10.6426 15.2969C10.6426 15.9609 11.1406 16.459 11.8438 16.459C12.1465 16.459 12.4492 16.332 12.7227 16.0684L19.1094 9.68164C19.3633 9.44727 19.5 9.10547 19.5 8.77344C19.5 8.44141 19.3633 8.10938 19.1094 7.86523L12.7031 1.46875C12.4492 1.21484 12.1465 1.08789 11.8438 1.08789C11.1406 1.08789 10.6426 1.58594 10.6426 2.25C10.6426 2.61133 10.7891 2.9043 11.0137 3.11914L13.1914 5.31641L15.8184 7.69922L13.5234 7.55273H7.49805C4.50977 7.55273 3.43555 6.33203 3.43555 3.31445C3.43555 2.71875 3.47461 2.28906 3.47461 1.89844C3.47461 1.17578 2.99609 0.697266 2.2832 0.697266Z" fill="#333333" />
                                        </svg>
                                    </span>
                                </button>
                            ))}
                        </SchoolSubDropdown>
                    </SchoolDropdown>
                </div> */}
      </div>
      <div className='rounded-3xl border-2 sm:mx-3 sm:mb-3 md:mx-8 md:mb-8 flex flex-col'>
        <div
          className={
            true ? "h-full flex flex-col p-2" : "h-75vh flex flex-col p-2"
          }>
          {messages.map((message, index) => (
            <div
              className={
                senders[index] == "USER"
                  ? "flex justify-end my-1"
                  : "flex justify-start my-1"
              }>
              <div className='flex flex-col' data-aos='fade-up'>
                {senders[index] == "USER" ? null : (
                  <span className='text-xs pl-2'>Quasi School</span>
                )}
                <div
                  className={
                    senders[index] == "USER"
                      ? "p-2 rounded-xl bg-yellow-school"
                      : "p-2 rounded-xl bg-gray-secondaryBox"
                  }>
                  {message}
                </div>
              </div>
            </div>
          ))}
          {loading ? (
            <div className='flex justify-start my-1'>
              <div className='p-2 rounded-xl bg-gray-secondaryBox'>...</div>
            </div>
          ) : null}
        </div>
        <div className='rounded-b-3xl bg-gray-tertiaryBox flex flex-col sm:p-3 md:p-6'>
          <input
            className='rounded-2xl bg-gray-secondaryBox w-full px-4 py-2'
            onChange={(e) => {
              setUserReply(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                var tempMessages = messages;
                var tempQuestions = questions;
                var tempSenders = senders;
                tempMessages.push(userReply);
                tempSenders.push("USER");
                tempQuestions.push(userReply);
                setMessages(tempMessages);
                setSenders(tempSenders);
                setQuestions(tempQuestions);
                document.getElementById("inputBar").scrollIntoView();
                ask(userReply);
                setUserReply("");
              }
            }}
            value={userReply}
            placeholder={"Type your response here!"}
            id={"inputBar"}
          />
          <div className='flex flex-row justify-end gap-2 pt-4'>
            <button
              onClick={handleCopyClick}
              className='px-2 py-1 bg-gray-secondaryBox rounded-xl flex flex-row items-center'>
              <svg
                width='18'
                height='17.2173913'
                viewBox='0 0 23 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M4.29688 16.877H5.9375V18.332C5.9375 20.4805 7.08984 21.6133 9.24805 21.6133H19.1992C21.3574 21.6133 22.5098 20.4707 22.5098 18.332V8.32227C22.5098 6.18359 21.3574 5.04102 19.1992 5.04102H17.5586V3.58594C17.5586 1.44727 16.4062 0.314453 14.248 0.314453H4.29688C2.12891 0.314453 0.986328 1.44727 0.986328 3.58594V13.5957C0.986328 15.7441 2.12891 16.877 4.29688 16.877ZM4.48242 14.709C3.62305 14.709 3.16406 14.2695 3.16406 13.3711V3.82031C3.16406 2.92188 3.62305 2.48242 4.48242 2.48242H14.0625C14.9121 2.48242 15.3809 2.92188 15.3809 3.82031V5.04102H9.24805C7.08984 5.04102 5.9375 6.18359 5.9375 8.32227V14.709H4.48242ZM9.43359 19.4355C8.57422 19.4355 8.11523 19.0059 8.11523 18.1074V8.54688C8.11523 7.64844 8.57422 7.21875 9.43359 7.21875H19.0137C19.8633 7.21875 20.332 7.64844 20.332 8.54688V18.1074C20.332 19.0059 19.8633 19.4355 19.0137 19.4355H9.43359ZM9.72656 13.332C9.72656 13.8984 10.1367 14.3086 10.7129 14.3086H13.2422V16.8477C13.2422 17.4141 13.6523 17.8242 14.2188 17.8242C14.8047 17.8242 15.2148 17.4141 15.2148 16.8477V14.3086H17.7539C18.3301 14.3086 18.7305 13.8984 18.7305 13.332C18.7305 12.7461 18.3301 12.3359 17.7539 12.3359H15.2148V9.80664C15.2148 9.2207 14.8047 8.82031 14.2188 8.82031C13.6523 8.82031 13.2422 9.23047 13.2422 9.80664V12.3359H10.7129C10.1367 12.3359 9.72656 12.7461 9.72656 13.332Z'
                  fill='#333333'
                />
              </svg>
              <span className='pl-2'>{copied ? "Copied!" : "Copy"}</span>
            </button>
            <button
              onClick={() => {
                var tempMessages = messages;
                var tempQuestions = questions;
                var tempSenders = senders;
                tempMessages.push("Elaborate");
                tempSenders.push("USER");
                tempQuestions.push("Elaborate");
                setMessages(tempMessages);
                setSenders(tempSenders);
                setQuestions(tempQuestions);
                document.getElementById("inputBar").scrollIntoView();
                ask("Elaborate");
                setUserReply("Elaborate");
              }}
              className='px-2 py-1 bg-gray-secondaryBox rounded-xl flex flex-row items-center'>
              <svg
                width='12'
                height='20'
                viewBox='0 0 12 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.47266 13.8359C6.25391 13.8359 6.69336 13.3867 6.78125 12.6738C6.78125 12.5859 6.79102 12.4883 6.79102 12.4102C6.87891 11.2871 7.31836 10.7988 8.91016 9.76367C10.6582 8.64062 11.6445 7.35156 11.6445 5.48633C11.6445 2.49805 9.21289 0.730469 5.94141 0.730469C3.51953 0.730469 1.51758 1.77539 0.667969 3.55273C0.443359 4.01172 0.345703 4.48047 0.345703 4.91016C0.345703 5.67188 0.824219 6.18945 1.64453 6.18945C2.2793 6.18945 2.76758 5.84766 2.98242 5.26172C3.45117 3.73828 4.47656 3.14258 5.81445 3.14258C7.43555 3.14258 8.68555 4.08008 8.68555 5.51562C8.68555 6.80469 7.94336 7.47852 6.54688 8.39648C5.10156 9.36328 4.0957 10.418 4.0957 12V12.4492C4.0957 13.2793 4.61328 13.8359 5.47266 13.8359ZM5.49219 19.3535C6.46875 19.3535 7.25977 18.582 7.25977 17.6348C7.25977 16.6777 6.46875 15.9258 5.49219 15.9258C4.52539 15.9258 3.73438 16.6777 3.73438 17.6348C3.73438 18.582 4.52539 19.3535 5.49219 19.3535Z'
                  fill='#333333'
                />
              </svg>
              <span className='pl-2'>Explain</span>
            </button>
            <button
              onClick={() => {
                var tempMessages = messages;
                var tempQuestions = questions;
                var tempSenders = senders;
                tempMessages.push(userReply);
                tempSenders.push("USER");
                tempQuestions.push(userReply);
                setMessages(tempMessages);
                setSenders(tempSenders);
                setQuestions(tempQuestions);
                document.getElementById("inputBar").scrollIntoView();
                ask(userReply);
                setUserReply("");
              }}
              className='px-2 py-1 bg-yellow-school rounded-xl flex flex-row items-center'>
              <svg
                width='17.2173913'
                height='16.4347826'
                viewBox='0 0 22 21'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M13.082 20.9355C13.873 20.9355 14.4102 20.3203 14.7812 19.373L21.2559 2.43945C21.4219 2.00977 21.5098 1.62891 21.5098 1.30664C21.5098 0.564453 21.041 0.0957031 20.2988 0.0957031C19.9766 0.0957031 19.5957 0.193359 19.166 0.349609L2.16406 6.86328C1.30469 7.18555 0.669922 7.73242 0.669922 8.52344C0.669922 9.48047 1.37305 9.87109 2.34961 10.1543L9.39062 12.2148L11.4316 19.1875C11.7246 20.2031 12.1152 20.9355 13.082 20.9355ZM10.0352 10.2324L4.36133 8.50391C4.23438 8.45508 4.19531 8.41602 4.19531 8.35742C4.19531 8.28906 4.22461 8.24023 4.3418 8.20117L14.9668 4.11914C15.8359 3.77734 16.6855 3.36719 17.6719 2.91797C16.9297 3.52344 16.0312 4.24609 15.4062 4.87109L10.0352 10.2324ZM13.2578 17.4297C13.1895 17.4297 13.1504 17.3711 13.1113 17.2441L11.373 11.5801L16.7344 6.19922C17.3398 5.59375 18.1113 4.65625 18.6973 3.91406C18.248 4.90039 17.8281 5.75977 17.4863 6.63867L13.4141 17.2637C13.3652 17.3809 13.3262 17.4297 13.2578 17.4297Z'
                  fill='#725300'
                />
              </svg>
              <span className='pl-2 text-yellow-schoolText'>Send</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SchoolPartial;
