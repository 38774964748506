import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useHistory } from "react-router";
import { getModelContent } from '../merlinv1/beta_api';
import Card4 from "../pages/components/Card4";
import {
    FaCodeBranch,
    FaDollarSign,
    FaRegThumbsDown,
    FaRegThumbsUp,
} from "react-icons/fa";
import { BsFillGridFill } from "react-icons/bs";
import { BsFillChatSquareQuoteFill } from "react-icons/bs";
import { BsFillFlagFill } from "react-icons/bs";
import { BsStarFill } from "react-icons/bs";
import { FaSmile } from "react-icons/fa";
import { getAnalyticsModel } from '../merlinv1/beta_api';
import { HiCursorClick } from "react-icons/hi"

const ModelAnalyticsPartial = (props) => {

    const [isPageLoading, setIsPageLoading] = useState(true)
    const [clicks, setClicks] = useState()
    const [disLikes, setDislikes] = useState()
    const [likes, setLikes] = useState()
    const [flaggs, setFlaggs] = useState()
    const [earnings, setEarnings] = useState()
    const [successRate, setSuccessRate] = useState()
    const history = useHistory()

    let searchParams = new URLSearchParams(window.location.pathname);

    const fetchData = async () => {
        try {
            var modelID
            for (let p of searchParams) {
                modelID = p[1];
                break
            }

            let modelAnalytics = await getAnalyticsModel(modelID);

            setClicks(modelAnalytics.data.clicks)
            setDislikes(modelAnalytics.data.dislikes)
            setLikes(modelAnalytics.data.likes)
            setFlaggs(modelAnalytics.data.flaggs)
            setEarnings(modelAnalytics.data.earnings)
            setSuccessRate(modelAnalytics.data.successRate)

        } catch (err) {
            history.push("/ErrorPage");
        }
        setIsPageLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, []);

    if (isPageLoading) {
        return (
            <div className="w-full h-screen flex justify-center items-center">
                <ReactLoading type="spin" color="#6a4c93" height="3%" width="3%" />
            </div>
        )
    }

    else {
        return (
            <wrapper>
                <div id={"pageRef"} className="mx-10 pt-20">
                    <div
                        className="flex flex-wrap justify-center w-full mx-auto text-white"
                        data-aos="fade-up"
                    >
                        {[
                            {
                                icon: <FaRegThumbsDown className="mb-3 font-bold" size="20px" />,
                                title: "DISLIKES",
                                text: disLikes
                            },
                            {
                                icon: <FaRegThumbsUp className="mb-3 font-bold" size="20px" />,
                                title: "LIKES",
                                text: likes
                            },
                            {
                                icon: <BsFillGridFill className="mb-3 font-bold" size="20px" />,
                                title: "LIKES TO DISLIKES RATIO",
                                text: (likes / disLikes)
                            },
                            {
                                icon: <BsFillFlagFill className="mb-3" size="20px" />,
                                title: "NUMBER OF FLAGS",
                                text: flaggs
                            },
                            {
                                icon: <BsStarFill className="mb-3" size="20px" />,
                                title: "PERCENT SUCCESS RATE",
                                text: successRate + "%"
                            },
                            {
                                icon: <HiCursorClick className="mb-3" size="20px" />,
                                title: "TOTAL CLICKS",
                                text: clicks
                            },
                            {
                                icon: <FaDollarSign className="mb-3" size="20px" />,
                                title: "AMOUNT EARNED",
                                text: earnings
                            },
                        ].map((eachCard, index) => (
                            <Card4
                                className={
                                    index - (2 % 4) !== 4 ? "w-22.5% mr-1/30 mb-5" : "w-22.5% mb-5"
                                }
                                key={index}
                            >
                                <div className="w-full p font-medium text-gray-400 justify-left pb-3 ">
                                    {" "}
                                    {eachCard.icon}
                                    {eachCard.title}{" "}
                                </div>
                                <div className="w-min h4 text-blue-pondr"> {eachCard.text}</div>
                            </Card4>
                        ))}
                    </div>
                </div>
            </wrapper>
        )
    }
}

export default ModelAnalyticsPartial;