import React, { useState, useEffect } from "react";
import firebase from "firebase";
import ReactLoading from "react-loading";
import {
  searchGalleryList,
  updateGalleryItem,
  getGalleryLikes,
} from "../merlinv1/beta_api";
import Input from "../pages/components/Input";
import Button from "../pages/components/Button";
import { searchModels, searchGallery } from "../merlinv1/beta_api";
import Dropdown from "../utils/Dropdown";
import { BiLogIn } from "react-icons/bi";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import NonFormInput from "../pages/components/NonFormInput.js";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import StackGrid from "react-stack-grid";
import InfiniteScroll from "react-infinite-scroller";

const GalleryPartial = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [titles, setTitles] = useState([]);
  const [outputs, setOutputs] = useState([]);
  const [page, setPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [likesByIndex, setLikesByIndex] = useState([]);
  const [disLikesByIndex, setDisLikesByIndex] = useState([]);
  const [userLikes, setUserLikes] = useState([]);
  const [userDislikes, setUserDislikes] = useState([]);
  const [totalOutputs, setTotalOutputs] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [ids, setIds] = useState([]);
  const history = useHistory();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  let searchParams = new URLSearchParams(window.location.pathname);

  const baseHeaderClassname =
    "text-black hover:text-blue-pondr mx-3 lg:mx-5 py-2 flex items-center transition duration-150 ease-in-out outline-none text-md";
  const currentSelectedPathname = window.location.pathname;

  const handleKeyPress = (event) => {
    if (event.key == "Enter") {
      if (event.target.value === "") {
        setHasSearched(false);
        setOutputs([]);
        setCurrentPage(0);
        fetchData();
      } else {
        setIsLoading(true);
        setHasSearched(true);
        searchGallery(event.target.value).then((res) => {
          console.log(res.data);
          setOutputs(res.data.outputs);
          setIds(res.data.ids);
          setTitles(res.data.titles);

          setIsLoading(false);
        });
      }
    }
  };

  const onCardClick = (id, type) => {
    if (type.includes("quasi_art")) {
      if (id !== "feebo") {
        history.replace({
          pathname: "/make/" + new URLSearchParams({ imagemodel: id }),
        });
      } else {
        history.replace({
          pathname:
            "/make/" +
            new URLSearchParams({
              imagemodel: "1650843350.765938A4rRtKMDewaJ59dyeIfy5hXkbVk2",
            }),
        });
      }
    } else {
      history.replace({
        pathname: "/make/" + new URLSearchParams({ model: id }),
      });
    }
  };

  const onOutputClick = async (id) => {
    history.replace({
      pathname: new URLSearchParams({ creations: id }),
    });
  };

  const loadMore = async () => {
    if (!isLoadingMore) {
      setIsLoadingMore(true);
      const res = await searchGalleryList("", page, currentPage);
      setCurrentPage(currentPage + 1);
      // setOutputs(res.data.outputs)
      // setTitles(res.data.titles)
      //instead of setting and overriding the state, we can just add the new data to the old state
      setOutputs([...outputs, ...res.data.outputs]);
      setTitles([...titles, ...res.data.titles]);
      setTotalOutputs(res.data.total);

      let likesConcat = [];
      let disLikesConcat = [];
      for (let i = 0; i < res.data.outputs.length; i++) {
        likesConcat.push(
          res.data.outputs[i].GalleryLikes != null
            ? res.data.outputs[i].GalleryLikes
            : 0
        );
        disLikesConcat.push(
          res.data.outputs[i].GalleryDislikes != null
            ? res.data.outputs[i].GalleryDislikes
            : 0
        );
      }

      setLikesByIndex([...likesByIndex, ...likesConcat]);
      setDisLikesByIndex([...disLikesByIndex, ...disLikesConcat]);

      setIsLoadingMore(false);

      if (currentPage * 50 >= totalOutputs) {
        setHasMore(false);
      }
    }
  };

  const changeGalleryItem = async (outputId, edit, index) => {
    if (
      userDislikes.indexOf(outputId) == -1 &&
      userLikes.indexOf(outputId) == -1
    ) {
      if (firebase.auth().currentUser != null) {
        //console.log(outputId)
        var res = await updateGalleryItem(outputId, edit);

        if (res.data.success) {
          if (edit == "like") {
            let newLikes = likesByIndex.slice();
            newLikes[index] = newLikes[index] + 1;
            setLikesByIndex(newLikes);

            let newUserLikes = userLikes.slice();
            newUserLikes.push(outputId);
            setUserLikes(newUserLikes);
          } else if (edit == "dislike") {
            let newDislikes = disLikesByIndex.slice();
            newDislikes[index] = newDislikes[index] + 1;
            setDisLikesByIndex(newDislikes);

            let newUserDislikes = userDislikes.slice();
            newUserDislikes.push(outputId);
            setUserDislikes(newUserDislikes);
          }
        }
      } else {
        history.push("/login");
      }
    }
  };

  const fetchData = async () => {
    try {
      var searchTerm;
      for (let p of searchParams) {
        searchTerm = p[1];
        break;
      }
      var res;
      if (searchTerm !== "") {
        res = await searchGalleryList("", page, currentPage);
        setCurrentPage(currentPage + 1);
        setSearchText(searchTerm);
      } else {
        res = await searchGalleryList("", page, currentPage);
        setCurrentPage(currentPage + 1);
      }
      setOutputs(res.data.outputs);
      setTitles(res.data.titles);
      setTotalOutputs(res.data.total);

      if (50 >= res.data.total) {
        setHasMore(false);
      }

      let likesConcat = [];
      let disLikesConcat = [];
      for (let i = 0; i < res.data.outputs.length; i++) {
        likesConcat.push(
          res.data.outputs[i].GalleryLikes != null
            ? res.data.outputs[i].GalleryLikes
            : 0
        );
        disLikesConcat.push(
          res.data.outputs[i].GalleryDislikes != null
            ? res.data.outputs[i].GalleryDislikes
            : 0
        );
      }

      setLikesByIndex(likesConcat);
      setDisLikesByIndex(disLikesConcat);

      var likes;

      //check if user is logged in
      if (firebase.auth().currentUser != null) {
        likes = await getGalleryLikes();
        setUserLikes(likes.data.likes);
        setUserDislikes(likes.data.dislikes);
      }
    } catch (err) {
      history.push("/ErrorPage");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <section>
        <div
          className='lg:px-10 md:px-2 sm:px-2 py-24'
          style={{ overflow: "auto" }}>
          <div className='sm:text-center md:text-left font-bold leading-tighter tracking-tighter mb-4 pb-5 sm:ml-0 lg:ml-24 flex flex-row items-center'>
            <span className='text-semibold text-5xl pr-6'>Gallery</span>

            <div className='relative text-gray-600 focus-within:text-gray-400'>
              <span className='absolute md:inset-y-0 sm:top-0 md:pt-0 sm:pt-2 left-0 flex md:justify-center sm:justify-end pl-6'>
                <button
                  type='submit'
                  className='focus:outline-none focus:shadow-outline'>
                  <svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    viewBox='0 0 24 24'
                    className='w-6 h-6'>
                    <path d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'></path>
                  </svg>
                </button>
              </span>
              <input
                className='px-16 py-2 w-full text-gray-800 bg-gray-secondaryBox border-opacity-0 focus:border-opacity-0 rounded-3xl'
                placeholder='Search Gallery'
                type='text'
                value={searchText}
                onKeyDown={(e) => handleKeyPress(e)}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className='w-full h-screen flex justify-center items-center'>
            <ReactLoading type='spin' color='#6a4c93' height='3%' width='3%' />
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section>
        <div
          className='lg:px-10 md:px-2 sm:px-2 py-12'
          style={{ overflow: "auto" }}>
          <div className='sm:text-center md:text-left font-bold leading-tighter tracking-tighter mb-4 pb-5 sm:ml-0 lg:ml-24 flex flex-row items-center'>
            <span className='text-semibold text-5xl pr-6'>Gallery</span>

            <div className='relative text-gray-600 focus-within:text-gray-400'>
              <span className='absolute md:inset-y-0 sm:top-0 md:pt-0 sm:pt-2 left-0 flex md:justify-center sm:justify-end pl-6'>
                <button
                  type='submit'
                  className='focus:outline-none focus:shadow-outline'>
                  <svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    viewBox='0 0 24 24'
                    className='w-6 h-6'>
                    <path d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'></path>
                  </svg>
                </button>
              </span>
              <input
                className='px-16 py-2 w-full text-gray-800 bg-gray-secondaryBox border-opacity-0 focus:border-opacity-0 rounded-3xl'
                placeholder='Search Gallery'
                type='text'
                value={searchText}
                onKeyDown={(e) => handleKeyPress(e)}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>

          {hasSearched ? (
            <div>
              {outputs.length > 0 ? (
                <StackGrid
                  columnWidth={300}
                  gutterWidth={10}
                  gutterHeight={10}
                  monitorImagesLoaded={true}
                  // this durasticly improves the performance of the gallery
                  duration={0}>
                  {outputs.map((output, index) => (
                    <div
                      className='group overflow-hidden bg-gray-tertiaryBox hover:bg-gray-secondaryHoverBox rounded-2xl'
                      key={index}>
                      {!output.Output.includes("quasi_art") ? (
                        <div className='group relative'>
                          {output.Input3 !== "" ? (
                            <div
                              className='px-6 py-6'
                              onClick={() => {
                                onOutputClick(output.Output_id);
                              }}>
                              <div className='text-gray-700 text-sm text-base mb-2'>
                                {titles[index]}
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input1},
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input2},
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input3}
                              </div>
                              <p className='bg-clip-text font-medium text-transparent bg-red-dev'>
                                <div className=''>{output.Output.trim()}</div>
                              </p>
                            </div>
                          ) : output.Input2 !== "" ? (
                            <div
                              className='px-6 py-6'
                              onClick={() => {
                                onOutputClick(output.Output_id);
                              }}>
                              <div className='text-gray-700 text-sm text-base mb-2'>
                                {titles[index]}
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input1},
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input2}
                              </div>
                              <p className='bg-clip-text font-medium text-transparent bg-purple-mainText'>
                                <div className=''>{output.Output.trim()}</div>
                              </p>
                            </div>
                          ) : output.Input1 !== "" ? (
                            <div
                              className='px-6 py-6'
                              onClick={() => {
                                onOutputClick(output.Output_id);
                              }}>
                              <div className='text-gray-700 text-sm text-base mb-2'>
                                {titles[index]}
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input1}
                              </div>
                              <p className='bg-clip-text font-medium text-transparent bg-green-mainText'>
                                <div className=''>{output.Output.trim()}</div>
                              </p>
                            </div>
                          ) : (
                            <div
                              className='px-6 py-6'
                              onClick={() => {
                                onOutputClick(output.Output_id);
                              }}>
                              <div className='text-gray-700 text-sm text-base mb-2'>
                                {titles[index]}
                              </div>
                              <p className='bg-clip-text font-medium text-transparent bg-blue-mainText'>
                                <div className=''>{output.Output.trim()}</div>
                              </p>
                            </div>
                          )}
                          <div className='opacity-0 group-hover:opacity-100 absolute inset-x-0 bottom-0 flex flex-row justify-between'>
                            <button
                              className={
                                "btn-sm font-medium text-black p-2 rounded-squircle bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox m-2"
                              }
                              onClick={() =>
                                onCardClick(output.Model_id, output.Output)
                              }>
                              <svg
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M15.4707 11.5723L15.4609 1.77734C15.4609 1.01562 14.9531 0.498047 14.1816 0.498047H4.37695C3.6543 0.498047 3.12695 1.04492 3.12695 1.69922C3.12695 2.34375 3.68359 2.87109 4.35742 2.87109H7.90234L11.6816 2.73438L9.93359 4.28711L0.919922 13.3105C0.666016 13.5645 0.519531 13.8867 0.519531 14.1992C0.519531 14.834 1.10547 15.4395 1.75977 15.4395C2.08203 15.4395 2.38477 15.3027 2.64844 15.0391L11.6719 6.02539L13.2344 4.27734L13.0781 7.91016V11.5918C13.0781 12.2754 13.6055 12.832 14.2598 12.832C14.9141 12.832 15.4707 12.2852 15.4707 11.5723Z'
                                  fill='black'
                                />
                              </svg>

                              <span className='pl-2'>Try Model</span>
                              <path
                                d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z'
                                fillRule='nonzero'
                              />
                            </button>
                            <div>
                              <button
                                class='text-black p-2 rounded-squircle bg-green-primaryBox hover:bg-green-hoverBox m-2'
                                onClick={async () => {
                                  changeGalleryItem(output.id, "like", index);
                                }}>
                                <div class='flex inline-block'>
                                  <div class='flex flex-col justify-center'>
                                    <FaRegThumbsUp
                                      style={{
                                        color: userLikes.includes(output.id)
                                          ? "green"
                                          : "black",
                                      }}
                                    />
                                  </div>
                                  <div className='pl-2'></div>
                                  <div className='flex flex-col justify-center'>
                                    <span className='text-green-primaryText group-hover:text-green-hoverText'>
                                      {likesByIndex[index]}
                                    </span>
                                  </div>
                                </div>
                              </button>
                              <button
                                className='text-black p-2 rounded-squircle bg-red-primaryBox hover:bg-red-hoverBox m-2'
                                onClick={async () => {
                                  changeGalleryItem(
                                    output.id,
                                    "dislike",
                                    index
                                  );
                                }}>
                                <div className='flex inline-block'>
                                  <div className='flex flex-col justify-center'>
                                    <FaRegThumbsDown
                                      style={{
                                        color: userDislikes.includes(output.id)
                                          ? "red"
                                          : "black",
                                      }}
                                    />
                                  </div>
                                  <div className='pl-2'></div>
                                  <div className='flex flex-col justify-center'>
                                    <span className='text-red-primaryText group-hover:text-red-hoverText'>
                                      {disLikesByIndex[index]}
                                    </span>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='group relative'>
                          <img
                            className='rounded-2xl darken-hover'
                            id={output.Output_id}
                            src={output.Output.trim()}
                            onClick={() => {
                              console.log(output);
                              onOutputClick(output.Output_id);
                            }}></img>
                          <div
                            className='pl-4 font-bold text-gray-secondaryBox opacity-0 group-hover:opacity-100 absolute inset-x-0 top-0 pr-4 text-xl mb-2 mt-4 cursor-pointer '
                            onClick={() => {
                              console.log(output);
                              onOutputClick(output.Output_id);
                            }}
                            onMouseOver={() => {
                              console.log(output.Output_id);
                              document.getElementById(
                                output.Output_id
                              ).className += " darken-not-hover";
                            }}
                            onMouseLeave={() => {
                              document.getElementById(
                                output.Output_id
                              ).className = "rounded-2xl darken-hover";
                            }}>
                            {output.Input1}
                          </div>
                          <div className='opacity-0 group-hover:opacity-100 absolute inset-x-0 bottom-0 flex flex-row justify-between'>
                            <button
                              to='/create'
                              className={
                                "btn-sm font-medium text-black p-2 rounded-squircle bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox m-2"
                              }
                              onClick={() =>
                                onCardClick(output.Model_id, output.Output)
                              }>
                              <svg
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M15.4707 11.5723L15.4609 1.77734C15.4609 1.01562 14.9531 0.498047 14.1816 0.498047H4.37695C3.6543 0.498047 3.12695 1.04492 3.12695 1.69922C3.12695 2.34375 3.68359 2.87109 4.35742 2.87109H7.90234L11.6816 2.73438L9.93359 4.28711L0.919922 13.3105C0.666016 13.5645 0.519531 13.8867 0.519531 14.1992C0.519531 14.834 1.10547 15.4395 1.75977 15.4395C2.08203 15.4395 2.38477 15.3027 2.64844 15.0391L11.6719 6.02539L13.2344 4.27734L13.0781 7.91016V11.5918C13.0781 12.2754 13.6055 12.832 14.2598 12.832C14.9141 12.832 15.4707 12.2852 15.4707 11.5723Z'
                                  fill='black'
                                />
                              </svg>

                              <span className='pl-2'>Try Model</span>
                            </button>
                            <div>
                              <button
                                className='text-black p-2 rounded-squircle bg-green-primaryBox hover:bg-green-hoverBox m-2'
                                onClick={async () => {
                                  changeGalleryItem(output.id, "like", index);
                                }}>
                                <div className='flex inline-block'>
                                  <div className='flex flex-col justify-center'>
                                    <FaRegThumbsUp
                                      style={{
                                        color: userLikes.includes(output.id)
                                          ? "green"
                                          : "black",
                                      }}
                                    />
                                  </div>
                                  <div className='pl-2'></div>
                                  <div className='flex flex-col justify-center'>
                                    <span className='text-green-primaryText group-hover:text-green-hoverText'>
                                      {likesByIndex[index]}
                                    </span>
                                  </div>
                                </div>
                              </button>
                              <button
                                className='text-black p-2 rounded-squircle bg-red-primaryBox hover:bg-red-hoverBox m-2'
                                onClick={async () => {
                                  changeGalleryItem(
                                    output.id,
                                    "dislike",
                                    index
                                  );
                                }}>
                                <div className='flex inline-block'>
                                  <div className='flex flex-col justify-center'>
                                    <FaRegThumbsDown
                                      style={{
                                        color: userDislikes.includes(output.id)
                                          ? "red"
                                          : "black",
                                      }}
                                    />
                                  </div>
                                  <div className='pl-2'></div>
                                  <div className='flex flex-col justify-center'>
                                    <span className='text-red-primaryText group-hover:text-red-hoverText'>
                                      {disLikesByIndex[index]}
                                    </span>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </StackGrid>
              ) : (
                <div>
                  <div className='font-bold text-xl mb-2 text-center pt-6'>
                    No Creations Match Your Search
                  </div>
                  <div className='text-center'>
                    <Link
                      to='/explore'
                      className='btn-sm font-bold pl-8 pr-8 text-green-primaryText bg-green-primaryBox hover:bg-green-hoverBox hover:text-green-hoverText ml-3 focus:outline-none rounded-squircle'>
                      Make Your Own!
                      <path
                        d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z'
                        fillRule='nonzero'
                      />
                    </Link>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={hasMore}
              loader={
                <div className='w-full  flex justify-center items-center'>
                  <ReactLoading
                    type='spin'
                    color='#6a4c93'
                    height='3%'
                    width='3%'
                  />
                </div>
              }
              threshold={0}>
              {outputs.length > 0 ? (
                <StackGrid
                  columnWidth={300}
                  gutterWidth={10}
                  gutterHeight={10}
                  monitorImagesLoaded={true}
                  // this durasticly improves the performance of the gallery
                  duration={0}>
                  {outputs.map((output, index) => (
                    <div
                      className='group overflow-hidden bg-gray-tertiaryBox hover:bg-gray-secondaryHoverBox rounded-2xl'
                      key={index}>
                      {!output.Output.includes("quasi_art") ? (
                        <div className='group relative'>
                          {output.Input3 !== "" ? (
                            <div
                              className='px-6 py-6'
                              onClick={() => {
                                onOutputClick(output.Output_id);
                              }}>
                              <div className='text-gray-700 text-sm text-base mb-2'>
                                {titles[index]}
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input1},
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input2},
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input3}
                              </div>
                              <p className='bg-clip-text font-medium text-transparent bg-red-dev'>
                                <div className=''>{output.Output.trim()}</div>
                              </p>
                            </div>
                          ) : output.Input2 !== "" ? (
                            <div
                              className='px-6 py-6'
                              onClick={() => {
                                onOutputClick(output.Output_id);
                              }}>
                              <div className='text-gray-700 text-sm text-base mb-2'>
                                {titles[index]}
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input1},
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input2}
                              </div>
                              <p className='bg-clip-text font-medium text-transparent bg-purple-mainText'>
                                <div className=''>{output.Output.trim()}</div>
                              </p>
                            </div>
                          ) : output.Input1 !== "" ? (
                            <div
                              className='px-6 py-6'
                              onClick={() => {
                                onOutputClick(output.Output_id);
                              }}>
                              <div className='text-gray-700 text-sm text-base mb-2'>
                                {titles[index]}
                              </div>
                              <div className='font-bold text-xl mb-2'>
                                {output.Input1}
                              </div>
                              <p className='bg-clip-text font-medium text-transparent bg-green-mainText'>
                                <div className=''>{output.Output.trim()}</div>
                              </p>
                            </div>
                          ) : (
                            <div
                              className='px-6 py-6'
                              onClick={() => {
                                onOutputClick(output.Output_id);
                              }}>
                              <div className='text-gray-700 text-sm text-base mb-2'>
                                {titles[index]}
                              </div>
                              <p className='bg-clip-text font-medium text-transparent bg-blue-mainText'>
                                <div className=''>{output.Output.trim()}</div>
                              </p>
                            </div>
                          )}
                          <div className='opacity-0 group-hover:opacity-100 absolute inset-x-0 bottom-0 flex flex-row justify-between'>
                            <button
                              className={
                                "btn-sm font-medium text-black p-2 rounded-squircle bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox m-2"
                              }
                              onClick={() =>
                                onCardClick(output.Model_id, output.Output)
                              }>
                              <svg
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M15.4707 11.5723L15.4609 1.77734C15.4609 1.01562 14.9531 0.498047 14.1816 0.498047H4.37695C3.6543 0.498047 3.12695 1.04492 3.12695 1.69922C3.12695 2.34375 3.68359 2.87109 4.35742 2.87109H7.90234L11.6816 2.73438L9.93359 4.28711L0.919922 13.3105C0.666016 13.5645 0.519531 13.8867 0.519531 14.1992C0.519531 14.834 1.10547 15.4395 1.75977 15.4395C2.08203 15.4395 2.38477 15.3027 2.64844 15.0391L11.6719 6.02539L13.2344 4.27734L13.0781 7.91016V11.5918C13.0781 12.2754 13.6055 12.832 14.2598 12.832C14.9141 12.832 15.4707 12.2852 15.4707 11.5723Z'
                                  fill='black'
                                />
                              </svg>

                              <span className='pl-2'>Try Model</span>
                              <path
                                d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z'
                                fillRule='nonzero'
                              />
                            </button>
                            <div>
                              <button
                                class='text-black p-2 rounded-squircle bg-green-primaryBox hover:bg-green-hoverBox m-2'
                                onClick={async () => {
                                  changeGalleryItem(output.id, "like", index);
                                }}>
                                <div class='flex inline-block'>
                                  <div class='flex flex-col justify-center'>
                                    <FaRegThumbsUp
                                      style={{
                                        color: userLikes.includes(output.id)
                                          ? "green"
                                          : "black",
                                      }}
                                    />
                                  </div>
                                  <div className='pl-2'></div>
                                  <div className='flex flex-col justify-center'>
                                    <span className='text-green-primaryText group-hover:text-green-hoverText'>
                                      {likesByIndex[index]}
                                    </span>
                                  </div>
                                </div>
                              </button>
                              <button
                                className='text-black p-2 rounded-squircle bg-red-primaryBox hover:bg-red-hoverBox m-2'
                                onClick={async () => {
                                  changeGalleryItem(
                                    output.id,
                                    "dislike",
                                    index
                                  );
                                }}>
                                <div className='flex inline-block'>
                                  <div className='flex flex-col justify-center'>
                                    <FaRegThumbsDown
                                      style={{
                                        color: userDislikes.includes(output.id)
                                          ? "red"
                                          : "black",
                                      }}
                                    />
                                  </div>
                                  <div className='pl-2'></div>
                                  <div className='flex flex-col justify-center'>
                                    <span className='text-red-primaryText group-hover:text-red-hoverText'>
                                      {disLikesByIndex[index]}
                                    </span>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='group relative'>
                          <img
                            className='rounded-2xl darken-hover'
                            id={output.Output_id}
                            src={output.Output.trim()}
                            onClick={() => {
                              console.log(output);
                              onOutputClick(output.Output_id);
                            }}></img>
                          <div
                            className='pl-4 font-bold text-gray-secondaryBox opacity-0 group-hover:opacity-100 absolute inset-x-0 top-0 pr-4 font-bold text-xl mb-2 mt-4 cursor-pointer '
                            onClick={() => {
                              console.log(output);
                              onOutputClick(output.Output_id);
                            }}
                            onMouseOver={() => {
                              console.log(output.Output_id);
                              document.getElementById(
                                output.Output_id
                              ).className += " darken-not-hover";
                            }}
                            onMouseLeave={() => {
                              document.getElementById(
                                output.Output_id
                              ).className = "rounded-2xl darken-hover";
                            }}>
                            {output.Input1}
                          </div>
                          <div className='opacity-0 group-hover:opacity-100 absolute inset-x-0 bottom-0 flex flex-row justify-between'>
                            <button
                              to='/create'
                              className={
                                "btn-sm font-medium text-black p-2 rounded-squircle bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox m-2"
                              }
                              onClick={() =>
                                onCardClick(output.Model_id, output.Output)
                              }>
                              <svg
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M15.4707 11.5723L15.4609 1.77734C15.4609 1.01562 14.9531 0.498047 14.1816 0.498047H4.37695C3.6543 0.498047 3.12695 1.04492 3.12695 1.69922C3.12695 2.34375 3.68359 2.87109 4.35742 2.87109H7.90234L11.6816 2.73438L9.93359 4.28711L0.919922 13.3105C0.666016 13.5645 0.519531 13.8867 0.519531 14.1992C0.519531 14.834 1.10547 15.4395 1.75977 15.4395C2.08203 15.4395 2.38477 15.3027 2.64844 15.0391L11.6719 6.02539L13.2344 4.27734L13.0781 7.91016V11.5918C13.0781 12.2754 13.6055 12.832 14.2598 12.832C14.9141 12.832 15.4707 12.2852 15.4707 11.5723Z'
                                  fill='black'
                                />
                              </svg>

                              <span className='pl-2'>Try Model</span>
                            </button>
                            <div>
                              <button
                                className='text-black p-2 rounded-squircle bg-green-primaryBox hover:bg-green-hoverBox m-2'
                                onClick={async () => {
                                  changeGalleryItem(output.id, "like", index);
                                }}>
                                <div className='flex inline-block'>
                                  <div className='flex flex-col justify-center'>
                                    <FaRegThumbsUp
                                      style={{
                                        color: userLikes.includes(output.id)
                                          ? "green"
                                          : "black",
                                      }}
                                    />
                                  </div>
                                  <div className='pl-2'></div>
                                  <div className='flex flex-col justify-center'>
                                    <span className='text-green-primaryText group-hover:text-green-hoverText'>
                                      {likesByIndex[index]}
                                    </span>
                                  </div>
                                </div>
                              </button>
                              <button
                                className='text-black p-2 rounded-squircle bg-red-primaryBox hover:bg-red-hoverBox m-2'
                                onClick={async () => {
                                  changeGalleryItem(
                                    output.id,
                                    "dislike",
                                    index
                                  );
                                }}>
                                <div className='flex inline-block'>
                                  <div className='flex flex-col justify-center'>
                                    <FaRegThumbsDown
                                      style={{
                                        color: userDislikes.includes(output.id)
                                          ? "red"
                                          : "black",
                                      }}
                                    />
                                  </div>
                                  <div className='pl-2'></div>
                                  <div className='flex flex-col justify-center'>
                                    <span className='text-red-primaryText group-hover:text-red-hoverText'>
                                      {disLikesByIndex[index]}
                                    </span>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </StackGrid>
              ) : (
                <div>
                  <div className='font-bold text-xl mb-2 text-center pt-6'>
                    No Creations Match Your Search
                  </div>
                  <div className='text-center'>
                    <Link
                      to='/explore'
                      className='btn-sm font-bold pl-8 pr-8 text-green-primaryText bg-green-primaryBox hover:bg-green-hoverBox hover:text-green-hoverText ml-3 focus:outline-none rounded-squircle'>
                      Make Your Own!
                      <path
                        d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z'
                        fillRule='nonzero'
                      />
                    </Link>
                  </div>
                </div>
              )}
            </InfiniteScroll>
          )}
        </div>
      </section>
    );
  }
});

//bg-gradient-to-r from-green-500 to-yellow-400

export default GalleryPartial;
