import React, { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import NewDevelopersPartial from "../partials/NewDevelopersPartial";
import MetaTags from "react-meta-tags";

function NewDevelopers() {
    // Sets screen analytics
    useEffect(() => {
        window.scroll(0, 0);
        logScreenName("BlogPost1");
        logEvent("page_view", { page_name: "BlogPost1" });
    }, []);

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            <MetaTags>
                <title>New Developers</title>
                <meta
                    name="description"
                    content="Quasi | New Dev Page"
                />
                <meta property="og:title" content="Quasi | New Developers" />
            </MetaTags>
            <meta name="viewport" content="width=device-width, initial-scale=1" />

            {/*  Site header */}
            <Header />

            {/*  Page content */}
            <main className="flex-grow">
                {/*  Page sections */}
                <NewDevelopersPartial />
            </main>

            {/*  Site footer */}
            <Footer />
        </div>
    );
}

export default NewDevelopers;