import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./css/style.scss";

import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";
import { getUserHeader } from "./merlinv1/beta_api";
// Landing pages:
import Home from "./pages/Home";
import Create from "./pages/Create";
import Developers from "./pages/Developers";
import DeveloperQPI from "./pages/DeveloperQPI";
import BetaProgram from "./pages/BetaProgram";
import NewDevelopers from "./pages/NewDevelopers";
import UnderConstruction from "./pages/UnderConstruction";
import ContactUs from "./pages/ContactUs";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import SignIn from "./pages/SignIn";
import ResetPassword from "./pages/ResetPassword";
import PageNotFound from "./pages/PageNotFound";
import Product from "./pages/Product";
import MyModels from "./pages/MyModels";
import MyCreations from "./pages/MyCreations";
import ModelPage from "./pages/ModelPage";
import ImageModelPage from "./pages/ImageModelPage";
import RequestModel from "./pages/RequestModel";
import ConfirmPaymentOut from "./pages/ConfirmPaymentOut";
import ModelAnalytics from "./pages/ModelAnalytics";
import Gallery from "./pages/Gallery";
import Creations from "./pages/Creations";
import Radio from "./pages/Radio";
import BountyList from "./pages/BountyList";
import School from "./pages/School";
import AddBounty from "./pages/AddBounty";
import Welcome from "./pages/Welcome";
import Walkthrough from "./pages/Walkthrough";
import Fork from "./pages/Fork";
import Sandbox from "./pages/Sandbox";
import Business from "./pages/Business";
import BusinessContact from "./pages/BusinessContact";

// User signed in components:
import SubmitBounty from "./pages/SubmitBounty";
import ProtectedRoute from "./partials/protectedRoute";
import UnprotectedRoute from "./partials/UnprotectedRoute";
import UserSignUp from "./pages/UserSignUp";
import AddModel from "./pages/AddModel";
import MakeImageModel from "./pages/MakeImageModel";
import Wrapper from "./utils/Wrapper";
import Checkout from "./pages/Checkout/CheckoutSession";
import Shop from "./pages/Shop";
import Feed from "./pages/Feed";
import Badges from "./pages/Badges";
import Referrals from "./pages/Referrals";
import MakeFlow from "./pages/MakeFlow";

// Required StyleSheets
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import firebase from "firebase";
import "firebase/auth";
import EditModelPartial from "./partials/EditModelPartial";
import EditImageModelPartial from "./partials/EditImageModelPartial";
import ConvertEarnings from "./pages/ConvertEarnings";
import WorkflowContent from "./pages/WorkflowContent";
import EditFlow from "./pages/EditFlow";

const App = (props) => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
    });
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, []);
  return (
    <Switch>
      <Route exact path='/Home' render={() => <Redirect to={"/"} />} />
      <Route exact path='/admin/bounty/lalhisbvilasbvilbavlksbibbbrgb'>
        <AddBounty />
      </Route>
      <Route
        exact
        path='/admin/stability-fork/wefighsiosehgwoienvoianssdoihtwe'>
        <Fork />
      </Route>
      <Route exact path='/'>
        <Home />
      </Route>
      <Route exact path='/create*'>
        <Create />
      </Route>
      <Route exact path='/creations*'>
        <Creations />
      </Route>
      <Route exact path='/developers'>
        <Developers />
      </Route>
      <Route exact path='/developers/qpi'>
        <DeveloperQPI />
      </Route>
      <Route exact path='/developers/beta'>
        <BetaProgram />
      </Route>
      <Route exact path='/developers/new'>
        <NewDevelopers />
      </Route>
      <Route exact path='/shop'>
        <Shop />
      </Route>
      <Route exact path='/make/model*'>
        <ModelPage />
      </Route>
      <Route exact path='/make/imagemodel*'>
        <ImageModelPage />
      </Route>
      <Route exact path='/about'>
        <About />
      </Route>
      <Route exact path='/privacy-policy'>
        <PrivacyPolicy />
      </Route>
      <Route exact path='/terms'>
        <TermsAndConditions />
      </Route>
      <Route exact path='/reset-password'>
        <ResetPassword />
      </Route>
      <Route exact path='/user/create'>
        <UserSignUp />
      </Route>
      <UnprotectedRoute exact path='/login'>
        <SignIn />
      </UnprotectedRoute>
      <Route exact path='/signup*'>
        <UserSignUp />
      </Route>
      <Route exact path='/gallery'>
        <Gallery />
      </Route>
      <Route exact path='/radio*'>
        <Radio />
      </Route>
      <Route exact path='/bounty'>
        <BountyList />
      </Route>
      <Route exact path='/welcome'>
        <Welcome />
      </Route>
      <Route exact path='/walkthrough'>
        <Walkthrough />
      </Route>
      <Route exact path='/make/workflow*'>
        <WorkflowContent />
      </Route>
      <Route exact path='/business'>
        <Business />
      </Route>
      <Route exact path='/business-contact'>
        <BusinessContact />
      </Route>

      {/* Protected routes are enables only when user signs in */}
      <ProtectedRoute exact path='/sandbox'>
        <Sandbox />
      </ProtectedRoute>

      <ProtectedRoute exact path='/school'>
        <School />
      </ProtectedRoute>

      <ProtectedRoute exact path='/bounty/submit'>
        <SubmitBounty />
      </ProtectedRoute>

      <ProtectedRoute exact path='/request-model'>
        <RequestModel />
      </ProtectedRoute>

      <ProtectedRoute exact path='/analytics/model*'>
        <ModelAnalytics />
      </ProtectedRoute>

      <ProtectedRoute exact path='/edit/model*'>
        <EditModelPartial />
      </ProtectedRoute>

      <ProtectedRoute exact path='/edit/imagemodel*'>
        <EditImageModelPartial />
      </ProtectedRoute>

      <ProtectedRoute path='/user/dashboard/'>
        <Product />
      </ProtectedRoute>

      <ProtectedRoute exact path='/user/create-product*'>
        <AddModel />
      </ProtectedRoute>

      <ProtectedRoute exact path='/user/feed'>
        <Feed />
      </ProtectedRoute>

      <ProtectedRoute exact path='/user/badges'>
        <Badges />
      </ProtectedRoute>

      <ProtectedRoute exact path='/referrals'>
        <Referrals />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path='/user/create-image-modelwieohgwoiehtpiwheogipwheopig'>
        <MakeImageModel />
      </ProtectedRoute>

      <ProtectedRoute exact path='/user/my-models'>
        <MyModels />
      </ProtectedRoute>

      <ProtectedRoute exact path='/user/my-creations'>
        <MyCreations />
      </ProtectedRoute>

      <ProtectedRoute path='/checkout'>
        <Checkout />
      </ProtectedRoute>

      <ProtectedRoute path='/confirm-pay-out/'>
        <ConfirmPaymentOut />
      </ProtectedRoute>

      <ProtectedRoute path='/convert-earnings/'>
        <ConvertEarnings />
      </ProtectedRoute>

      <ProtectedRoute path='/user/make-workflow'>
        <MakeFlow />
      </ProtectedRoute>
      <ProtectedRoute path='/edit/workflow*'>
        <EditFlow />
      </ProtectedRoute>

      <Route path='*'>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default App;
