import React from 'react';

function Terms() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
            <h1 className="h1 mb-4">TERMS OF USE</h1>
            <p className="text-lg text-gray-600 mb-8">
              Last updated: March 1st, 2021
            </p>
            <p className="text-lg text-gray-600 mb-8">
              # **Overview**

              Please read these Terms of Use ("Terms of Use", "Terms", "Terms and Conditions") carefully before using the Application Services operated by Quasi Ventures Inc. ("us", "we", or "our"). The "**Application Services**" (the "Service") shall mean the website and programming interfaces under 'Quasi'. A "**Subscription(s)**" shall mean a specified pricing plan and term length with associated features, functionality and volume tiers for the Application Services purchased by Customer.

              Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Service.

              By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not have permission to access the Service.

              # Purchase

              The usage of the website is billed on a credit pack basis. You will be billed per bundle ("Billing Cycle"). Billing cycles are set by the customer.

              At the end of each Billing Cycle, your credit count will not renew until another purchase is made. A valid payment method, including credit card, is required to process the payment for your bundle.

              # **Free Trial**

              Quasi offers every credit bundle with a free trial for 100 Credits ("Free Trial"), which can only be availed once.

              You may be required to enter your billing information in order to sign up for the Free Trial.

              If you do enter your billing information when signing up for the Free Trial, you will not be charged by until the Free Trial has expired. On the last day of the Free Trial period, you will need to purchase additional credits with the applicable fees for the type of bundle you have selected.

              At any time and without notice, Quasi reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.

              # **Refunds**

              Quasi has a no refund policy for all transactions. We will however evaluate disputes on a case-by-case basis.

              # **Intellectual Property**

              The Service and its original content, features and functionality are and will remain the exclusive property of Quasi and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Quasi.

              # **Copyright Infringement**

              We do not allow any content that infringes copyright. The use of copyrighted content belonging to others without proper authorization or legally valid reason may lead to a violation of Quasi’s policies.

              At the same time, not all uses of copyrighted content constitute an infringement. In many countries, exceptions to copyright infringement allow the use of these works under certain circumstances without authorization. These include the fair use doctrine in the United States, and permitted acts of fair dealing in the European Union (and other equivalent exceptions under applicable local laws in other countries).

              If you believe your content is being infringed upon, please submit a claim to **quasiven@gmail.com** and we will respond within 5 Business Days.

              # **Links To Other Web Sites**

              Our Service may contain links to third party web sites or services that are not owned or controlled by Quasi.

              Quasi has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.

              You acknowledge and agree that Quasi shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third party web sites or services.

              We strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services that you visit.

              # **Indemnification**

              You agree to defend, indemnify and hold harmless Quasi and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password, or b) a breach of these Terms.

              Quasi shall hold Customer harmless from liability to third parties resulting from infringement by the Service of any United States patent or any copyright or misappropriation of any trade secret, provided Quasi is promptly notified of any and all threats, claims and proceedings related thereto and given reasonable assistance and the opportunity to assume sole control over defense and settlement; Quasi will not be responsible for any settlement it does not approve in writing. The foregoing obligations do not apply with respect to portions or components of the Service (i) not supplied by Quasi, (ii) made in whole or in part in accordance with Customer specifications, (iii) that are modified after delivery, (iv) combined with other products, processes or materials where the alleged infringement relates to such combination, (v) where Customer continues allegedly infringing activity after being notified thereof or after being informed of modifications that would have avoided the alleged infringement, or (vi) where Customer’s use of the Service is not strictly in accordance with this Agreement. If, due to a claim of infringement, the Services are held by a court of competent jurisdiction to be or are believed by Quasi to be infringing, Quasi may, at its option and expense (a) replace or modify the Service to be non-infringing provided that such modification or replacement contains substantially similar features and functionality, (b) obtain for Customer a license to continue using the Service, or (c) if neither of the foregoing is commercially practicable, terminate this Agreement and Customer’s rights hereunder.

              # **Limitation Of Liability**

              In no event shall Quasi nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.

              # **Disclaimer**

              Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.

              Quasi, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.

              # **Exclusions**

              Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.

              # **Governing Law**

              These Terms shall be governed and construed in accordance with the laws of Indiana, United States, without regard to its conflict of law provisions.

              Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.

              # **Changes**

              We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will not provide notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

              By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.

            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Terms;