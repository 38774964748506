import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { logEvent, logFBEvent, logScreenName } from "../utils/CommonFunctions";
import Wrapper from "../utils/Wrapper";
import { signIn } from "../store/actions/authActions";
import { enterpriseCreate, logFirestoreEvent } from "../merlinv1/beta_api";
import { useHistory, withRouter } from "react-router";
import { GiCancel } from "react-icons/gi";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

const CompanySignUpFormSchema = Yup.object({
  firstname: Yup.string().required(
    "Person of contact's first name is required"
  ),
  lastname: Yup.string().required("Person of contact's last name is required"),
  phoneNum: Yup.string()
    //.required("Phone Number is required")
    //.matches(/^[0-9]+$/, "Must be only digits"),
    .matches(/^[0-9]*$/, "Must be only digits"),
  email: Yup.string().email("Invalid Email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  passwordConfirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
  companyName: Yup.string()
    .required("Username is required")
    .max(100, "Username cannot be more than 100 letters"),
  outreachType: Yup.string().required("This field is required"),
});

const CompanySignUp = (props) => {
  const history = useHistory();

  // State for the screen
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [referralID, setReferralID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  let searchParams = new URLSearchParams(window.location.pathname);

  useEffect(() => {
    logFirestoreEvent("UserSignUpView", {});
    var tempRefferalID;
    for (let p of searchParams) {
      tempRefferalID = p[1];
      setReferralID(tempRefferalID);
      break;
    }
  }, []);

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(CompanySignUpFormSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      phoneNum: "",
      email: "",
      password: "",
      passwordConfirm: "",
      companyName: "",
      outreachType: "",
    },
  });

  const onSubmit = async (data) => {
    // Creates the company, signs in, then navigates to the correct screen
    try {
      setIsLoading(true);

      var res;

      res = await enterpriseCreate(
        data.companyName,
        data.phoneNum,
        data.email,
        data.password,
        data.firstname,
        data.lastname,
        data.outreachType,
        referralID
      );

      const responseData = res.data;

      if (responseData.success) {
        await props.signIn(data);
        logFirestoreEvent("CompanySignUpSuccess", {
          companyName: data.companyName,
        });
        //logFBEvent("CompanySignUp", {});
        setTimeout(() => {
          history.push("/welcome");
          setIsLoading(false);
        }, 1000);
      } else if (responseData.includes("PHONE_NUMBER_EXISTS")) {
        setErrorMessage("This phone number is already linked to an account.");
        setErrorModal(true);
        setIsLoading(false);
      } else if (responseData.includes("EMAIL_EXISTS")) {
        setErrorMessage("This email address is already linked to an account.");
        setErrorModal(true);
        setIsLoading(false);
      } else if (responseData.includes("An Error Occured")) {
        logFirestoreEvent("404Error", { err: responseData });
        setIsLoading(false);
        history.push("/ErrorPage");
      }
    } catch (err) {
      logFirestoreEvent("404Error", { err: err });
      history.push("/ErrorPage");
    }
  };

  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
    logScreenName("CompanySignUp");
    logEvent("page_view", { page_name: "CompanySignUp" });
  }, []);

  return (
    <Wrapper>
      <MetaTags>
        <title>Sign Up</title>
        <meta name='description' content='Quasi | Sign Up Page' />
        <meta property='og:title' content='Quasi | Sign Up' />
      </MetaTags>
      <p className='w-full md:text-5xl sm:text-3xl font-bold text-black'>
        Create Your Account{" "}
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='rounded-3xl p-6 bg-gray-tertiaryBox mb-5'>
        <div className='flex flex-wrap -mx-3 mb-12 mt-5 -mt-5 -mb-5'>
          <div className={"flex items-center w-full justify-between"}>
            <div className='w-full px-3 mb-4'>
              <p className='w-full text-3xl mt-4 text-black font-semibold'>
                Get 1 month of unlimited for&nbsp;
                <span className={"text-blue-pondr font-semibold"}>free</span>!
              </p>
            </div>
          </div>

          {error ? (
            <div className='text-sm w-full p-6 mx-3 mb-4 bg-red-200 border-2 border-red-600 text-red-600 rounded'>
              {error}
            </div>
          ) : null}

          <div className='grid grid-cols-2 gap-4 mb-8 items-center w-full'>
            <div className='flex flex-col'>
              <span className='text-xl font-semibold'>
                First Name
                <span className='text-red-orange'>*</span>
              </span>
              <input
                className={"w-full rounded-full py-2 px-6 bg-gray-secondaryBox"}
                name='firstname'
                label='First Name'
                placeholder='Enter your first name'
                type='text'
                required
                ref={register}
                errors={errors}
              />
              <p className='block text-sm font-medium text-red-600'>
                <ErrorMessage errors={errors} name='firstname' />
              </p>
            </div>

            <div className='flex flex-col'>
              <span className='text-xl font-semibold'>
                Last Name
                <span className='text-red-orange'>*</span>
              </span>
              <input
                className={"w-full rounded-full py-2 px-6 bg-gray-secondaryBox"}
                name='lastname'
                label='Last Name'
                placeholder='Enter your last name'
                type='text'
                required
                ref={register}
                errors={errors}
              />
              <p className='block text-sm font-medium text-red-600'>
                <ErrorMessage errors={errors} name='lastname' />
              </p>
            </div>
          </div>

          <div className='w-full mb-8'>
            <span className='text-xl font-semibold'>
              Email
              <span className='text-red-orange'>*</span>
            </span>
            <input
              className='w-full rounded-full py-2 px-6 bg-gray-secondaryBox'
              name='email'
              label='Email'
              placeholder='Enter your email'
              type='text'
              required
              ref={register}
              errors={errors}
            />
            <p className='block text-sm font-medium text-red-600'>
              <ErrorMessage errors={errors} name='email' />
            </p>
          </div>

          <div className='w-full mb-8'>
            <span className='text-xl font-semibold'>
              Username
              <span className='text-red-orange'>*</span>
            </span>
            <input
              className='w-full rounded-full py-2 px-6 bg-gray-secondaryBox'
              name='companyName'
              label='Username'
              placeholder='Enter your username'
              type='text'
              required
              ref={register}
              errors={errors}
            />
            <p className='block text-sm font-medium text-red-600'>
              <ErrorMessage errors={errors} name='companyName' />
            </p>
          </div>

          <span className='text-xl font-semibold'>Phone Number (optional)</span>
          <input
            className='w-full rounded-full py-2 px-6 bg-gray-secondaryBox mb-8'
            name='phoneNum'
            label='Phone Number'
            placeholder='Enter your phone number'
            type='text'
            //required
            ref={register}
            errors={errors}
            maxLength={15}
          />

          <div className='w-full mb-8'>
            <span className='text-xl font-semibold'>
              Password
              <span className='text-red-orange'>*</span>
            </span>
            <input
              className='w-full rounded-full py-2 px-6 bg-gray-secondaryBox'
              name='password'
              label='Password'
              placeholder='Enter your password'
              type='password'
              required
              ref={register}
              errors={errors}
            />
            <p className='block text-sm font-medium text-red-600'>
              <ErrorMessage errors={errors} name='password' />
            </p>
          </div>

          <div className='w-full mb-8'>
            <span className='text-xl font-semibold'>
              Confirm Password
              <span className='text-red-orange'>*</span>
            </span>
            <input
              className='w-full rounded-full py-2 px-6 bg-gray-secondaryBox'
              name='passwordConfirm'
              label='Confirm Password'
              placeholder='Re-enter Your Password'
              type='password'
              required
              ref={register}
              errors={errors}
            />
            <p className='block text-sm font-medium text-red-600'>
              <ErrorMessage errors={errors} name='passwordConfirm' />
            </p>
          </div>

          <div className='w-full mb-12'>
            <span className='text-xl font-semibold'>
              How Did You Hear About Quasi?
              <span className='text-red-orange'>*</span>
            </span>
            <select
              name='outreachType'
              ref={register}
              className='form-select w-full focus:outline-none outline-none rounded-full bg-gray-tertiaryBox'
              value={referralID !== "" ? "Referral" : null}>
              <option hidden>Please Select An Option</option>
              <option>Google Search</option>
              <option>Instagram</option>
              <option>Twitter</option>
              <option>Facebook</option>
              <option>YouTube</option>
              <option>Discord</option>
              <option>Reddit</option>
              <option>TikTok</option>
              <option>LinkedIn</option>
              <option>Website</option>
              <option>Referral (Friend/Affiliate)</option>
              <option>Other</option>
            </select>

            <p className='block text-sm font-medium text-red-600'>
              <ErrorMessage errors={errors} name='outreachType' />
            </p>
          </div>

          {error ? (
            <div className='text-sm w-full p-6 mx-3 mb-4 bg-red-200 border-2 border-red-600 text-red-600 rounded '>
              {error}
            </div>
          ) : null}
          <div className='flex flex-row w-full justify-left mb-8 '>
            {isLoading ? (
              <div className='flex sm:w-full lg:w-1/3 justify-center pb-5'>
                <ReactLoading
                  type={"spin"}
                  color={"#6a4c93"}
                  height={"10%"}
                  width={"10%"}
                />
              </div>
            ) : (
              <button
                className='my-auto mr-auto w-full text-sm text-blue-pondr'
                type='submit'>
                Sign up
              </button>
            )}

            <Link
              to='/login'
              className='my-auto mr-auto w-full text-sm text-blue-pondr'>
              Already Have An Account? Sign In
            </Link>
          </div>
        </div>
      </form>
      <Modal
        visible={errorModal}
        effect='fadeInUp'
        onClickAway={() => setErrorModal(false)}>
        <div className={"p-5 flex items-center flex-col px-10 py-10"}>
          <div
            onClick={() => setErrorModal(false)}
            className={
              "focus:outline-none cursor-pointer self-end -mr-8 -mt-5"
            }>
            <GiCancel size={30} color={"#7779FC"} />
          </div>
          <p
            className={
              "text-2xl mb-2 text-blue-pondr text-center font-bold mt-5"
            }>
            Whoops, an error occurred.
          </p>
          <p className={"text-center text-md mt-10 font-normal text-lg"}>
            {errorMessage}
          </p>
        </div>
      </Modal>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: async (creds) => {
      const returnSignIn = await signIn(creds);
      dispatch(returnSignIn);
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanySignUp)
);
