import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { connect } from "react-redux";
import { logEvent, logFBEvent, logScreenName } from "../utils/CommonFunctions";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import Button from "./components/Button";
import Input from "./components/Input";
import Card from "./components/Card";
import { Range } from "react-range";
import Editable from "../pages/components/Editable";
import { useHistory } from "react-router";
import ReactLoading from "react-loading";
import { Link } from 'react-router-dom';
import { AiFillCode } from 'react-icons/ai';
import { AiFillGift } from 'react-icons/ai';
import { FaConnectdevelop } from 'react-icons/fa';
import { RiDashboardLine } from 'react-icons/ri'
import {
  getStripeCustomer,
  isCustomerSubscribed,
  createImageModel,
  generateImageExamples,
  logFirestoreEvent,
  getTokens
} from "../merlinv1/beta_api";
import { input } from "@tailwindcss/custom-forms/src/defaultOptions";
import ReactTooltip from "react-tooltip";
import TextArea from "../pages/components/TextArea";
import Switch from "react-switch";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const InfoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFEBB4",
    borderRadius: '1rem',
    fontWeight: 500,
    fontSize: 14,
    color: "#725300",
    display: 'flex',
    alignItems: 'center',
    padding: 20,
  },
}));

const AddProductFormSchema = Yup.object({
  modelTitle: Yup.string().required("Model title is required"),
  prompt: Yup.string().required("Prompt is required"),
  engine: Yup.string().required("Model engine is required")
});

const MakeImageModel = (props) => {

  // Button loading state
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExamples, setIsLoadingExamples] = useState(false);
  const [numRuns, setNumRuns] = React.useState([45])
  const [width, setWidth] = React.useState([256])
  const [length, setLength] = React.useState([256])
  const [numImages, setNumImages] = React.useState([4])
  const [uniqueness, setUniqueness] = React.useState([11])
  const [input1, setInput1] = React.useState("")
  const [input2, setInput2] = React.useState("")
  const [input3, setInput3] = React.useState("")
  const [input1Len, setInput1Len] = React.useState([60])
  const [input2Len, setInput2Len] = React.useState([60])
  const [input3Len, setInput3Len] = React.useState([60])
  const [example1, setExample1] = React.useState("")
  const [example2, setExample2] = React.useState("")
  const [example3, setExample3] = React.useState("")
  const [exampleInputList, setExampleInputList] = React.useState([])
  const [exampleOutput, setExampleOutput] = useState("Generate Examples Before Launching!")
  const [openAIKey, setOpenAIKey] = React.useState("")
  const [useStatePrompt, setPrompt] = React.useState("")
  const [exampleList, setExampleList] = React.useState([])
  const [newModelEngine, setNewModelEngine] = React.useState()
  const history = useHistory();
  const [numberList, setNumberList] = React.useState([])
  const [oldPrompt, setOldPrompt] = React.useState("")
  const [nameList, setNameList] = React.useState([])
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(AddProductFormSchema),
    defaultValues: {
      modelTitle: "",
      category: "",
      openAI: "",
      prompt: "",
      engine: "",
      input1: "",
      input2: "",
      input3: "",
      prefix: "",
      credits: 1,
    },
  });

  const addExamples = async (event) => {
    var newExampleList = exampleList
    newExampleList.push(exampleOutput)
    setExampleList(newExampleList)

    var newExampleInputList = exampleInputList
    newExampleInputList.push([example1, example2, example3])
    setExampleInputList(newExampleInputList)

    forceUpdate()
  }

  const subtractExamples = async (index) => {
    var newExampleList = exampleList
    newExampleList.splice(index, 1)
    setExampleList(newExampleList)

    var newExampleInputList = exampleInputList
    newExampleInputList.splice(index, 1)
    setExampleInputList(newExampleInputList)
  }

  const handleChange = async (event) => {

    // This is really ugly I know, ill come back and make it better but for now it works - Thomas S
    var newNumberList = numberList
    var newNameList = nameList
    var textInput = event.target.value
    setPrompt(textInput)
    if (textInput !== oldPrompt) {
      setExampleList([])
      setExampleInputList([])
      setExampleOutput("Generate Examples Before Launching!")

    }
    if (textInput.includes("{1}") && !newNumberList.includes(1)) {
      newNumberList.push(1)
      newNameList.push("input1")
      forceUpdate()
    }
    if (textInput.includes("{2}") && !newNumberList.includes(2)) {
      newNumberList.push(2)
      newNameList.push("input2")
      forceUpdate()
    }
    if (textInput.includes("{3}") && !newNumberList.includes(3)) {
      newNumberList.push(3)
      newNameList.push("input3")
      forceUpdate()
    }
    if (!textInput.includes("{1}") && newNumberList.includes(1)) {
      const index = newNumberList.indexOf(1);
      if (index > -1) {
        newNumberList.splice(index, 1);
        newNameList.splice(index, 1);
        setInput1("")
      }
      forceUpdate()
    }
    if (!textInput.includes("{2}") && newNumberList.includes(2)) {
      const index = newNumberList.indexOf(2);
      if (index > -1) {
        newNumberList.splice(index, 1);
        newNameList.splice(index, 1);
        setInput2("")
      }
      forceUpdate()
    }
    if (!textInput.includes("{3}") && newNumberList.includes(3)) {
      const index = newNumberList.indexOf(3);
      if (index > -1) {
        newNumberList.splice(index, 1);
        newNameList.splice(index, 1);
        setInput3("")
      }
      forceUpdate()
    }
    setNumberList(newNumberList)
    setNameList(newNameList)
    setOldPrompt(textInput)
  }

  const onSubmit = async (data) => {

    if (exampleList.length >= 3) {
      setIsLoading(true);
      // Creates the product
      var res = await createImageModel(
        data.modelTitle,
        useStatePrompt,
        newModelEngine,
        data.credits,
        numRuns,
        width,
        length,
        numImages,
        uniqueness,
        exampleList,
        exampleInputList,
        input1,
        input2,
        input3,
        data.modelDescription,
        input1Len,
        input2Len,
        input3Len
      );

      setIsLoading(false);
      history.push("/user/dashboard/");

    }

    else {
      alert("Plase have at least 3 examples to launch model")
    }
  };

  const onGenerateSubmit = async (data) => {

    let res = ""

    setIsLoadingExamples(true);

    if (true) {

      res = await generateImageExamples(
        useStatePrompt,
        newModelEngine,
        numRuns,
        width,
        length,
        numImages,
        uniqueness,
        example1,
        example2,
        example3
      );
      setExampleOutput(res.data["response"]);

      setIsLoadingExamples(false);

    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  const handler = async (event) => {
    // Searches models
    if (event.key === "Enter") {
      onGenerateSubmit()
    }
  };

  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section className="sm:px-0 lg:px-16">
      <Header />
      <div className="w-11/12 mx-auto pt-8 md:pt-16 sf-pro">
        <div className="flex flex-wrap flex-row justify-between pb-5">
          <div className="text-left sm:text-5xl font-bold leading-tighter tracking-tighter my-auto text-left">
            Make Image Model
          </div>
          <Link to="/user/dashboard"
            className="my-auto btn-sm bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox rounded-3xl md:text-base sm:text-xs">
            <span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.4434 12.5918C19.4434 8.60742 16.7578 5.86328 12.334 5.86328H6.48438L4.24805 5.96094L5.85938 4.61328L8.14453 2.38672C8.35938 2.16211 8.50586 1.89844 8.50586 1.52734C8.50586 0.853516 8.03711 0.355469 7.32422 0.355469C7.01172 0.355469 6.68945 0.492188 6.44531 0.736328L0.927734 6.17578C0.693359 6.41016 0.556641 6.74219 0.556641 7.07422C0.556641 7.40625 0.693359 7.74805 0.927734 7.98242L6.44531 13.4121C6.68945 13.6562 7.01172 13.793 7.32422 13.793C8.03711 13.793 8.50586 13.2949 8.50586 12.6211C8.50586 12.25 8.35938 11.9863 8.14453 11.7617L5.85938 9.54492L4.24805 8.19727L6.48438 8.29492H12.3242C15.332 8.29492 17.041 10.043 17.041 12.5137C17.041 14.9941 15.332 16.8008 12.3242 16.8008H10.0391C9.31641 16.8008 8.79883 17.3379 8.79883 18.0117C8.79883 18.6855 9.31641 19.2324 10.0391 19.2324H12.4414C16.8066 19.2324 19.4434 16.6055 19.4434 12.5918Z" fill="black" />
              </svg>
            </span>
            <span className="pl-2">
              Back To Dashboard
            </span>
          </Link>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className="flex justify-center mx-auto m-0 p-0 w-full"
        >
          <div className="flex flex-col">
            <div className="bg-gray-tertiaryBox rounded-3xl p-6">
              <div className='md:text-xl sm:md:text-base sm:text-xs mb-2 flex flex-row'>
                <span>Title (required)</span>
                <span className="bg-yellow-info rounded-full p-1 my-auto ml-2 flex flex-col justify-center">
                  <InfoTooltip title="A catchy title can help you hook creators. When creating titles, it’s a good idea to include keywords your audience is likely to use when looking for videos like yours." style={{ background: "#FFEBB4" }} placement="right-end">
                    <div style={{ "width": "fit-content", display: "flex", alignItems: "center" }}>
                      <svg width="14.3181818" height="15" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.1904 21.0371C15.708 21.0371 20.2783 16.4766 20.2783 10.9492C20.2783 5.43164 15.708 0.861328 10.1807 0.861328C4.66307 0.861328 0.102526 5.43164 0.102526 10.9492C0.102526 16.4766 4.67284 21.0371 10.1904 21.0371ZM10.1904 19.0547C5.69823 19.0547 2.09471 15.4414 2.09471 10.9492C2.09471 6.45703 5.68846 2.85352 10.1807 2.85352C14.6728 2.85352 18.2861 6.45703 18.2959 10.9492C18.2959 15.4414 14.6826 19.0547 10.1904 19.0547ZM10.1318 7.62891C10.874 7.62891 11.4599 7.0332 11.4599 6.30078C11.4599 5.54883 10.874 4.95312 10.1318 4.95312C9.3994 4.95312 8.8037 5.54883 8.8037 6.30078C8.8037 7.0332 9.3994 7.62891 10.1318 7.62891ZM8.4619 16.2617H12.4267C12.8662 16.2617 13.208 15.9492 13.208 15.5098C13.208 15.0898 12.8662 14.7578 12.4267 14.7578H11.333V10.1191C11.333 9.5332 11.04 9.15234 10.4932 9.15234H8.63768C8.20799 9.15234 7.85643 9.48438 7.85643 9.9043C7.85643 10.3438 8.20799 10.6562 8.63768 10.6562H9.63378V14.7578H8.4619C8.03221 14.7578 7.69042 15.0898 7.69042 15.5098C7.69042 15.9492 8.03221 16.2617 8.4619 16.2617Z" fill="#725300" />
                      </svg>
                    </div>
                  </InfoTooltip>
                </span>
              </div>
              <input
                className="md:text-lg sm:text-sm px-4 text-black w-full bg-gray-secondaryBox rounded-xl border-opacity-0 focus:border-opacity-0"
                name="modelTitle"
                placeholder="Enter your model's title"
                type="text"
                required
                ref={register}
                errors={errors}
                maxLength={150}
              />
            </div>

            <div className="pt-5" />

            <div className="rounded-3xl bg-gray-tertiaryBox p-6">
              <div className='md:text-xl sm:md:text-base sm:text-xs mb-4 flex flex-row'>
                <span>Description (required)</span>
                <span className="bg-yellow-info rounded-full p-1 my-auto ml-2 flex flex-col justify-center">
                  <InfoTooltip title="Writing descriptions with keywords can help viewers easily understand what your model does. You give an overview of your model and place keywords at the end of the description." placement="right-end">
                    <div style={{ "width": "fit-content", display: "flex", alignItems: "center" }}>
                      <svg width="14.3181818" height="15" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.1904 21.0371C15.708 21.0371 20.2783 16.4766 20.2783 10.9492C20.2783 5.43164 15.708 0.861328 10.1807 0.861328C4.66307 0.861328 0.102526 5.43164 0.102526 10.9492C0.102526 16.4766 4.67284 21.0371 10.1904 21.0371ZM10.1904 19.0547C5.69823 19.0547 2.09471 15.4414 2.09471 10.9492C2.09471 6.45703 5.68846 2.85352 10.1807 2.85352C14.6728 2.85352 18.2861 6.45703 18.2959 10.9492C18.2959 15.4414 14.6826 19.0547 10.1904 19.0547ZM10.1318 7.62891C10.874 7.62891 11.4599 7.0332 11.4599 6.30078C11.4599 5.54883 10.874 4.95312 10.1318 4.95312C9.3994 4.95312 8.8037 5.54883 8.8037 6.30078C8.8037 7.0332 9.3994 7.62891 10.1318 7.62891ZM8.4619 16.2617H12.4267C12.8662 16.2617 13.208 15.9492 13.208 15.5098C13.208 15.0898 12.8662 14.7578 12.4267 14.7578H11.333V10.1191C11.333 9.5332 11.04 9.15234 10.4932 9.15234H8.63768C8.20799 9.15234 7.85643 9.48438 7.85643 9.9043C7.85643 10.3438 8.20799 10.6562 8.63768 10.6562H9.63378V14.7578H8.4619C8.03221 14.7578 7.69042 15.0898 7.69042 15.5098C7.69042 15.9492 8.03221 16.2617 8.4619 16.2617Z" fill="#725300" />
                      </svg>
                    </div>
                  </InfoTooltip>
                </span>
              </div>
              <input
                className="md:text-lg sm:text-sm px-4 text-black w-full bg-gray-secondaryBox rounded-xl border-opacity-0 focus:border-opacity-0"
                name="modelDescription"
                placeholder="Describe your model"
                type="text"
                required
                ref={register}
                errors={errors}
                maxLength={200}
              />
            </div>

            <div className="w-full pb-8">
              <div className="flex flex-wrap overflow-hidden bg-gray-tertiaryBox rounded-lg">
                <div className="pt-2 px-2 w-full overflow-hidden md:px-2 xl:px-3 xl:w-1/2">
                  <div className="">
                    <div className="w-full">
                      <label
                        className="form-label block text-sm font-medium mb-1"
                        htmlFor="engine"
                      >
                        <div className="text-left h3 mr-5 leading-tighter tracking-tighter mb-2">
                          Model Engine
                        </div>
                      </label>
                      <select
                        ref={register}
                        onChange={e => setNewModelEngine(e.target.value)}
                        name="engine"
                        className="form-select w-full focus:outline-none bg-gray-secondaryBox"
                      >
                        <option value="" disabled defaultValue>
                          Select your model engine
                        </option>
                        <option value="Quasi Diffusion">Quasi Diffusion</option>
                      </select>
                      <p className="block text-sm font-medium text-red-600">
                        <ErrorMessage errors={errors} name="engine" />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="pt-2 px-2 w-full overflow-hidden md:px-2 xl:px-3 xl:w-1/2">
                  <div className="">
                    <div className="w-full">
                      <div className="text-left h3 mr-5 leading-tighter tracking-tighter mb-2">
                        Set Price
                      </div>
                      <Input
                        className="w-full mb-8"
                        name="credits"
                        placeholder="How many coins does your model cost? (1 credit is 10 cents)"
                        type="number"
                        required
                        ref={register}
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full pb-8">
              <div className="w-full bg-gray-tertiaryBox rounded-lg">
                <div className="w-full flex justify-center items-center">
                  <div className="text-right h3 pt-2 leading-tighter tracking-tighter"
                    data-tip="Quasi recommends 4 examples">
                    Prompt
                  </div>
                </div>
                <div className="w-full px-3 flex justify-center items-center">
                  <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                    Use &#123;1&#125; &#123;2&#125; &#123;3&#125; characters to denote an input
                  </div>
                </div>

                <div className="w-full px-3 flex">
                  <div className="w-full bg-quasi-gray">
                    <div className="w-full p-2 mb-3">
                      <textarea
                        type="textarea"
                        className=" px-4 py-3 w-full text-gray-800 bg-gray-secondaryBox rounded-lg whitespace-pre-line"
                        name="prompt"
                        label="Prompt"
                        placeholder="Enter your prompt"
                        required
                        ref={register}
                        errors={errors}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full pb-24">
              {numberList.length > 0 ? (
                <div className="w-full px-3">
                  <div
                    className="mb-2"
                  >
                    <div className="flex flex-wrap -mx-1 overflow-hidden sm:-mx-1 md:-mx-1 lg:-mx-1 xl:-mx-4 bg-gray-tertiaryBox rounded-xl">
                      <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                        <div className="p-4">
                          <div className="pb-2">
                            <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                              Input {numberList[0]}
                            </div>
                            <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                              What do you want the end user to input at {numberList[0]}
                            </div>
                          </div>
                        </div>
                        <div className="p-4">
                          <div className="w-full">
                            <Editable
                              className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox"
                              text={input1}
                              placeholder="What should the user input at this location?"
                              type="input"
                            >
                              <input
                                className="w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                name='test1'
                                value={input1}
                                placeholder="What should the user input at this location?"
                                required
                                type="text"
                                errors={errors}
                                onChange={e => setInput1(e.target.value)}
                              />
                            </Editable>
                          </div>
                        </div>
                      </div>

                      <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                        <div className="p-4">
                          <div className="pb-2">
                            <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                              Input {numberList[0]} Length
                            </div>
                            <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                              What is the maximum length you would allow the end user to input at input {numberList[0]}?
                            </div>
                          </div>
                        </div>

                        <div className="p-4">
                          <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                            <Range
                              step={1}
                              min={0}
                              max={1000}
                              values={input1Len}
                              onChange={(input1Len) => {
                                setInput1Len(input1Len)
                              }}
                              renderTrack={({ props, children }) => (
                                <div
                                  {...props}
                                  className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                                >
                                  {children}
                                </div>
                              )}
                              renderThumb={({ props }) => (
                                <div
                                  {...props}
                                  className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                                />
                              )}
                            />
                            <span>{input1Len[0]}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {numberList.length > 1 ? (
                <div className="w-full px-3">
                  <div
                    className="mb-2"
                  >
                    <div className="flex flex-wrap -mx-1 overflow-hidden sm:-mx-1 md:-mx-1 lg:-mx-1 xl:-mx-4 bg-gray-tertiaryBox rounded-xl">
                      <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                        <div className="p-4">
                          <div className="pb-2">
                            <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                              Input {numberList[1]}
                            </div>
                            <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                              What do you want the end user to input at {numberList[1]}
                            </div>
                          </div>
                        </div>
                        <div className="p-4">
                          <div className="w-full">
                            <Editable
                              className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox"
                              text={input2}
                              placeholder="What should the user input at this location?"
                              type="input"
                            >
                              <input
                                className="w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                name='test1'
                                value={input2}
                                placeholder="What should the user input at this location?"
                                required
                                type="text"
                                errors={errors}
                                onChange={e => setInput2(e.target.value)}
                              />
                            </Editable>
                          </div>
                        </div>
                      </div>

                      <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                        <div className="p-4">
                          <div className="pb-2">
                            <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                              Input {numberList[1]} Length
                            </div>
                            <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                              What is the maximum length you would allow the end user to input at input {numberList[1]}?
                            </div>
                          </div>
                        </div>

                        <div className="p-4">
                          <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                            <Range
                              step={1}
                              min={0}
                              max={1000}
                              values={input2Len}
                              onChange={(input2Len) => {
                                setInput2Len(input2Len)
                              }}
                              renderTrack={({ props, children }) => (
                                <div
                                  {...props}
                                  className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                                >
                                  {children}
                                </div>
                              )}
                              renderThumb={({ props }) => (
                                <div
                                  {...props}
                                  className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                                />
                              )}
                            />
                            <span>{input2Len[0]}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {numberList.length > 2 ? (
                <div className="w-full px-3">
                  <div
                    className="mb-2"
                  >
                    <div className="flex flex-wrap -mx-1 overflow-hidden sm:-mx-1 md:-mx-1 lg:-mx-1 xl:-mx-4 bg-gray-tertiaryBox rounded-xl">
                      <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                        <div className="p-4">
                          <div className="pb-2">
                            <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                              Input {numberList[2]}
                            </div>
                            <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                              What do you want the end user to input at {numberList[2]}
                            </div>
                          </div>
                        </div>
                        <div className="p-4">
                          <div className="w-full">
                            <Editable
                              className="form-input w-full text-gray-800 outline-none bg-gray-secondaryBox"
                              text={input3}
                              placeholder="What should the user input at this location?"
                              type="input"
                            >
                              <input
                                className="w-full text-gray-800 outline-none bg-gray-secondaryBox"
                                name='test1'
                                value={input3}
                                placeholder="What should the user input at this location?"
                                required
                                type="text"
                                errors={errors}
                                onChange={e => setInput3(e.target.value)}
                              />
                            </Editable>
                          </div>
                        </div>
                      </div>

                      <div className="my-1 px-1 w-full overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 lg:my-1 lg:px-1 lg:w-1/2 xl:my-1 xl:px-1 xl:w-1/2">
                        <div className="p-4">
                          <div className="pb-2">
                            <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                              Input {numberList[2]} Length
                            </div>
                            <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                              What is the maximum length you would allow the end user to input at input {numberList[2]}?
                            </div>
                          </div>
                        </div>

                        <div className="p-4">
                          <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                            <Range
                              step={1}
                              min={0}
                              max={1000}
                              values={input3Len}
                              onChange={(input3Len) => {
                                setInput3Len(input3Len)
                              }}
                              renderTrack={({ props, children }) => (
                                <div
                                  {...props}
                                  className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                                >
                                  {children}
                                </div>
                              )}
                              renderThumb={({ props }) => (
                                <div
                                  {...props}
                                  className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                                />
                              )}
                            />
                            <span>{input3Len[0]}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="w-full grid overflow-hidden grid-cols-2 auto-rows-max gap-4">
              <div className="box col-start-1 col-end-3 bg-gray-tertiaryBox rounded-xl p-4">
                <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                    <div className="pb-2">
                      <div className="p-1">
                        <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                          Number of Runs
                        </div>
                        <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                          How many times do you want your model to run?
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                    <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                      <Range
                        step={1}
                        min={0}
                        max={50}
                        values={numRuns}
                        onChange={(numRuns) => {
                          setNumRuns(numRuns)
                        }}
                        renderTrack={({ props, children }) => (
                          <div
                            {...props}
                            className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                          >
                            {children}
                          </div>
                        )}
                        renderThumb={({ props }) => (
                          <div
                            {...props}
                            className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                          />
                        )}
                      />
                      <span>{numRuns[0]}</span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                    <div className="pb-2">
                      <div className="p-1">
                        <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                          Width
                        </div>
                        <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                          How wide in pixel do you want your image to be?
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                    <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                      <Range
                        step={128}
                        min={0}
                        max={1024}
                        values={width}
                        onChange={(width) => {
                          setWidth(width)
                        }}
                        renderTrack={({ props, children }) => (
                          <div
                            {...props}
                            className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                          >
                            {children}
                          </div>
                        )}
                        renderThumb={({ props }) => (
                          <div
                            {...props}
                            className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                          />
                        )}
                      />
                      <span>{width[0]}</span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                    <div className="pb-2">
                      <div className="p-1">
                        <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                          Legnth
                        </div>
                        <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                          How long in pixels do you want your image to be?
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                    <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                      <Range
                        step={128}
                        min={0}
                        max={1024}
                        values={length}
                        onChange={(length) => {
                          setLength(length)
                        }}
                        renderTrack={({ props, children }) => (
                          <div
                            {...props}
                            className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                          >
                            {children}
                          </div>
                        )}
                        renderThumb={({ props }) => (
                          <div
                            {...props}
                            className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                          />
                        )}
                      />
                      <span>{length[0]}</span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                    <div className="pb-2">
                      <div className="rounded-xl p-1">
                        <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                          Number of Images
                        </div>
                        <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                          How many images do you want?
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                    <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                      <Range
                        step={1}
                        min={0}
                        max={16}
                        values={numImages}
                        onChange={(numImages) => {
                          setNumImages(numImages)
                        }}
                        renderTrack={({ props, children }) => (
                          <div
                            {...props}
                            className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                          >
                            {children}
                          </div>
                        )}
                        renderThumb={({ props }) => (
                          <div
                            {...props}
                            className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                          />
                        )}
                      />
                      <span>{numImages[0]}</span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
                    <div className="pb-2">
                      <div className="p-1">
                        <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                          Uniqueness Value
                        </div>
                        <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                          How unique do you want each of your images
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="my-2 px-2 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 self-center">
                    <div className="w-full sm:my-2 sm:px-2 md:my-1 md:px-1 md:w-full lg:w-full xl:my-2 xl:px-2">
                      <Range
                        step={1}
                        min={0}
                        max={15}
                        values={uniqueness}
                        onChange={(uniqueness) => {
                          setUniqueness(uniqueness)
                        }}
                        renderTrack={({ props, children }) => (
                          <div
                            {...props}
                            className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md"
                          >
                            {children}
                          </div>
                        )}
                        renderThumb={({ props }) => (
                          <div
                            {...props}
                            className="w-5 h-5 transform translate-x-10 bg-blue-user rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-pondr"
                          />
                        )}
                      />
                      <span>{uniqueness[0]}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box col-start-1 col-end-3 bg-gray-tertiaryBox rounded-xl p-4">
                <div className="flex flex-row justify-between">
                  <div>
                    <div className="">
                      <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                        Test Your Model!
                      </div>
                    </div>

                    <div className="">
                      <div className="text-left mr-5 leading-tighter tracking-tighter mb-4">
                        These examples will show up on your model's page
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="my-2 px-2 w-full overflow-hidden">
                      <div className="w-full">
                        <button
                          className="w-full text-blue-primaryText bg-blue-primaryBox hover:bg-blue-hoverBox hover:text-blue-hoverText font-bold py-2 px-4 rounded-full"
                          type="generate_examples"
                          onClick={handleSubmit(onGenerateSubmit, onError)}
                        >
                          <span className=''>
                            {isLoadingExamples ?
                              <div className="flex items-center justify-center">
                                <svg role="status" className="inline w-6 h-6 mx-16 text-blue-generateSpin animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#ffffff" />
                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                              </div>
                              : 'Generate Examples'}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full flex mb-5"
                  onKeyDown={(e) => handler(e)}>
                  {numberList.length > 0 ? (
                    <div className="w-full">
                      <div
                        className="mb-2 px-3"
                      >
                        <div className="flex flex-wrap w-full overflow-hidden">
                          <div className="my-2 px-2 w-full overflow-hidden">
                            <div className="pb-2">
                              <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                {input1}
                              </div>
                            </div>
                          </div>
                          <div className="my-2 px-2 w-full overflow-hidden">
                            <div className="">
                              <TextArea
                                className="px-4 py-3 w-full bg-gray-secondaryBox rounded-lg whitespace-pre-line"
                                name='Exinput1'
                                placeholder="Complete your input for example outputs"
                                required
                                type="text"
                                errors={errors}
                                onChangeText={(newText) => setExample1(newText)}
                                maxLength={input1Len * 4}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="w-full flex mb-5"
                  onKeyDown={(e) => handler(e)}>
                  {numberList.length > 1 ? (
                    <div className="w-full">
                      <div
                        className="mb-2 px-3"
                      >
                        <div className="flex flex-wrap w-full overflow-hidden">
                          <div className="my-2 px-2 w-full">
                            <div className="pb-2">
                              <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                {input2}
                              </div>
                            </div>
                          </div>
                          <div className="my-2 px-2 w-full">
                            <div className="">
                              <TextArea
                                className="px-4 py-3 w-full bg-gray-secondaryBox rounded-lg whitespace-pre-line"
                                name='Exinput2'
                                placeholder="Complete your input for example outputs"
                                required
                                type="text"
                                errors={errors}
                                onChangeText={(newText) => setExample2(newText)}
                                maxLength={input2Len * 4}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="w-full flex mb-5"
                  onKeyDown={(e) => handler(e)}>
                  {numberList.length > 2 ? (
                    <div className="w-full">
                      <div
                        className="mb-2 px-3"
                      >
                        <div className="flex flex-wrap w-full overflow-hidden">
                          <div className="my-2 px-2 w-full overflow-hidden">
                            <div className="pb-2">
                              <div className="text-left h3 mr-5 leading-tighter tracking-tighter">
                                {input3}
                              </div>
                            </div>
                          </div>
                          <div className="my-2 px-2 w-full overflow-hidden">
                            <div className="">
                              <TextArea
                                className="px-4 py-3 w-full bg-gray-secondaryBox rounded-lg whitespace-pre-line"
                                name='Exinput3'
                                placeholder="Complete your input for example outputs"
                                required
                                type="text"
                                errors={errors}
                                onChangeText={(newText) => setExample3(newText)}
                                maxLength={input3Len * 4}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="w-full overflow-hidden bg-gray-tertiaryBox rounded-xl">
                  <div className="w-full bg-gray-secondaryBox rounded-lg p-3 whitespace-pre-line">
                    {!exampleOutput.includes("quasi_art") ? (
                      <div className="text-xl font-semibold whitespace-pre-line">
                        {exampleOutput.trim()}
                      </div>
                    ) : (
                      <div className='px-12'>
                        <div className='flex justify-center items-center'>
                          <img src={exampleOutput.trim()}></img>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row justify-end pt-4">
                    <div className="my-2 px-2 overflow-hidden">
                      <div className="">
                        <Button
                          className="w-full text-blue-primaryText bg-blue-primaryBox hover:bg-blue-hoverBox hover:text-blue-hoverText font-bold py-2 px-4 rounded-full"
                          type="add"
                          onClick={handleSubmit(addExamples, onError)}
                        >
                          + Add Example
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box col-start-1 col-end-3 bg-gray-tertiaryBox rounded-xl p-4">
                <p className="text-left h3 mr-5 leading-tighter tracking-tighter">
                  Model Examples
                </p>
                <p className="text-left mr-5 leading-tighter tracking-tighter">
                  These examples will be published on your model page. (Need 3+)
                </p>
                {exampleList.length > 0 ? (
                  <div className="w-full">
                    {exampleList.map((example, index) => (
                      <div className="flex flex-wrap w-full -mx-2 overflow-hidden md:-mx-3 xl:-mx-3">
                        <div className="my-2 px-2 w-full overflow-hidden">
                          <div className="bg-gray-secondaryBox rounded-xl">
                            <div className="p-3">
                              <div className="">
                                <div className="flex justify-end items-end pb-2">
                                  <button
                                    className="rounded-full bg-red-quasi px-2 hover:bg-red-quasidark"
                                    type="button"
                                    onClick={() => {
                                      var newExampleList = exampleList
                                      newExampleList.splice(index, 1)
                                      var newExampleInputList = exampleInputList
                                      newExampleInputList.splice(index, 1)
                                      setExampleInputList(newExampleInputList)
                                      forceUpdate()
                                    }}
                                  >
                                    -
                                  </button>
                                </div>
                                {example1 !== "" ? (
                                  <div className="font-semibold text-lg mb-2 px-3">
                                    <span className="text-left bg-clip-text text-transparent bg-green-mainText">
                                      Input: {" "}
                                    </span>
                                    {" " + exampleInputList[index]}
                                  </div>
                                ) : null}
                                <div className="text-lg font-semibold mb-2 px-3 whitespace-pre-line">
                                  <span className="text-left bg-clip-text text-transparent bg-green-mainText whitespace-pre-line">
                                    Output: {" "}
                                  </span>
                                  <div className='flex justify-center items-center'>
                                    <img src={example.trim()}></img>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : <div className='font-semibold text-lg pt-6'>
                  No examples added yet...
                </div>}
              </div>

              <div className="box col-start-1 col-end-3">
                <div className="">
                  {isLoading ? (
                    <ReactLoading
                      type={"spin"}
                      color={"#AEE08F"}
                      height={"5%"}
                      width={"5%"}
                    />
                  ) : (
                    <Button
                      className="w-full text-green-primaryText bg-green-primaryBox hover:bg-green-hoverBox hover:text-green-hoverText font-bold py-4 px-4 rounded-full"
                      type="submit"
                      id="submit"
                    >
                      Launch Model!
                    </Button>
                  )}
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
      <Footer />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(MakeImageModel);
