import React, { useState, useEffect } from 'react';
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import MetaTags from "react-meta-tags";
import SchoolPartial from '../partials/SchoolPartial';

const School = () => {
    useEffect(() => {

    }, []);
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <MetaTags>
                <title>Quasi School</title>
                <meta
                    name="description"
                    content="Quasi | School"
                />
                <meta property="og:title" content="Quasi | School" />
            </MetaTags>
            <Header />
            <SchoolPartial />
            <div className='justify-end mt-auto'>
                <Footer />
            </div>
        </div>
    );
};

export default School;
