import React, { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import Header from "../partials/Header";
import DevPartial from "../partials/DevPartial";
import Footer from "../partials/Footer";
import MetaTags from "react-meta-tags";

function Developers() {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
    logScreenName("BlogPost1");
    logEvent("page_view", { page_name: "BlogPost1" });
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <MetaTags>
        <title>Developers</title>
        <meta
          name="description"
          content="Quasi | Developers Page"
        />
        <meta property="og:title" content="Quasi | Developers" />
      </MetaTags>
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <DevPartial />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Developers;
