import React, { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import Header from "../partials/Header";
import CreateList from "../partials/CreateLists";
import CreationPartial from "../partials/CreationPartial";
import Wrapper from "../utils/Wrapper";
import Footer from "../partials/Footer";
import MetaTags from "react-meta-tags";

function MyCreations() {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <MetaTags>
        <title>My Creations</title>
        <meta name='description' content='Quasi | Creations Page' />
        <meta property='og:title' content='Quasi | My Creations' />
      </MetaTags>
      <Header />
      <div className={"flex justify-between"}>
        <Wrapper dontShowHeader={true} isGrey={false} topHead={true}>
          <CreationPartial />
        </Wrapper>
      </div>
    </div>
  );
}

export default MyCreations;
