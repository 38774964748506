import React from "react";

const Card4 = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className={
        "relative flex flex-col p-6 bg-gray-secondaryBox rounded-xl justify-between" +
        props.className
      }
    >
      {props.children}
    </div>
  );
});

//bg-gradient-to-r from-green-500 to-yellow-400

export default Card4;
