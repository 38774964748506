import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import EditFlowPartial from "../partials/EditFlowPartial";

const EditFlow = () => {
  useEffect(() => {}, []);

  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <MetaTags>
        <title>Make Workflow</title>
        <meta
          name='description'
          content='Quasi Market: the first ever AI marketplace'
        />
        <meta property='og:title' content='Quasi | Edit Workflow' />
      </MetaTags>

      <Header />
      <EditFlowPartial />
      <Footer />
    </div>
  );
};

export default EditFlow;
