// This is the screen which will contain the checkout session steps for users to use to checkout
import React, { useState, useEffect } from "react";
import Wrapper from "../../utils/Wrapper";
import * as FaIcons from "react-icons/fa";
import CheckoutStep1 from "./CheckoutStep1";
import CheckoutStep2 from "./CheckoutStep2";
import { useLocation, useHistory } from "react-router";
import CheckoutStep3 from "./CheckoutStep3";
import {
  attachPaymentToCustomer,
  createStripeSubscription,
  logFirestoreEvent,
} from "../../merlinv1/beta_api";
import { connect } from "react-redux";
import {
  logScreenName,
  logEvent,
  logFBEvent,
} from "../../utils/CommonFunctions";

// Creates the react component
const CheckoutSession = (props) => {
  // This indiciates the selected tab
  const [selectedTab, setSelectedTab] = useState(0);
  const [paymentInformation, setPaymentInformation] = useState("");
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [defaultPayment, setDefaultPayment] = useState(false);

  // The location hook
  const location = useLocation();
  const history = useHistory();

  // Fetches product information from location.state
  let productName = location.state.productName
  let stripeCustomer = location.state.stripeCustomer
  // Creates the initial plan information (More plans would be available in the future)
  let planPrice = location.state.price
  let stripeID = location.state.stripeID

  // Logs screen analytics
  useEffect(() => {
    logFirestoreEvent("CheckoutStep1View", { email: props.user.email });
    logScreenName("CheckoutStep1");
    logFBEvent("CheckoutStep1", {});
    logEvent("page_view", { page_name: "CheckoutStep1" });
  }, []);

  // This is the method that is going to checkout for the user once the checkout session has been completed
  const checkout = async (priceToCharge, codeApplied) => {
    setIsCheckingOut(true);

    logFirestoreEvent("PaymentAttempt", {
      email: props.user.email,
      priceCharged: priceToCharge,
    });
    // First, attaches the payment to the customer in Stripe if new payment info
    if (defaultPayment === false) {
      const paymentID = paymentInformation.paymentMethod.id;
      (await attachPaymentToCustomer(paymentID))
    }
    // Charges the customer the subscription
    console.log(stripeID)
    console.log(planPrice)
    console.log(codeApplied)
    await createStripeSubscription(stripeID, planPrice, codeApplied);

    logFBEvent("PaymentSuccess", {});
    logFirestoreEvent("PaymentAttemptSuccess", {
      email: props.user.email,
      priceCharged: priceToCharge,
    });

    setIsCheckingOut(false);
    history.push("/user/dashboard/");
  };

  // Array of different titles based on the index
  const arrayOfTitles = [
    {
      title: "Review Your Purchase",
      subtitle: "You are a few steps away from AI goodness",
    },
    {
      title: "Billing Information",
      subtitle: "Just one more step before unlimited creativity",
    },
    {
      title: "Checkout",
      subtitle: "This is it",
    },
  ];

  // Returns the UI
  return (
    <Wrapper isGrey={true}>
      <div className={"w-full flex lg:flex-row md:flex-col sm:flex-col pb-5"}>
        {/* <div className={"ml-12 mt-18"}>
          <p className={"text-5xl text-blue-pondrgray font-bold"}>
            {arrayOfTitles[selectedTab].title}
          </p>
          <p className={"text-2xl mt-5 text-blue-pondr font-normal"}>
            {arrayOfTitles[selectedTab].subtitle}
          </p>
        </div> */}
        <div className="flex flex-row lg:w-2/5 md:w-full sm:w-full">
          <div className="flex lg:flex-col sm:flex-col gap-5 w-full pr-5 sm:mb-5 md:mb-5 lg:mb-0">
            <div className={0 < selectedTab ? "cursor-pointer" : ""}
              onClick={() => {
                if (0 < selectedTab) {
                  setSelectedTab(0);
                }
              }}>
              <div className="bg-gray-tertiaryBox rounded-3xl p-6 flex">
                <span className={0 == selectedTab ? "text-green-mainText flex flex-row" : "text-black flex flex-row"}>
                  {0 == selectedTab ? (<svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3711 41.2695C31.543 41.2695 40.7422 32.0703 40.7422 20.8984C40.7422 9.74609 31.5234 0.527344 20.3516 0.527344C9.19922 0.527344 0 9.74609 0 20.8984C0 32.0703 9.19922 41.2695 20.3711 41.2695ZM20.3711 36.7969C11.5625 36.7969 4.49219 29.707 4.49219 20.8984C4.49219 12.0898 11.543 5 20.3516 5C29.1797 5 36.25 12.0898 36.2695 20.8984C36.2891 29.707 29.1797 36.7969 20.3711 36.7969ZM21.5039 29.7852C22.793 29.7852 23.6133 29.0039 23.6133 27.5195V14.2578C23.6133 12.8711 22.6953 11.9922 21.2695 11.9922C20.3516 11.9922 19.6484 12.2461 18.6328 12.9297L15.3516 15.1172C14.7656 15.5273 14.4922 15.9375 14.4922 16.6016C14.4922 17.4023 15.0977 18.0859 15.918 18.0859C16.2891 18.0859 16.5234 18.0273 17.0703 17.6758L19.2578 16.2305H19.4141V27.5195C19.4141 29.0039 20.2148 29.7852 21.5039 29.7852Z" fill="#8AC926" />
                  </svg>
                  ) : (<svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3711 41.2695C31.543 41.2695 40.7422 32.0703 40.7422 20.8984C40.7422 9.74609 31.5234 0.527344 20.3516 0.527344C9.19922 0.527344 0 9.74609 0 20.8984C0 32.0703 9.19922 41.2695 20.3711 41.2695ZM20.3711 36.7969C11.5625 36.7969 4.49219 29.707 4.49219 20.8984C4.49219 12.0898 11.543 5 20.3516 5C29.1797 5 36.25 12.0898 36.2695 20.8984C36.2891 29.707 29.1797 36.7969 20.3711 36.7969ZM21.5039 29.7852C22.793 29.7852 23.6133 29.0039 23.6133 27.5195V14.2578C23.6133 12.8711 22.6953 11.9922 21.2695 11.9922C20.3516 11.9922 19.6484 12.2461 18.6328 12.9297L15.3516 15.1172C14.7656 15.5273 14.4922 15.9375 14.4922 16.6016C14.4922 17.4023 15.0977 18.0859 15.918 18.0859C16.2891 18.0859 16.5234 18.0273 17.0703 17.6758L19.2578 16.2305H19.4141V27.5195C19.4141 29.0039 20.2148 29.7852 21.5039 29.7852Z" fill="#333333" />
                  </svg>
                  )}
                  <span className="pl-2 my-auto text-xl">
                    Review
                  </span>
                </span>
              </div>
            </div>

            <div className={1 < selectedTab ? "cursor-pointer" : ""}
              onClick={() => {
                if (1 < selectedTab) {
                  setSelectedTab(1);
                }
              }}>
              <div className="bg-gray-tertiaryBox rounded-3xl p-6">
                <span className={1 == selectedTab ? "text-green-mainText flex flex-row" : "text-black flex flex-row"}>
                  {1 == selectedTab ? (<svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3711 41.2695C31.543 41.2695 40.7422 32.0703 40.7422 20.8984C40.7422 9.74609 31.5234 0.527344 20.3516 0.527344C9.19922 0.527344 0 9.74609 0 20.8984C0 32.0703 9.19922 41.2695 20.3711 41.2695ZM20.3711 36.7969C11.5625 36.7969 4.49219 29.707 4.49219 20.8984C4.49219 12.0898 11.543 5 20.3516 5C29.1797 5 36.25 12.0898 36.2695 20.8984C36.2891 29.707 29.1797 36.7969 20.3711 36.7969ZM15.957 29.6094H25.7617C26.8359 29.6094 27.5586 28.9648 27.5586 27.9297C27.5586 26.8945 26.8555 26.2695 25.7617 26.2695H19.9023V26.1133L23.6719 22.2266C25.625 20.3516 26.7578 18.8281 26.7578 16.7969C26.7578 13.75 24.2188 11.7188 20.4297 11.7188C17.4609 11.7188 14.9219 13.3203 14.2383 15.5469C14.1211 15.8594 14.082 16.1523 14.082 16.4648C14.082 17.4609 14.7852 18.0469 15.8203 18.0469C16.7188 18.0469 17.1484 17.5586 17.5195 16.9922C18.0273 16.0156 18.8672 14.9805 20.4102 14.9805C21.8945 14.9805 22.8516 15.8203 22.8516 17.1094C22.8516 18.3203 21.8945 19.3555 20.6836 20.5469L14.9609 26.3281C14.3555 26.875 14.1797 27.4023 14.1797 27.9688C14.1797 28.9453 14.8828 29.6094 15.957 29.6094Z" fill="#8AC926" />
                  </svg>

                  ) : (<svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3711 41.2695C31.543 41.2695 40.7422 32.0703 40.7422 20.8984C40.7422 9.74609 31.5234 0.527344 20.3516 0.527344C9.19922 0.527344 0 9.74609 0 20.8984C0 32.0703 9.19922 41.2695 20.3711 41.2695ZM20.3711 36.7969C11.5625 36.7969 4.49219 29.707 4.49219 20.8984C4.49219 12.0898 11.543 5 20.3516 5C29.1797 5 36.25 12.0898 36.2695 20.8984C36.2891 29.707 29.1797 36.7969 20.3711 36.7969ZM15.957 29.6094H25.7617C26.8359 29.6094 27.5586 28.9648 27.5586 27.9297C27.5586 26.8945 26.8555 26.2695 25.7617 26.2695H19.9023V26.1133L23.6719 22.2266C25.625 20.3516 26.7578 18.8281 26.7578 16.7969C26.7578 13.75 24.2188 11.7188 20.4297 11.7188C17.4609 11.7188 14.9219 13.3203 14.2383 15.5469C14.1211 15.8594 14.082 16.1523 14.082 16.4648C14.082 17.4609 14.7852 18.0469 15.8203 18.0469C16.7188 18.0469 17.1484 17.5586 17.5195 16.9922C18.0273 16.0156 18.8672 14.9805 20.4102 14.9805C21.8945 14.9805 22.8516 15.8203 22.8516 17.1094C22.8516 18.3203 21.8945 19.3555 20.6836 20.5469L14.9609 26.3281C14.3555 26.875 14.1797 27.4023 14.1797 27.9688C14.1797 28.9453 14.8828 29.6094 15.957 29.6094Z" fill="#333333" />
                  </svg>
                  )}
                  <span className="pl-2 my-auto text-xl">
                    Payment Info
                  </span>
                </span>
              </div>
            </div>

            <div className={2 < selectedTab ? "cursor-pointer" : ""}
              onClick={() => {
                if (2 < selectedTab) {
                  setSelectedTab(2);
                }
              }}>
              <div className="bg-gray-tertiaryBox rounded-3xl p-6">
                <span className={2 == selectedTab ? "text-green-mainText flex flex-row" : "text-black flex flex-row"}>
                  {2 == selectedTab ? (<svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3711 41.2891C31.543 41.2891 40.7422 32.0898 40.7422 20.918C40.7422 9.74609 31.5234 0.546875 20.3516 0.546875C9.19922 0.546875 0 9.74609 0 20.918C0 32.0898 9.19922 41.2891 20.3711 41.2891ZM20.3711 36.8164C11.5625 36.8164 4.49219 29.7266 4.49219 20.918C4.49219 12.1094 11.543 5.01953 20.3516 5.01953C29.1797 5.01953 36.25 12.1094 36.2695 20.918C36.2891 29.7266 29.1797 36.8164 20.3711 36.8164ZM20.3125 30.0977C24.6875 30.0977 27.4023 27.9492 27.4023 24.8242C27.4023 22.5781 25.7227 20.7617 23.457 20.5664V20.3711C25.332 20.0977 26.8555 18.3594 26.8555 16.4453C26.8555 13.6523 24.2383 11.7383 20.4297 11.7383C17.1289 11.7383 14.6875 13.125 14.082 15.332C14.0039 15.6445 13.9648 15.8984 13.9648 16.2305C13.9648 17.207 14.668 17.8516 15.7422 17.8516C16.5234 17.8516 17.0508 17.5195 17.4023 16.7969C17.8516 15.5859 18.9062 14.8438 20.3906 14.8438C21.9727 14.8438 22.9492 15.625 22.9492 16.875C22.9492 18.2031 21.8555 19.1602 20.3125 19.1602H19.4336C18.3984 19.1602 17.7539 19.7656 17.7539 20.7031C17.7539 21.6211 18.418 22.2461 19.4336 22.2461H20.3906C22.2266 22.2461 23.4375 23.2031 23.4375 24.6094C23.4375 25.9961 22.207 26.9141 20.3906 26.9141C18.6328 26.9141 17.6172 26.0938 17.1289 24.9609C16.7383 24.2383 16.2305 23.9062 15.4492 23.9062C14.375 23.9062 13.6719 24.5898 13.6719 25.5664C13.6719 25.8984 13.7109 26.1328 13.8086 26.4258C14.4336 28.5938 17.0703 30.0977 20.3125 30.0977Z" fill="#8AC926" />
                  </svg>
                  ) : (<svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3711 41.2891C31.543 41.2891 40.7422 32.0898 40.7422 20.918C40.7422 9.74609 31.5234 0.546875 20.3516 0.546875C9.19922 0.546875 0 9.74609 0 20.918C0 32.0898 9.19922 41.2891 20.3711 41.2891ZM20.3711 36.8164C11.5625 36.8164 4.49219 29.7266 4.49219 20.918C4.49219 12.1094 11.543 5.01953 20.3516 5.01953C29.1797 5.01953 36.25 12.1094 36.2695 20.918C36.2891 29.7266 29.1797 36.8164 20.3711 36.8164ZM20.3125 30.0977C24.6875 30.0977 27.4023 27.9492 27.4023 24.8242C27.4023 22.5781 25.7227 20.7617 23.457 20.5664V20.3711C25.332 20.0977 26.8555 18.3594 26.8555 16.4453C26.8555 13.6523 24.2383 11.7383 20.4297 11.7383C17.1289 11.7383 14.6875 13.125 14.082 15.332C14.0039 15.6445 13.9648 15.8984 13.9648 16.2305C13.9648 17.207 14.668 17.8516 15.7422 17.8516C16.5234 17.8516 17.0508 17.5195 17.4023 16.7969C17.8516 15.5859 18.9062 14.8438 20.3906 14.8438C21.9727 14.8438 22.9492 15.625 22.9492 16.875C22.9492 18.2031 21.8555 19.1602 20.3125 19.1602H19.4336C18.3984 19.1602 17.7539 19.7656 17.7539 20.7031C17.7539 21.6211 18.418 22.2461 19.4336 22.2461H20.3906C22.2266 22.2461 23.4375 23.2031 23.4375 24.6094C23.4375 25.9961 22.207 26.9141 20.3906 26.9141C18.6328 26.9141 17.6172 26.0938 17.1289 24.9609C16.7383 24.2383 16.2305 23.9062 15.4492 23.9062C14.375 23.9062 13.6719 24.5898 13.6719 25.5664C13.6719 25.8984 13.7109 26.1328 13.8086 26.4258C14.4336 28.5938 17.0703 30.0977 20.3125 30.0977Z" fill="#333333" />
                  </svg>
                  )}
                  <span className="pl-2 my-auto text-xl">
                    Checkout
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className={
            "flex items-center w-3/5 justify-between self-center mt-10"
          }
        >
          {["Review", "Payment Info", "Checkout"].map(
            (eachStep, index) => (
              <div
                className={
                  index < selectedTab
                    ? "flex flex-col items-center cursor-pointer"
                    : "flex flex-col items-center"
                }
                onClick={() => {
                  if (index < selectedTab) {
                    setSelectedTab(index);
                  }
                }}
              >
                <div
                  className={
                    "md:w-16 md:h-16 sm:w-10 sm:h-10 flex items-center justify-center rounded-full bg-purple-light mb-5"
                  }
                >
                  {index < selectedTab ? (
                    <FaIcons.FaCheck color={"#6a4c93"} size={20} />
                  ) : (
                    <p className={"text-xl text-blue-pondr"}>{index + 1}</p>
                  )}
                </div>
                <p
                  className={
                    selectedTab === index
                      ? "md:text-xl sm:text-sm font-semibold text-center"
                      : "md:text-xl sm:text-sm font-normal text-center"
                  }
                >
                  {eachStep}
                </p>
              </div>
            )
          )}
        </div> */}
        <div
          className={
            "self-center w-full flex items-center justify-center"
          }
        >
          {
            [
              <CheckoutStep1
                productName={
                  productName.length > 20
                    ? productName.substring(0, 20) + "..."
                    : productName
                }
                price={planPrice}
                onNextClick={() => {
                  setSelectedTab(1);
                  logScreenName("CheckoutStep2");
                  logFirestoreEvent("CheckoutStep2View", {
                    email: props.user.email,
                  });
                  logEvent("page_view", { page_name: "CheckoutStep2" });
                  logFBEvent("CheckoutStep2", {});
                }}
              />,
              <CheckoutStep2
                stripeCustomer={stripeCustomer}
                user={props.user}
                paymentInformation={paymentInformation}
                stripeID={stripeID}
                price={planPrice}

                onNextClick={(paymentInformation) => {
                  if (paymentInformation === "DEFAULT_PAYMENT_INFORMATION") {
                    setPaymentInformation({
                      paymentMethod: stripeCustomer.payment_method,
                    });
                    setDefaultPayment(true);
                  } else {
                    setPaymentInformation(paymentInformation);
                  }
                  setSelectedTab(2);
                  logScreenName("CheckoutStep3");
                  logFirestoreEvent("CheckoutStep3View", {
                    email: props.user.email,
                  });
                  logEvent("page_view", { page_name: "CheckoutStep3" });
                  logFBEvent("CheckoutStep3", {});
                }}
              />,
              <CheckoutStep3
                productName={
                  productName.length > 20
                    ? productName.substring(0, 20) + "..."
                    : productName
                }
                price={planPrice}
                paymentInformation={paymentInformation}
                isCheckingOut={isCheckingOut}
                onCheckout={(priceToCharge, codeApplied) => {
                  checkout(priceToCharge, codeApplied);
                }}
                goToPaymentInfo={() => setSelectedTab(1)}
                // productName=""
                // price={0}
                // paymentInformation=""
                // isCheckingOut={true}
                // onCheckout={() => {console.log("checked out")}}
                // goToPaymentInfo={() => setSelectedTab(1)}
          
              />,
            ][selectedTab]
          }
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(CheckoutSession);
