// This is the first step of the CheckoutSession
import React from "react";
import * as FaIcons from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { Link } from 'react-router-dom';

// Creates the component
const CheckoutStep1 = ({ productName, price, features, onNextClick }) => {
  // Returns the UI
  return (
    <div className="md:grid md:grid-cols-2 gap-5 sm:flex sm:flex-col">
      {productName == "Unlimited Quasi" ? (<>
        <div className="bg-gray-tertiaryBox rounded-3xl p-6 flex flex-col">
          <span className="border-b border-b-solid mb-4 font-bold text-4xl text-red-freeTrial">
            Unlimited
          </span>
          <span className="font-medium text-2xl">
            $5/Mo
          </span>
          <span className="text-lg text-gray-400 pb-8">
            Who doesn't like unlimited?
          </span>
          <span className="flex flex-row text-gray-600 pb-4">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
            </svg>
            <span className="pl-2 my-auto">Unlimited Text Creations</span>
          </span>
          <span className="flex flex-row text-gray-600 pb-4">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
            </svg>
            <span className="pl-2 my-auto">Unlimited Image Creations</span>
          </span>
          <span className="flex flex-row text-gray-600 pb-4">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
            </svg>
            <span className="pl-2 my-auto">Post To The Gallery</span>
          </span>
          <span className="flex flex-row text-gray-600 pb-4">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
            </svg>
            <span className="pl-2 my-auto">Make Your Own Models</span>
          </span>
          <span className="flex flex-row text-gray-600 pb-4">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
            </svg>
            <span className="pl-2 my-auto">Earn Money As A Developer</span>
          </span>
        </div>
      </>) : (<>

        <div className="bg-gray-tertiaryBox rounded-3xl p-6 flex flex-col">
          <span className="underline font-bold text-4xl text-red-unlimited">
            Free Trial
          </span>
          <span className="font-medium text-2xl">
            1 Month
          </span>
          <span className="text-lg text-gray-400 pb-8">
            Get a taste of what Quasi offers.
          </span>
          <span className="flex flex-row text-gray-600 pb-4">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
            </svg>
            <span className="pl-2 my-auto">Unlimited Creations</span>
          </span>
          <span className="flex flex-row text-gray-600 pb-4">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
            </svg>
            <span className="pl-2 my-auto">Post To The Gallery</span>
          </span>
          <span className="flex flex-row text-gray-600 pb-4">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 25.9185C19.9824 25.9185 25.7319 20.1689 25.7319 13.1865C25.7319 6.21631 19.9702 0.45459 12.9878 0.45459C6.01758 0.45459 0.268066 6.21631 0.268066 13.1865C0.268066 20.1689 6.01758 25.9185 13 25.9185ZM13 23.123C7.49463 23.123 3.07568 18.6919 3.07568 13.1865C3.07568 7.68115 7.48242 3.25 12.9878 3.25C18.5054 3.25 22.9243 7.68115 22.9365 13.1865C22.9487 18.6919 18.5054 23.123 13 23.123ZM11.6938 18.9971C12.1577 18.9971 12.5728 18.7529 12.8413 18.3379L18.2124 10.0005C18.3833 9.73193 18.5176 9.42676 18.5176 9.146C18.5176 8.48682 17.9438 8.04736 17.3213 8.04736C16.9185 8.04736 16.5645 8.26709 16.2959 8.70654L11.6572 16.1528L9.48438 13.4307C9.19141 13.0522 8.88623 12.9058 8.50781 12.9058C7.86084 12.9058 7.33594 13.4185 7.33594 14.0776C7.33594 14.3828 7.45801 14.6636 7.67773 14.9443L10.4976 18.3501C10.8394 18.7773 11.2178 18.9971 11.6938 18.9971Z" fill="#666666" />
            </svg>
            <span className="pl-2 my-auto">A Chance To Make Money</span>
          </span>
        </div>
      </>)}
      <div>
        <div className="bg-gray-tertiaryBox rounded-3xl p-6 flex flex-col mb-5">
          <span className="text-2xl font-bold">
            Confirm Your Subscription
          </span>
          <span className="text-gray-600">
            Or click "Go Back" to pick the subscription that best suits you.
          </span>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <button
            onClick={() => {
              onNextClick();
            }}
            className="rounded-xl w-full bg-green-primaryBox hover:bg-green-hoverBox py-1">
            Confirm
          </button>
          <Link to="/shop"
            className="rounded-xl w-full bg-gray-200 hover:bg-gray-300 text-center py-1">
            Go Back
          </Link>
        </div>
      </div>
    </div>
    // <div
    //   className={"w-full flex flex-col justify-center items-center"}
    //   data-aos="fade-right"
    // >
    //   <div className={"w-4/5 p-6 bg-white rounded-xl shadow-xl flex flex-col"}>
    //     <p className={"text-3xl ml-5 font-semibold"}>
    //       {"Subscription"}
    //     </p>
    //     <div
    //       className={
    //         "w-11/12 self-center bg-gray-light rounded-xl md:px-16 sm:px-8 pb-5 mt-10 mb-10"
    //       }
    //     >
    //       <div className={"flex w-full justify-between items-center mt-5"}>
    //         <p className={"md:text-4xl sm:text-xl font-bold text-blue-pondr"}>
    //           {productName === "NO_PRODUCT" ? "Unlimited Products" : productName}
    //         </p>
    //         <div className={"text-blue-pondr flex items-start"}>
    //           <span className={"font-medium text-xl self-start pt-3"}>$</span>
    //           <span className={"font-bold md:text-5xl sm:text-3xl self-center"}>{price}</span>

    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <button
    //     onClick={() => {
    //       onNextClick();
    //     }}
    //     className={
    //       "outline-none mt-10 text-2xl focus:outline-none rounded-full shadow-xl border-0 px-12 py-5 flex items-center justify-center bg-purple-primaryBox btn text-purple-primaryText hover:bg-purple-hoverBox hover:text-purple-hoverText self-center"
    //     }
    //   >
    //     Next
    //   </button>
    // </div>
  );
};

// Exports the component
export default CheckoutStep1;
