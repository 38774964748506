import React, { useEffect } from "react";
import AboutIntro from "../partials/AboutIntro";
import Wrapper from "../utils/Wrapper";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";

function About() {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
    logFirestoreEvent("AboutView", {});
    logScreenName("About");
    logEvent("page_view", { page_name: "About" });
  }, []);

  return (
    <Wrapper>
      <MetaTags>
        <title>About Us</title>
        <meta
          name="description"
          content="Quasi | About Page"
        />
        <meta property="og:title" content="Quasi | About Us" />
      </MetaTags>
      <AboutIntro />
    </Wrapper>
  );
}

export default About;
