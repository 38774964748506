import React from "react";
import { useEffect } from "react";
import Header from "../partials/Header";
import BlogList from "../partials/BlogList";
import Footer from "../partials/Footer";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import RequestForm from "../partials/RequestForm";
import MetaTags from "react-meta-tags";

function RequestModel() {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <MetaTags>
        <title>Request Model</title>
        <meta
          name="description"
          content="Quasi | Request Page"
        />
        <meta property="og:title" content="Quasi | Request Model" />
      </MetaTags>
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/*  Site header */}

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <RequestForm />
      </main>

      {/*  Site footer */}
    </div>
  );
}

export default RequestModel;
