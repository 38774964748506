import React from "react";
import { useEffect } from "react";
import Header from "../partials/Header";
import BlogList from "../partials/BlogList";
import Footer from "../partials/Footer";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import RequestForm from "../partials/RequestForm";
import AddBountyPartial from "../partials/AddBountyPartial";
import MetaTags from "react-meta-tags";

function AddBounty() {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <MetaTags>
        <title>Add Bounty</title>
        <meta
          name="description"
          content="Quasi | Add Bounty List"
        />
        <meta property="og:title" content="Quasi | Add Bounty List" />
      </MetaTags>
      {/*  Site header */}

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <AddBountyPartial />
      </main>

      {/*  Site footer */}
    </div>
  );
}

export default AddBounty;
