import React from "react";
import { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import ModelContent from "../partials/ModelContent";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";

const ModelPage = (props) => {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <MetaTags>
        <title>Model Page</title>
        <meta
          name="description"
          content="Quasi Market: the first ever AI marketplace"
        />
        <meta property="og:title" content="Quasi Create" />
        <meta name="audience" content="all" />
        <meta name="keywords" content="ai, artificialintelligence, quasi, quasiai, quasiartificialintelligence, aitext, gpt3, gpt2, gptneo, opt, openai, artificialintelligencetext, aicopy, openai, quasiaitext, quasiartificialintelligencetext, ai, artificialintelligenc, quasi, quasiai, quasiartificialintelligence, aiimage, aiimages, artificialintelligenceimage, artificialintelligenceimages, aiart, artificialintelligenceart, dalle, dalle2, gans, gan, imagen, dallemini, craiyon, openai, google, quasiaiimages, quasiartificialintelligenceimages" />
      </MetaTags>

      {/*  Site header */}

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <Header />

        <ModelContent />

      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
};

export default ModelPage;
