import React, { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import Header from "../partials/Header";
import ModelCards from "../partials/ModelCards";
import ModelPartial from "../partials/ModelPartial";
import Footer from "../partials/Footer";
import Wrapper from "../utils/Wrapper";
import MetaTags from "react-meta-tags";

function MyModels() {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className='flex flex-col min-h-screen overflow-hidden'>
      <MetaTags>
        <title>My Models</title>
        <meta name='description' content='Quasi | Model Page' />
        <meta property='og:title' content='Quasi | My Models' />
      </MetaTags>
      <Header />
      <div className={"flex justify-between"}>
        <Wrapper dontShowHeader={true} isGrey={false} topHead={true}>
          <ModelPartial />
        </Wrapper>
      </div>
    </div>
  );
}

export default MyModels;
