import React from "react";
import { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import ModelAnalyticsPartial from "../partials/ModelAnalyticsPartial";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";

const ModelAnalytics = (props) => {
  // Sets screen analytics
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <MetaTags>
        <title>Model Analytics</title>
        <meta
          name="description"
          content="Quasi Market: the first ever AI marketplace"
        />
        <meta property="og:title" content="Quasi | Analytics" />
      </MetaTags>

      {/*  Site header */}

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <Header />

        <ModelAnalyticsPartial />

      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
};

export default ModelAnalytics;
