import React from 'react';

function Privacy() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-left pb-12 md:pb-16">
            <h1 className="h1 mb-4">PRIVACY NOTICE</h1>
            <p className="text-lg text-gray-600 mb-8">
              Last updated March 01, 2022
            </p>
            <p className="text-lg text-gray-600 mb-8">
              Quasi Ventures Inc. built the Quasi website as a Freemium marketplace. This SERVICE is provided by Quasi Ventures Inc. at no cost and is intended for use as is.

              This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.

              If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.

              The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Quasi unless otherwise defined in this Privacy Policy.

              # **Information Collection and Use**

              For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Photos. The information that we request will be retained by us and used as described in this privacy policy.

              The website does use third party services that may collect information used to identify you.

              ‍

              **Collection**

              We collect information from you when you use our services, including but not limited to facial feature data. We also collect information about the way our users spend time on the app.

              ‍

              **Use**

              Our use of this data is limited to the exclusive purpose of improving our services for our users. Website usage data is used for the exclusive purpose of better understanding the manner in which users spend time in the Quasi website, so that we may improve our overall experience.

              ‍

              **Disclosure & Sharing**

              All data we collect is stored in a secure manner and shared with third parties only when this is necessary to enable the functionality of the application's offerings.

              Quasi does not control third party platforms, websites or online services, and Quasi is not responsible for their actions. These platforms, websites and online services follow different rules regarding the collection, use and sharing of your personal information. Quasi encourages you to read the privacy policies of these platforms, websites and online services to find out more about how your usage data may be used by these vendors.

              ‍

              **Retention**

              Your data will be stored by Quasi no longer than is necessary for the purposes for which the data are processed.

              That is why,  - anonymous, aggregated or de-identified data will be stored as long as necessary to fulfill the purposes Quasi has set out in this Privacy Policy.

              **Security**

              Quasi processes your data using computers and/or other IT enabled tools. Quasi takes technical and organizational measures to ensure the information is processed in a manner that ensures appropriate security of information, including protection against unauthorized or unlawful processing and against accidental loss, destruction or damage. For example, Quasi uses verified contractors that might have access to the data as specified in Section 2 of the Privacy Policy with which the relevant data processing agreements are signed. We also guide and train our personnel to process your data securely according to our internal data protection policies.

              ‍

              We want to inform you that whenever you use our Service, in a case of an error in the website we collect data and information (through third party products) on your device called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the website when utilizing our Service, the time and date of your use of the Service, and other statistics.

              # **Cookies**

              Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.

              This Service does not use these “cookies” explicitly. However, the website may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.

              # **Service Providers**

              We may employ third-party companies and individuals due to the following reasons:

              To facilitate our Service;To provide the Service on our behalf;To perform Service-related services; orTo assist us in analyzing how our Service is used.We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.

              # **Security**

              We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.

              # **Links to Other Sites**

              This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.

              # **Children’s Privacy**

              These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.

              # **Changes to This Privacy Policy**

              We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.

              This policy is effective as of 02.10.2022

              # **Contact Us**

              If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at quasiven@gmail.com.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Privacy;