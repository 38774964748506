import React from 'react';
import AboutCard from "./AboutCard";

function AboutIntro() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-6">
          {/* Section header */}

          <div
            className="text-left md:text-6xl md:pt-24 sm:text-5xl font-bold leading-tighter tracking-tighter mb-4 pb-5 text-center"
            data-aos="zoom-y-out"
          >
            About Us
          </div>

          <div
            className="text-left sm:text-5xl justify-left align-left font-bold leading-auto tracking-tighter mb-4 pb-5"
            data-aos="fade-left"
          >
            <span className='text-yellow-100'>
              Anyone {"  "}
            </span>
            Can Create... We Want To Provide More Tools For
            <span className='text-yellow-100'>
              {" "} Creation
            </span>
            .
          </div>

          <div
            className=" p2 text-gray-400 text-left sm:text-2xl justify-left align-left leading-auto tracking-tighter mb-4 pb-5"
            data-aos="fade-left"
          >
            Creating stuff is incredibly gratifying because it is the way you are able to express yourself. Creations are therapy as they allow you to put your thoughts out into the world. However, most ways to express creativity require time and skill. We felt that the creation process needs to be simplified when starting out. As a result, we created Quasi as a way to create tools that allow anyone to create anything they want. Imagine a world where you don't have to be an author to write the next bestselling novel, or a musician to create the next chart topping song. With Quasi, anyone can create, share, and sell AI-generated content.
          </div>

          <div
            className=" p2 text-gray-400 text-left sm:text-2xl justify-left align-left leading-auto tracking-tighter mb-4 pb-20"
            data-aos="fade-left"
          >
            We believe that AI will play a huge role in the future of content creation and we want to build the platform that enables that. Whether you're a content creator or a content consumer, Quasi has something for you. We believe that Quasi is the future of content creation and we hope you'll join us on this journey.
          </div>

          <div
            className="text-left sm:text-5xl justify-left align-left font-bold leading-auto tracking-tighter mb-4 pb-5"
            data-aos="fade-left"
          >
            The Quasi
            <span className='text-yellow-100'>
              {" "} Story
            </span>
          </div>

          <div
            className=" p2 text-gray-400 text-left sm:text-2xl justify-left align-left leading-auto tracking-tighter mb-4 pb-5"
            data-aos="fade-left"
          >
            Quasi was co-founded by Thomas Stahura & Shantanu Roy in December 2021 out of a co-working space inside The Anvil. As of March 2022, over 2,000 AI-generated creations have been produced on the platform.
          </div>

          <div
            className=" p2 text-gray-400 text-left sm:text-2xl justify-left align-left leading-auto tracking-tighter mb-4 pb-5"
            data-aos="fade-left"
          >
            Thomas & Shantanu started Quasi after a bunch of friends complained about how tedious it was to create NFT’s. Both agreed and tried to find a suitable/personalized solution, but both were left unsatisfied with existing infrastructure.
          </div>

          <div
            className=" p2 text-gray-400 text-left sm:text-2xl justify-left align-left leading-auto tracking-tighter mb-4 pb-5"
            data-aos="fade-left"
          >
            With Thomas having experience with AI and Shantanu having experience in Marketing, they thought that it would be incredible to combine the two things they loved most into a company centered around creation.
          </div>

          <div
            className=" p2 text-gray-400 text-left sm:text-2xl justify-left align-left leading-auto tracking-tighter mb-4 pb-5"
            data-aos="fade-left"
          >
            The idea evolved from NFT artwork to helping anyone create things using the power of AI. Their ultimate goal is to allow anyone to use AI models to create not just text, but images, music, and much more.
          </div>

          {/* <div
            className=" p2 text-gray-400 text-left sm:text-2xl justify-left align-left leading-auto tracking-tighter mb-4 pb-0"
            data-aos="fade-left"
          >
          -Thomas S
          </div>

          <div
            className=" p2 text-gray-400 text-left sm:text-2xl justify-left align-left leading-auto tracking-tighter mb-4 pb-20"
            data-aos="fade-left"
          >
          11/27/2021
          </div> */}

          {/*<div className="grid grid-cols-3 mx-auto auto-cols-min gap-10 w-full">
          <div>
            <AboutCard className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
          <div className="h4 text-white pb-10 pt-5 text-center align-center "> Products Analyzed</div>
          <div className="h4 text-white text-5xl pb-10 text-center align-center"data-aos="zoom-in"> 211</div>
            </AboutCard>
            </div>
          <div>
            <AboutCard className="bg-gradient-to-r from-orange-400 to-red-500">
          <div className="h4 text-white pb-10 pt-5 text-center align-center"> Reviews Analyzed</div>
          <div className="h4 text-white text-5xl pb-10 text-center align-center" data-aos="zoom-in"> 100,000</div>
            </AboutCard>
            </div>
            <div>
            <AboutCard className="bg-gradient-to-r from-purple-400 via-purple-500 to-purple-500 mx-auto justify-center text-center align-center">
          <div className="h4 text-white pb-10 pt-5 text-center align-center"> AI Questions Asked</div>
          <div className="h4 text-white text-5xl pb-10 text-center align-center" data-aos="zoom-in"> 327</div>
            </AboutCard>
            </div>
  </div>*/}

          <figure className="flex justify-center items-start">
            <img
              className="rounded shadow-3xl"
              src={"https://storage.googleapis.com/pondr-306720.appspot.com/general-site-images/human_space_colony.6.png"}
              width="768"
              height="432"
              alt="About us"
            />
          </figure>
          <p className="text-center align-center margin-auto text-gray-400 pt-5">
            Just some cool AI Art
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutIntro;
