import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import { ErrorMessage } from "@hookform/error-message";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextArea from "../pages/components/TextArea";
import { addToMailingList } from "../merlinv1/beta_api";

function BusinessContactPartial() {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isTextChecked, setIsTextChecked] = useState(false);
  const [isMusicChecked, setIsMusicChecked] = useState(false);
  const [isImageChecked, setIsImageChecked] = useState(false);
  const [isCodeChecked, setIsCodeChecked] = useState(false);
  const [isEducationChecked, setIsEducationChecked] = useState(false);
  const [isDiscountChecked, setIsDiscountChecked] = useState(false);
  const [questions, setQuestions] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const CompanySignUpFormSchema = Yup.object({
    firstname: Yup.string().required(
      "Person of contact's first name is required"
    ),
    lastname: Yup.string().required(
      "Person of contact's last name is required"
    ),
    phoneNum: Yup.string()
      //.required("Phone Number is required")
      //.matches(/^[0-9]+$/, "Must be only digits"),
      .matches(/^[0-9]*$/, "Must be only digits"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    companyName: Yup.string()
      .required("Company Name is required")
      .max(100, "Company Name cannot be more than 100 letters"),
    industryType: Yup.string().required("This field is required"),
  });

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(CompanySignUpFormSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      phoneNum: "",
      email: "",
      companyName: "",
      industryType: "",
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    var res = await addToMailingList(
      data.firstname,
      data.lastname,
      data.email,
      data.companyName,
      data.phoneNum,
      data.industryType,
      data.timeZone,
      [
        isTextChecked,
        isMusicChecked,
        isImageChecked,
        isCodeChecked,
        isEducationChecked,
        isDiscountChecked,
      ],
      questions
    );
    setIsSuccess(res.data.success);
  };

  if (!isSuccess) {
    return (
      <section>
        <div className='px-8'>
          <div className='pt-6'>
            <figure className='flex justify-center items-start'>
              <img
                className='rounded shadow-3xl'
                src={
                  "https://storage.googleapis.com/quasi-a39a6.appspot.com/Bubble_Art.png"
                }
                width='368'
                height='32'
                alt='About us'
              />
            </figure>
            <div className='lg:text-6xl md:text-4xl sm:text-2xl font-bold leading-tighter tracking-tighter text-center aos-init aos-animate w-full pb-10'>
              <strong className='flex flex-col justify-center'>
                Get In Touch
              </strong>
            </div>
            <div className='pt-12 pb-12 lg:text-4xl md:text-3xl sm:text-xl font-semibold leading-tighter tracking-tighter text-center aos-init aos-animate w-full'>
              <div className='flex flex-col justify-center'>
                Fill out the form below to speak <break /> with a Quasi team
                member.
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='p-6 mb-5'>
            <div className='flex flex-wrap -mx-3 mb-12 mt-5 -mt-5 -mb-5'>
              {error ? (
                <div className='text-sm w-full p-6 mx-3 mb-4 bg-red-200 border-2 border-red-600 text-red-600 rounded'>
                  {error}
                </div>
              ) : null}

              <div className='grid grid-cols-2 gap-4 mb-8 items-center w-full'>
                <div className='flex flex-col'>
                  <span className='text-xl font-semibold'>
                    First Name
                    <span className='text-red-orange'>*</span>
                  </span>
                  <input
                    className={
                      "w-full rounded-full py-2 px-6 bg-gray-secondaryBox"
                    }
                    name='firstname'
                    label='First Name'
                    placeholder='Enter your first name'
                    type='text'
                    required
                    ref={register}
                    errors={errors}
                  />
                  <p className='block text-sm font-medium text-red-600'>
                    <ErrorMessage errors={errors} name='firstname' />
                  </p>
                </div>

                <div className='flex flex-col'>
                  <span className='text-xl font-semibold'>
                    Last Name
                    <span className='text-red-orange'>*</span>
                  </span>
                  <input
                    className={
                      "w-full rounded-full py-2 px-6 bg-gray-secondaryBox"
                    }
                    name='lastname'
                    label='Last Name'
                    placeholder='Enter your last name'
                    type='text'
                    required
                    ref={register}
                    errors={errors}
                  />
                  <p className='block text-sm font-medium text-red-600'>
                    <ErrorMessage errors={errors} name='lastname' />
                  </p>
                </div>
              </div>

              <div className='w-full mb-8'>
                <span className='text-xl font-semibold'>
                  Work Email
                  <span className='text-red-orange'>*</span>
                </span>
                <input
                  className='w-full rounded-full py-2 px-6 bg-gray-secondaryBox'
                  name='email'
                  label='Email'
                  placeholder='Enter your email'
                  type='text'
                  required
                  ref={register}
                  errors={errors}
                />
                <p className='block text-sm font-medium text-red-600'>
                  <ErrorMessage errors={errors} name='email' />
                </p>
              </div>

              <div className='w-full mb-8'>
                <span className='text-xl font-semibold'>
                  Company/Organization Name
                  <span className='text-red-orange'>*</span>
                </span>
                <input
                  className='w-full rounded-full py-2 px-6 bg-gray-secondaryBox'
                  name='companyName'
                  label='companyName'
                  placeholder='Enter your company name'
                  type='text'
                  required
                  ref={register}
                  errors={errors}
                />
                <p className='block text-sm font-medium text-red-600'>
                  <ErrorMessage errors={errors} name='companyName' />
                </p>
              </div>

              <span className='text-xl font-semibold'>Work Phone Number</span>
              <input
                className='w-full rounded-full py-2 px-6 bg-gray-secondaryBox mb-8'
                name='phoneNum'
                label='Phone Number'
                placeholder='Enter your work phone number'
                type='text'
                required
                ref={register}
                errors={errors}
                maxLength={15}
              />

              <div className='w-full mb-8'>
                <span className='text-xl font-semibold'>
                  Select Industry
                  <span className='text-red-orange'>*</span>
                </span>
                <select
                  name='industryType'
                  ref={register}
                  className='form-select w-full focus:outline-none outline-none rounded-full bg-gray-tertiaryBox'>
                  <option hidden>Please Select An Option</option>
                  <option>Adtech</option>
                  <option>Aerospace</option>
                  <option>Agriculture</option>
                  <option>Analytics</option>
                  <option>Augmented Reality</option>
                  <option>Biotech</option>
                  <option>Community</option>
                  <option>Construction</option>
                  <option>Continuing Education</option>
                  <option>Crypto / Blockchain</option>
                  <option>Developer Tools</option>
                  <option>E-Sports</option>
                  <option>Education</option>
                  <option>Energy</option>
                  <option>Entertainment</option>
                  <option>Financial Services</option>
                  <option>Fitness / Wellness</option>
                  <option>Food/Beverage</option>
                  <option>Freight</option>
                  <option>Gaming</option>
                  <option>Government</option>
                  <option>Hard Tech</option>
                  <option>Hardware</option>
                  <option>Healthcare</option>
                  <option>Insurance</option>
                  <option>Language Learning</option>
                  <option>Lending / Loan Management</option>
                  <option>Marketplacea</option>
                  <option>Media</option>
                  <option>Public Transportation</option>
                  <option>Retail</option>
                  <option>Recruiting/Talent</option>
                  <option>Robotics</option>
                  <option>Security</option>
                  <option>Sourcing / Recruiting</option>
                  <option>Transportation</option>
                  <option>Travel/Tourism</option>
                  <option>Virtual Reality</option>
                  <option>Other</option>
                </select>

                <p className='block text-sm font-medium text-red-600'>
                  <ErrorMessage errors={errors} name='industryType' />
                </p>
              </div>

              <div className='w-full mb-8'>
                <span className='text-xl font-semibold'>
                  Select Timezone
                  <span className='text-red-orange'>*</span>
                </span>
                <select
                  name='timeZone'
                  ref={register}
                  className='form-select w-full focus:outline-none outline-none rounded-full bg-gray-tertiaryBox'>
                  <option hidden></option>
                  <option>Eastern Time (US & Canada)</option>
                  <option>Central Time (US & Canada)</option>
                  <option>Mountain Time (US & Canada)</option>
                  <option>Pacific Time (US & Canada)</option>
                  <option>Alaska</option>
                  <option>Hawaii</option>
                </select>

                <p className='block text-sm font-medium text-red-600'>
                  <ErrorMessage errors={errors} name='timeZone' />
                </p>
              </div>

              <div className='flex flex-col w-full'>
                <div className='flex flex-row py-2'>
                  <input
                    id='1'
                    type='checkbox'
                    checked={isTextChecked}
                    onChange={() => setIsTextChecked(!isTextChecked)}
                  />
                  <div className='w-full'>Text/Writing Tools</div>
                </div>
                <div className='flex flex-row py-2'>
                  <input
                    id='2'
                    type='checkbox'
                    checked={isImageChecked}
                    onChange={() => setIsImageChecked(!isImageChecked)}
                  />
                  <div className='w-full'>Images/Art Tools</div>
                </div>
                <div className='flex flex-row py-2'>
                  <input
                    id='3'
                    type='checkbox'
                    checked={isMusicChecked}
                    onChange={() => setIsMusicChecked(!isMusicChecked)}
                  />
                  <div className='w-full'>Music Creation Tools</div>
                </div>
                <div className='flex flex-row py-2'>
                  <input
                    id='4'
                    type='checkbox'
                    checked={isCodeChecked}
                    onChange={() => setIsCodeChecked(!isCodeChecked)}
                  />
                  <div className='w-full'>Code Tools/API Creation</div>
                </div>
                <div className='flex flex-row py-2'>
                  <input
                    id='5'
                    type='checkbox'
                    checked={isEducationChecked}
                    onChange={() => setIsEducationChecked(!isEducationChecked)}
                  />
                  <div className='w-full'>Education Tools</div>
                </div>
                <div className='flex flex-row py-2'>
                  <input
                    id='6'
                    type='checkbox'
                    checked={isDiscountChecked}
                    onChange={() => setIsDiscountChecked(!isDiscountChecked)}
                  />
                  <div className='w-full'>
                    Employee Group Discout For Marketplace
                  </div>
                </div>
              </div>
              <div className='mt-8 w-full'>
                <TextArea
                  className='w-full p-3 text-gray-100 bg-gray-secondaryBox rounded-lg whitespace-pre-line'
                  name='question'
                  type='search'
                  required
                  placeholder='Comments/Questions/Thoughts'
                  id='questions'
                  onChangeText={(newText) => {
                    setQuestions(newText);
                  }}
                  autoComplete='off'
                />
              </div>
              {error ? (
                <div className='text-sm w-full p-6 mx-3 mb-4 bg-red-200 border-2 border-red-600 text-red-600 rounded '>
                  {error}
                </div>
              ) : null}
              <div className='flex flex-row w-full justify-left mb-8 mt-12'>
                {isLoading ? (
                  <div className='flex sm:w-full lg:w-1/3 justify-center pb-5'>
                    <ReactLoading
                      type={"spin"}
                      color={"#6a4c93"}
                      height={"10%"}
                      width={"10%"}
                    />
                  </div>
                ) : (
                  <button
                    className='my-auto mr-auto w-full text-sm text-blue-pondr'
                    type='submit'>
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  } else {
    return (
      <section>
        <div className='px-8'>
          <div className='pt-6'>
            <div className='flex flex-col items-center justify-center w-full'>
              <div className='text-center lg:text-6xl md:text-4xl sm:text-2xl font-bold leading-tighter tracking-tighter mb-4 text-left aos-init aos-animate w-full text-blue-pondr font-bold'>
                Thanks!
              </div>
              <p className='text-center lg:text-4xl md:text-3xl sm:text-xl font-bold leading-tighter tracking-tighter mb-4 text-left aos-init aos-animate w-full'>
                A Quasi team member will be reaching out <break /> to you within
                the next few days.
              </p>
              <p className='text-center lg:text-3xl md:text-2xl sm:text-xl font-semibold leading-tighter tracking-tighter mb-4 text-left aos-init aos-animate w-full text-gray-600'>
                In the meantime, feel free to explore all that Quasi has to
                offer on our <break /> consumer marketplace!
              </p>
              <div className='flex flex-wrap justify-center items-center'>
                <a
                  href='https://quasi.market'
                  className={
                    "btn-sm font-medium text-purple-primaryText bg-purple-primaryBox hover:bg-purple-hoverBox hover:text-purple-hoverText focus:outline-none rounded-squircle"
                  }>
                  <svg
                    width='18'
                    height='18'
                    viewBox='0 0 23 23'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M22.75 16.8584L22.7354 2.16602C22.7354 1.02344 21.9736 0.24707 20.8164 0.24707H6.10938C5.02539 0.24707 4.23438 1.06738 4.23438 2.04883C4.23438 3.01562 5.06934 3.80664 6.08008 3.80664H11.3975L17.0664 3.60156L14.4443 5.93066L0.923828 19.4658C0.542969 19.8467 0.323242 20.3301 0.323242 20.7988C0.323242 21.751 1.20215 22.6592 2.18359 22.6592C2.66699 22.6592 3.12109 22.4541 3.5166 22.0586L17.0518 8.53809L19.3955 5.91602L19.1611 11.3652V16.8877C19.1611 17.9131 19.9521 18.748 20.9336 18.748C21.915 18.748 22.75 17.9277 22.75 16.8584Z'
                      fill='#2C0147'
                    />
                  </svg>
                  <span className='pl-2'>Visit Quasi Marketplace</span>
                </a>

                <Link
                  to='/'
                  className='ml-2 btn-sm font-medium text-gray-secondaryText bg-gray-300 hover:text-gray-secondaryHoverText hover:bg-gray-secondaryHoverBox focus:outline-none rounded-squircle my-2'>
                  <span className='pl-2'>Back To Home</span>
                  <path
                    d='M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z'
                    fillRule='nonzero'
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BusinessContactPartial;
