import React from "react";
import { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import GalleryPartial from "../partials/GalleryPartial";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import MetaTags from "react-meta-tags";
import { logFirestoreEvent } from "../merlinv1/beta_api";
import FeedPartial from "../partials/FeedPartial";


const Feed = () => {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <MetaTags>
                <title>Quasi Feed</title>
                <meta
                    name="description"
                    content="Quasi | Feed" 
                />
                <meta property="og:title" content="Quasi | Feed"/>
            </MetaTags>
            <Header />
            <FeedPartial />
            <Footer />
        </div>
    );
};

export default Feed;