import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { getBountyList } from "../merlinv1/beta_api";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { firebaseReducer } from "react-redux-firebase";
import firebase from 'firebase';

const BountyPartial = () => {
    const [nameList, setNameList] = useState([]);
    const [descriptionList, setDescriptionList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [idList, setIdList] = useState([]);
    const [linkList, setLinkList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [alreadySubmitted, setAlreadySubmitted] = useState([]);
    const history = useHistory();

    const fetchData = async () => {
        try {
            var res = await getBountyList(await firebase.auth().currentUser.getIdToken());
            console.log(res);

            setNameList(res.data.names);
            setDescriptionList(res.data.descriptions);
            setTypeList(res.data.types);
            setIdList(res.data.ids);
            setUserList(res.data.users);
            setAlreadySubmitted(res.data.submissions);
        } catch (err) {
            history.push("/ErrorPage");
        }
    };

    useEffect(() => {
        fetchData();
        window.scroll(0, 0);
    }, []);

    return (
        <section>
            <MetaTags>
                <title>
                    Bounty
                </title>
                <meta
                    name="Bounty"
                    content="Quasi Bounty List" />
            </MetaTags>
            <div className="max-w-full mx-auto bg-black">
                <div className="w-full lg:max-w-none">
                    <div className="bg-gray-800 py-16 w-full">
                        <div
                            className="text-left text-white md:text-6xl md:pt-20 sm:text-5xl font-bold leading-tighter tracking-tighter mb-4 pb-2 text-center"
                            data-aos="zoom-y-out"
                        >
                            <span className="text-left bg-clip-text text-transparent bg-gradient-to-r from-green-bountyStartGradient to-green-bountyEndGradient">
                                Bounty List
                            </span>
                        </div>

                        <div
                            className="text-left text-white sm:text-3xl font-semibold leading-tighter tracking-tighter mb-2 pb-5 text-center"
                            data-aos="zoom-y-out"
                        >
                            Browse The Latest Model Requests <br /> And Get Paid To Develop Them
                        </div>
                        <div
                            className="text-left text-white sm:text-xl leading-tighter tracking-tighter mb-4 pb-16 text-center"
                        //data-aos="zoom-y-out"
                        >
                            The Bounty List is a service built into Quasi and designed expressly for Quasi developers. It <br />
                            accelerates the development of highly requested models by introducing rewarding incentives. The <br />
                            first developer to submit a requested model on the bounty list gets paid for their hard work.
                        </div>

                    </div>

                    <div className="sm:mx-1 md:mx-20 lg:mx-40 py-20">
                        {nameList.length > 0 ? (
                            <div>
                                {nameList.map((name, index) => {
                                    return (
                                        <div className="rounded-3xl p-4 border-gray-border border-2 my-4 flex justify-between">
                                            <div className="flex flex-col">
                                                <div className="flex flex-row items-center">
                                                    <span>
                                                        {typeList[index] == "Text" ? (<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.73242 2.16211H20.2578C20.8047 2.16211 21.2441 1.70312 21.2441 1.15625C21.2441 0.609375 20.8047 0.160156 20.2578 0.160156H1.73242C1.18555 0.160156 0.755859 0.609375 0.755859 1.15625C0.755859 1.69336 1.19531 2.16211 1.73242 2.16211ZM2.04492 8.01172H19.9551C20.7949 8.01172 21.2441 7.58203 21.2441 6.73242V5.81445C21.2441 4.98438 20.7949 4.54492 19.9551 4.54492H2.04492C1.20508 4.54492 0.755859 4.98438 0.755859 5.81445V6.73242C0.755859 7.58203 1.20508 8.01172 2.04492 8.01172ZM2.04492 13.1387H9.36914C10.209 13.1387 10.6582 12.6992 10.6582 11.8594V10.9414C10.6582 10.1113 10.209 9.66211 9.36914 9.66211H2.04492C1.20508 9.66211 0.755859 10.1113 0.755859 10.9414V11.8594C0.755859 12.6992 1.20508 13.1387 2.04492 13.1387ZM13.168 12.3965H20.2578C20.8047 12.3965 21.2441 11.9375 21.2441 11.4004C21.2441 10.8438 20.8047 10.4043 20.2578 10.4043H13.168C12.6211 10.4043 12.1914 10.8535 12.1914 11.4004C12.1914 11.9375 12.6211 12.3965 13.168 12.3965ZM1.73242 17.5137H20.2578C20.8047 17.5137 21.2441 17.0547 21.2441 16.5176C21.2441 15.9609 20.8047 15.5215 20.2578 15.5215H1.73242C1.18555 15.5215 0.755859 15.9707 0.755859 16.5176C0.755859 17.0547 1.19531 17.5137 1.73242 17.5137Z" fill="#5AFF88" />
                                                        </svg>
                                                        ) : (
                                                            <span>
                                                                {typeList[index] == "Image" ? (<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.69922 19.168H20.291C22.4492 19.168 23.6016 18.0254 23.6016 15.8867V4.03125C23.6016 1.89258 22.4492 0.75 20.291 0.75H3.69922C1.54102 0.75 0.388672 1.88281 0.388672 4.03125V15.8867C0.388672 18.0352 1.54102 19.168 3.69922 19.168ZM2.56641 4.25586C2.56641 3.35742 3.02539 2.92773 3.88477 2.92773H20.1055C20.9551 2.92773 21.4238 3.35742 21.4238 4.25586V14.2266L16.7949 9.89062C16.3652 9.50977 15.8574 9.30469 15.3398 9.30469C14.8027 9.30469 14.3242 9.49023 13.875 9.88086L9.4707 13.8164L7.67383 12.1953C7.26367 11.834 6.81445 11.6387 6.35547 11.6387C5.89648 11.6387 5.50586 11.8242 5.0957 12.1855L2.56641 14.4414V4.25586ZM8.17188 10.2031C9.4707 10.2031 10.5352 9.13867 10.5352 7.83008C10.5352 6.53125 9.4707 5.44727 8.17188 5.44727C6.86328 5.44727 5.79883 6.53125 5.79883 7.83008C5.79883 9.13867 6.86328 10.2031 8.17188 10.2031Z" fill="#5AFF88" />
                                                                </svg>
                                                                ) : (<svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.2461 19.4219C12.998 19.627 13.6914 19.2266 13.9258 18.4648L18.6914 2.13672C18.8965 1.41406 18.5352 0.720704 17.7832 0.50586C17.041 0.300782 16.3672 0.642579 16.1328 1.46289L11.3867 17.7227C11.1621 18.4941 11.4746 19.207 12.2461 19.4219ZM29.4434 9.94922C29.4434 9.56836 29.2773 9.17774 28.9453 8.88477L22.793 3.31836C22.1875 2.78125 21.3867 2.80078 20.8789 3.36719C20.3711 3.94336 20.4199 4.74414 21.0059 5.27149L26.1719 9.94922L21.0059 14.627C20.4199 15.1445 20.3711 15.9551 20.8789 16.5215C21.3867 17.0879 22.1875 17.1074 22.793 16.5703L28.9453 11.0137C29.2773 10.7207 29.4434 10.3301 29.4434 9.94922ZM0.556641 9.94922C0.556641 10.3301 0.722656 10.7207 1.05469 11.0137L7.2168 16.5703C7.8125 17.1074 8.61328 17.0879 9.12109 16.5215C9.62891 15.9551 9.58008 15.1445 8.99414 14.627L3.82812 9.94922L8.99414 5.27149C9.58008 4.74414 9.62891 3.94336 9.12109 3.36719C8.61328 2.80078 7.8125 2.78125 7.2168 3.31836L1.05469 8.88477C0.722656 9.17774 0.556641 9.56836 0.556641 9.94922Z" fill="#5AFF88" />
                                                                </svg>
                                                                )}
                                                            </span>
                                                        )}
                                                    </span>
                                                    <span className="text-white text-2xl my-auto font-semibold pl-2">
                                                        {name}
                                                    </span>

                                                </div>
                                                <span className="text-green-bountyStartGradient">
                                                    {descriptionList[index]}
                                                </span>
                                            </div>
                                            <div>
                                                {alreadySubmitted[index] ? (
                                                    <span>
                                                        Already Submitted!
                                                    </span>
                                                ) : (
                                                    <Link to={{ pathname: "/bounty/submit", state: { "Id": idList[index], "Index": index, "Name": name} }}
                                                        //to="/bounty/submit"
                                                        className="flex flex-row items-center bg-gray-800 p-2 text-base text-green-bountyStartGradient rounded-2xl">
                                                        <svg width="15" height="15" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.4941 20.9355C13.2852 20.9355 13.8223 20.3203 14.1934 19.373L20.668 2.43945C20.834 2.00977 20.9219 1.62891 20.9219 1.30664C20.9219 0.564453 20.4531 0.0957031 19.7109 0.0957031C19.3887 0.0957031 19.0078 0.193359 18.5781 0.349609L1.57617 6.86328C0.716797 7.18555 0.0820312 7.73242 0.0820312 8.52344C0.0820312 9.48047 0.785156 9.87109 1.76172 10.1543L8.80273 12.2148L10.8438 19.1875C11.1367 20.2031 11.5273 20.9355 12.4941 20.9355ZM9.44727 10.2324L3.77344 8.50391C3.64648 8.45508 3.60742 8.41602 3.60742 8.35742C3.60742 8.28906 3.63672 8.24023 3.75391 8.20117L14.3789 4.11914C15.248 3.77734 16.0977 3.36719 17.084 2.91797C16.3418 3.52344 15.4434 4.24609 14.8184 4.87109L9.44727 10.2324ZM12.6699 17.4297C12.6016 17.4297 12.5625 17.3711 12.5234 17.2441L10.7852 11.5801L16.1465 6.19922C16.752 5.59375 17.5234 4.65625 18.1094 3.91406C17.6602 4.90039 17.2402 5.75977 16.8984 6.63867L12.8262 17.2637C12.7773 17.3809 12.7383 17.4297 12.6699 17.4297Z" fill="#DCFF51" />
                                                        </svg>
                                                        <span className="pl-2 my-auto">Submit Bounty</span>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        ) : (
                            <div>
                                No bounties yet, come back later!
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
};

export default BountyPartial;