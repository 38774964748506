import React, { useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import BountySubmitPartial from "../partials/BountySubmitPartial";
import {useLocation} from "react-router-dom";
import MetaTags from "react-meta-tags";

function SubmitBounty(props) {
    const location = useLocation();

    useEffect(() => {
    }, []);

    return (
        <div>
            <MetaTags>
        <title>Submit Bounty</title>
        <meta
          name="description"
          content="Quasi | Submit Bounty Page"
        />
        <meta property="og:title" content="Quasi | Submit Bounty" />
      </MetaTags>
            <Header />
            <main>
                <BountySubmitPartial id={location.state.Id} index={location.state.Index} name={location.state.Name}/>
            </main>
            <Footer />
        </div>
    );
}

export default SubmitBounty;