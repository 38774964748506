import React from 'react';

const Card = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className={'relative flex flex-col w-full bg-white rounded-lg justify-center ' + props.className} style={props.style}>
      {props.children}
    </div>
  );
});

export default Card;
