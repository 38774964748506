import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Transition from "../utils/Transition.js";

function Dropdown({ children, title, className, openOnHover }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  return (
    <li
      className='w-full relative z-50'
      onMouseEnter={() => {
        if (openOnHover) {
          setDropdownOpen(true);
        }
      }}
      onMouseLeave={() => {
        if (openOnHover) {
          setDropdownOpen(false);
        }
      }}
      onClick={() => setDropdownOpen(!dropdownOpen)}
      aria-expanded={dropdownOpen}>
      <a
        className='btn-sm font-medium text-gray-secondaryText bg-gray-secondaryBox hover:bg-gray-secondaryHoverBox hover:text-gray-secondaryHoverText ml-3 p-2 focus:outline-none rounded-squircle'
        aria-haspopup='true'
        aria-expanded={dropdownOpen}>
        {title}
      </a>
      <Transition
        show={dropdownOpen}
        tag='ul'
        className={"" + className}
        enter='transition ease-out duration-200 transform'
        enterStart='opacity-0 -translate-y-2'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-out duration-200'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'>
        {children}
      </Transition>
    </li>
  );
}

export default Dropdown;

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
  title: PropTypes.string.isRequired,
};
