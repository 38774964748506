import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import firebase from "firebase";
import ShareButton from "react-web-share-button";

function ReferralPartial() {
    const history = useHistory();
    const [APIkey, setAPIKey] = useState();
    const [copied, setCopied] = useState(false);
    const [uid, setUid] = useState("");

    const copyReferral = async () => {
        setCopied(true);
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText("https://quasi.market/signup=" + uid);
        } else {
            return document.execCommand('copy', true, "https://quasi.market/signup=" + uid);
        }
    }

    useEffect(() => {
        try {
            var uid = firebase.auth().currentUser.uid;
            setUid(uid);
        } catch (err) {
            console.log(err);
        }
    }, []);

    return (
        <section>
            <div className="max-w-full mx-auto bg-black">
                <div className="w-full lg:max-w-none">
                    <div className="bg-gray-800 py-16 w-full">
                        <div
                            className="text-left text-white md:text-6xl md:pt-20 sm:text-5xl font-bold leading-tighter tracking-tighter mb-4 pb-2 text-center"
                            data-aos="zoom-y-out"
                        >
                            <span className="text-left text-orange-musicHome">
                                Referrals
                            </span>
                        </div>

                        <div
                            className="text-left text-white sm:text-3xl font-semibold leading-tighter tracking-tighter mb-2 pb-5 text-center"
                            data-aos="zoom-y-out"
                        >
                            Share the Quasi experience with even<br />
                            more people. Plus added benefits for you.
                        </div>
                        <div
                            className="text-left text-white sm:text-xl leading-tighter tracking-tighter mb-4 pb-16 text-center"
                        //data-aos="zoom-y-out"
                        >
                        </div>
                    </div>

                    <div className="sm:mx-1 md:mx-20 lg:mx-40 py-20">
                        <div className="flex flex-col py-10">
                            <span>
                                <svg width="55" height="53.7640049" viewBox="0 0 89 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M54.3359 37.6172C63.75 37.6172 71.4062 29.2969 71.4062 19.0625C71.4062 9.02344 63.7891 0.976562 54.3359 0.976562C44.9219 0.976562 37.2266 9.10156 37.2266 19.1406C37.2656 29.3359 44.8828 37.6172 54.3359 37.6172ZM54.3359 30.8594C48.9062 30.8594 44.3359 25.7422 44.3359 19.1406C44.2969 12.6953 48.8672 7.73438 54.3359 7.73438C59.8047 7.73438 64.3359 12.6562 64.3359 19.0625C64.3359 25.6641 59.8047 30.8594 54.3359 30.8594ZM45.5859 73.9453H78.6719C85.3906 73.9453 88.6328 71.8359 88.6328 67.2656C88.6328 56.5625 75.2734 42.1875 54.2969 42.1875C47.5 42.1875 41.4844 43.75 36.4844 46.2109C38.3984 47.7344 40.0781 49.4922 41.4453 51.4844C45.0391 49.9219 49.375 48.9453 54.2969 48.9453C71.5625 48.9453 81.25 60.1953 81.25 66.1719C81.25 66.9141 80.8984 67.1875 79.9609 67.1875H46.6797C46.6016 69.6094 46.3281 71.6797 45.5859 73.9453ZM21.0547 86.7188C31.9531 86.7188 41.1719 77.6172 41.1719 66.6406C41.1719 55.625 32.0703 46.5234 21.0547 46.5234C10.0391 46.5234 0.9375 55.625 0.9375 66.6406C0.9375 77.6562 10.0391 86.7188 21.0547 86.7188ZM21.0547 79.375C19.4531 79.375 18.3984 78.3203 18.3984 76.7188V69.2969H10.9766C9.41406 69.2969 8.32031 68.2422 8.32031 66.6406C8.32031 65.0391 9.375 63.9844 10.9766 63.9844H18.3984V56.5625C18.3984 55 19.4531 53.9062 21.0547 53.9062C22.6562 53.9062 23.7109 55 23.7109 56.5625V63.9844H31.1328C32.6953 63.9844 33.7891 65.0391 33.7891 66.6406C33.7891 68.2422 32.6953 69.2969 31.1328 69.2969H23.7109V76.7188C23.7109 78.3203 22.6562 79.375 21.0547 79.375Z" fill="#F5821F" />
                                </svg>
                            </span>
                            <span className="text-white text-4xl font-semibold pb-2 pt-4">
                                Give 1 Month Free, Get 1 Month Free
                            </span>
                            <span className="text-orange-music text-xl">
                                Create an account to start referring friends to Quasi. They get 1 month free usage. You get 1 month free usage for every 5 friends that create an account. It’s a win-win.
                            </span>
                        </div>
                        <div className="flex flex-col py-10">
                            <span>
                                <svg width="55" height="54.2027975" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.78906 26.1797C7.28906 26.1797 8.61719 24.8125 8.61719 22.3125V13.4844C8.61719 9.69531 10.6094 7.82031 14.2422 7.82031H23.2656C25.7656 7.82031 27.1328 6.45312 27.1328 3.99219C27.1328 1.53125 25.7656 0.164062 23.2656 0.164062H13.7734C5.33594 0.164062 0.960938 4.5 0.960938 12.8203V22.3125C0.960938 24.8125 2.32812 26.1797 4.78906 26.1797ZM74.5156 26.1797C77.0156 26.1797 78.3438 24.8125 78.3438 22.3125V12.8203C78.3438 4.5 73.9688 0.164062 65.5312 0.164062H56.0391C53.5391 0.164062 52.1719 1.53125 52.1719 3.99219C52.1719 6.45312 53.5391 7.82031 56.0391 7.82031H65.0625C68.6562 7.82031 70.6875 9.69531 70.6875 13.4844V22.3125C70.6875 24.8125 72.0547 26.1797 74.5156 26.1797ZM21.8984 54.8125H24.6719V22.8984H21.8984V54.8125ZM28.1875 54.8125H33.4609V22.8984H28.1875V54.8125ZM35.8047 54.8125H39.0859V22.8984H35.8047V54.8125ZM42.0547 54.8125H47.1719V22.8984H42.0547V54.8125ZM49.2422 54.8125H53.3438V22.8984H49.2422V54.8125ZM54.8281 54.8125H57.4062V22.8984H54.8281V54.8125ZM13.7734 77.5469H23.2656C25.7656 77.5469 27.1328 76.1797 27.1328 73.7188C27.1328 71.2578 25.7656 69.8906 23.2656 69.8906H14.2422C10.6094 69.8906 8.61719 68.0156 8.61719 64.1875V55.3594C8.61719 52.8594 7.25 51.4922 4.78906 51.4922C2.28906 51.4922 0.960938 52.8594 0.960938 55.3594V64.8516C0.960938 73.2109 5.33594 77.5469 13.7734 77.5469ZM56.0391 77.5469H65.5312C73.9688 77.5469 78.3438 73.2109 78.3438 64.8516V55.3594C78.3438 52.8594 76.9766 51.4922 74.5156 51.4922C72.0156 51.4922 70.6875 52.8984 70.6875 55.3594V64.1875C70.6875 68.0156 68.6562 69.8906 65.0625 69.8906H56.0391C53.5391 69.8906 52.1719 71.2578 52.1719 73.7188C52.1719 76.1797 53.5391 77.5469 56.0391 77.5469Z" fill="#F5821F" />
                                </svg>
                            </span>
                            <span className="text-white text-4xl font-semibold pb-2 pt-4">
                                Share Your Referral Code. Start Earning.
                            </span>
                            <span className="text-xl flex flex-row items-center">
                                <div className="bg-orange-music rounded-xl flex items-center">
                                    <ShareButton title={"Share Invite"} text="Don't miss the chance to get unlimited access to quasi" url={"https://quasi.market/signup=" + uid}
                                        buttonText={
                                            <div className="w-full flex flex-row items-center px-3 py-1">
                                                <svg width="12" height="15.3333333333" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.94531 14.6875C9.50195 14.6875 9.94141 14.2383 9.94141 13.7109V4.01367L9.86328 2.56836L10.4297 3.30078L11.709 4.66797C11.8848 4.86328 12.1289 4.96094 12.373 4.96094C12.8516 4.96094 13.252 4.60938 13.252 4.11133C13.252 3.85742 13.1543 3.66211 12.9785 3.48633L9.7168 0.351562C9.45312 0.0976562 9.20898 0 8.94531 0C8.68164 0 8.42773 0.0976562 8.17383 0.351562L4.91211 3.48633C4.72656 3.66211 4.62891 3.85742 4.62891 4.11133C4.62891 4.60938 5.0293 4.96094 5.50781 4.96094C5.75195 4.96094 6.00586 4.86328 6.17188 4.66797L7.46094 3.30078L8.02734 2.56836L7.93945 4.01367V13.7109C7.93945 14.2383 8.38867 14.6875 8.94531 14.6875ZM3.31055 22.5781H14.5801C16.748 22.5781 17.8906 21.4355 17.8906 19.2969V9.78516C17.8906 7.64648 16.748 6.50391 14.5801 6.50391H11.9922V8.68164H14.3945C15.2441 8.68164 15.7129 9.12109 15.7129 10.0098V19.0723C15.7129 19.9707 15.2441 20.4004 14.3945 20.4004H3.49609C2.63672 20.4004 2.17773 19.9707 2.17773 19.0723V10.0098C2.17773 9.12109 2.63672 8.68164 3.49609 8.68164H5.92773V6.50391H3.31055C1.15234 6.50391 0 7.64648 0 9.78516V19.2969C0 21.4453 1.15234 22.5781 3.31055 22.5781Z" fill="#333333" />
                                                </svg>
                                                <div className='pl-2'></div>
                                                <div className='flex flex-col justify-center'>
                                                    <span className='text-black text-sm font-semibold'>Share Invite</span>
                                                </div>
                                            </div>
                                        } className="font-xl">
                                    </ShareButton>
                                </div>
                                <button
                                    onClick={() => {
                                        copyReferral()
                                        setTimeout(() => {
                                            setCopied(false);
                                        }, 2000)
                                    }}
                                    className="mx-2 bg-gray-200 rounded-xl flex flex-row items-center px-3 py-1 text-sm font-semibold text-gray-primaryText bg-gray-primaryBox hover:text-gray-hoverText hover:bg-gray-hoverBox">
                                    <svg width="19" height="18.173913" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.29688 16.877H5.9375V18.332C5.9375 20.4805 7.08984 21.6133 9.24805 21.6133H19.1992C21.3574 21.6133 22.5098 20.4707 22.5098 18.332V8.32227C22.5098 6.18359 21.3574 5.04102 19.1992 5.04102H17.5586V3.58594C17.5586 1.44727 16.4062 0.314453 14.248 0.314453H4.29688C2.12891 0.314453 0.986328 1.44727 0.986328 3.58594V13.5957C0.986328 15.7441 2.12891 16.877 4.29688 16.877ZM4.48242 14.709C3.62305 14.709 3.16406 14.2695 3.16406 13.3711V3.82031C3.16406 2.92188 3.62305 2.48242 4.48242 2.48242H14.0625C14.9121 2.48242 15.3809 2.92188 15.3809 3.82031V5.04102H9.24805C7.08984 5.04102 5.9375 6.18359 5.9375 8.32227V14.709H4.48242ZM9.43359 19.4355C8.57422 19.4355 8.11523 19.0059 8.11523 18.1074V8.54688C8.11523 7.64844 8.57422 7.21875 9.43359 7.21875H19.0137C19.8633 7.21875 20.332 7.64844 20.332 8.54688V18.1074C20.332 19.0059 19.8633 19.4355 19.0137 19.4355H9.43359ZM9.72656 13.332C9.72656 13.8984 10.1367 14.3086 10.7129 14.3086H13.2422V16.8477C13.2422 17.4141 13.6523 17.8242 14.2188 17.8242C14.8047 17.8242 15.2148 17.4141 15.2148 16.8477V14.3086H17.7539C18.3301 14.3086 18.7305 13.8984 18.7305 13.332C18.7305 12.7461 18.3301 12.3359 17.7539 12.3359H15.2148V9.80664C15.2148 9.2207 14.8047 8.82031 14.2188 8.82031C13.6523 8.82031 13.2422 9.23047 13.2422 9.80664V12.3359H10.7129C10.1367 12.3359 9.72656 12.7461 9.72656 13.332Z" fill="#333333" />
                                    </svg>
                                    <span className="pl-2">{copied ? "Copied!" : "Copy Invite Code"}</span>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ReferralPartial;