import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export const firebaseConfig = {
  apiKey: "AIzaSyBgMVvY6eK8UkKGbyXaGY2VY5UPuMT5fZg",
  authDomain: "quasi-a39a6.firebaseapp.com",
  projectId: "quasi-a39a6",
  storageBucket: "quasi-a39a6.appspot.com",
  messagingSenderId: "605214877537",
  appId: "1:605214877537:web:5458841320f1712a5dc720",
  measurementId: "G-3YYHDP23PQ"
};

const app = firebase.initializeApp(firebaseConfig);

export const db = app.firestore();

console.log(db.collection("OUTPUTS"));

export default firebase;
