import React, { useState, useEffect } from "react";
import { logEvent, logScreenName } from "../utils/CommonFunctions";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { signIn } from "../store/actions/authActions";
import Wrapper from "../utils/Wrapper";
import Button from "../pages/components/Button"
import ReactLoading from "react-loading";
import Input from "../pages/components/Input";
import Card from "../pages/components/Card";
import { addBounty } from "../merlinv1/beta_api";
import { useHistory } from "react-router";

const AddBountyPartial = (props) => {
    // Sets a loading state
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            modelTitle: "",
            modelDescription: "",
            modelType: "",
        },
    });

    const onSubmit = async (data) => {
        //setIsLoading(true);
        let res = await addBounty(data.modelTitle, data.modelDescription, data.modelType)
        //history.push('/user/dashboard/')
    };

    // Sets screen analytics
    useEffect(() => {
        window.scroll(0, 0);
        logScreenName("RequestModel");
        logEvent("page_view", { page_name: "RequestModel" });
    }, []);

    return (
        <Wrapper>
            <div className="pt-12"></div>
            <Card className="mx-auto md:-mt-10  max-w-3/4 md:w-3/5 h-3/5 shadow-2xl pt-24 mb-16">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex justify-center mt-5 md:auto mx-auto"
                >
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <div className="flex flex-wrap -mx-3 mb-12 mt-5 pl-10 pr-10 -mt-5 -mb-5">
                        <div className="w-auto px-3 mb-4">
                            <div className="h3 text-green-mainText w-auto">What model would you like to see on the platform?</div>
                            <div className="pb-10"></div>
                        </div>

                        <Input
                            className="w-full mb-8"
                            name="modelTitle"
                            label="Model Title"
                            placeholder="Enter the model title"
                            type="text"
                            required
                            ref={register}
                            errors={errors}
                        />

                        <Input
                            className="w-full pb-8"
                            name="modelDescription"
                            label="Model Description"
                            placeholder="Enter the model description"
                            type="text"
                            required
                            ref={register}
                            errors={errors}
                        />

                        <Input
                            className="w-full pb-8"
                            name="modelType"
                            label="Model Type"
                            placeholder="Enter the model type"
                            type="text"
                            required
                            ref={register}
                            errors={errors}
                        />

                        {isLoading ? (
                            <div className="flex w-full px-3 justify-center pb-5">
                                <ReactLoading
                                    type={"spin"}
                                    color={"#6a4c93"}
                                    height={"5%"}
                                    width={"5%"}
                                />
                            </div>
                        ) : (
                            <div className="w-full px-3">
                                <Button
                                    className="w-full bg-green-primaryBox text-green-primaryText hover:bg-green-hoverBox hover:text-green-hoverText focus:outline-none rounded-full"
                                    type="submit"
                                >
                                    <strong>Add Bounty</strong>
                                </Button>
                                <div className="pb-5"></div>
                            </div>
                        )}

                    </div>
                </form>
            </Card>
        </Wrapper>
    );
};

export default withRouter(AddBountyPartial);
